<template>
  <vue-final-modal
      v-bind="$attrs"
      :name="modalId"
      :modal-id="modalId"
      class="flex justify-center items-center"
      :content-class="['w-[350px] bg-white rounded-md']">

    <slot>
      <div class="px-6 py-5">
        <h1 class="font-bold text-2xl mb-2">{{title}}</h1>
        <slot name="description">
          <span class="text-gray-600">{{description}}</span>
        </slot>
        <div class="flex mt-8 gap-3">
          <button class="btn  text-white flex-1" :class="[confirmButtonColor]" @click="confirm">{{buttonConfirmText}}</button>
          <button class="btn bg-gray-100 text-gray-600 focus:ring-0 hover:ring-0 hover:bg-gray-200" @click="cancel">{{buttonCancelText}}</button>
        </div>
      </div>
    </slot>
  </vue-final-modal>
</template>


<script>
import {useVfm, VueFinalModal} from "vue-final-modal";

export default {
  components: {VueFinalModal},
  props: {
    confirmButtonColor: {
      type: String,
      default: 'bg-primary'
    },
    title: {
      type: String,
      default: 'Confirmation'
    },
    description: {
      type: String,
      default: 'Vous êtes sur le point de faire une action irréversible !'
    },
    buttonConfirmText: {
      type: String,
      default: 'Confirmer'
    },
    buttonCancelText: {
      type: String,
      default: 'Annuler'
    },
    modalId: {
      type: String,
      default: 'modal-confirm'
    }
  },
  methods: {
    confirm(){
      this.$emit('confirm');
    },
    cancel(){
      this.$emit('cancel');
      useVfm().close(this.modalId);
    }
  }
}
</script>
