import {useModal} from "vue-final-modal";
import axios from "@/axios-instance";
import ImageUploadModal from "@/components/Media/Modal/ImageUploadModal.vue";
import heic2any from "heic2any";
import EventBus from "@/EventBus";
import {errorToast} from "@/services/SystemService";


export async function urlToDataUrl(merchantId, url){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/media/get_data_url`, {url: url})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                errorToast('Image introuvable...')
                reject(error);
            })
    }));
}

export async function mediaUploadImage(event, merchantId, uploadType, sizes = ['1000x1000', '500x500'], aspectRatio=1){
    return new Promise((async (resolve, reject) => {

        let imageSrc, fileType, fileName = null;
        let baseUpload = {path: '', publicUrl: ''};
        if(typeof event === 'string'){

            // file url
            const url = new URL(event);
            imageSrc = await urlToDataUrl(merchantId,event)
                .catch((error) => {
                    reject(error);
                });
            baseUpload.publicUrl = event;
            baseUpload.path = url.pathname.substring(1);
            fileType = imageSrc.split(';')[0].split(':')[1];
            fileName = Math.random().toString(36).substring(7);
        }
        else{
            let file = event.target.files[0];
            // Convert Iphone files to jpeg if needed
            if(event.target.files[0].type === 'image/heif' || event.target.files[0].type === 'image/heic'){
                await heic2any({
                    blob: event.target.files[0],
                    toType: "image/jpeg",
                    quality: 0.9, // cuts the quality and size by half
                })
                    .then((conversionResult) => {
                        file = conversionResult;
                    })
                    .catch((e) => {
                        reject('bad_format');
                    });
            }
            // Upload base image...
            fileType = file.type;
            baseUpload = await createUploadMediaUrl(merchantId, uploadType, fileType, 'base')
            await uploadMediaToS3(baseUpload.uploadUrl, file);
            imageSrc = await fileToDataURl(file);
            fileName = file.name;
        }




        const uploadId = (Math.random() + 1).toString(36).substring(7);
        const modal = await useModal({
            component: ImageUploadModal,
            attrs: {
                aspectRatio: aspectRatio,
                imageSrc: imageSrc,
                sizes: sizes,
                uploadId: uploadId
            }
        });
        modal.open();

        const result = [];
        result.push({size: 'base', path: baseUpload.path, url: baseUpload.publicUrl});

        let imageCropped = false;
        EventBus.on('media:crop-and-resize:'+uploadId+':aborted', async (data) => {
            console.log('NOO ', 'media:crop-and-resize:'+uploadId+':aborted')
            if(!imageCropped){
                reject(data);
            }
        });

        EventBus.on('media:crop-and-resize:'+uploadId, async (data) => {
            console.log('NOO ', 'media:crop-and-resize:'+uploadId)
            imageCropped = true;
            for(let d of data){
                const upload = await createUploadMediaUrl(merchantId, uploadType, fileType, d.size)
                result.push({size: d.size, path: upload.path, url: upload.publicUrl});
                await uploadMediaToS3(upload.uploadUrl, await dataURItoBlob(d.data, fileName))
            }
            resolve(result);
        });
    }));
}


function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  var blob = new Blob([ab], {type: mimeString});
  return blob;
}

export function fileToBuffer(file){
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            resolve(e.target.result)
        }
        reader.readAsArrayBuffer(file);
    });
}

export function fileToDataURl(file){
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            resolve(reader.result)
        }
    });
}


export async function createUploadMediaUrl(merchantId, uploadType, fileType, type){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/media/create_media_upload`, {merchantId, uploadType, fileType, type})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function uploadMediaToS3(uploadUrl, file){
    return fetch(uploadUrl, {
        method: "PUT",
        headers: {'Content-Type': file.type},
        body: file
    });
}
