<template>
  <Modal close-id="order-filter" title="Filtrer les commandes" size="xs" :no-scroll="true">
    <div class="mb-4">
      <label class="form-label">Par type</label>
      <div class="border rounded-md flex">
        <label for="onsite" class="h-10 border-r flex-1 flex justify-center items-center gap-2 cursor-pointer">
          <input type="checkbox" class="form-checkbox" id="onsite" value="on_site_counter" name="type" v-model="filters.types">
          <span>Sur place</span>
        </label>
        <label for="pickup" class="h-10 border-r flex-1 flex justify-center items-center gap-2 cursor-pointer">
          <input type="checkbox" class="form-checkbox" id="pickup" value="pickup" name="type" v-model="filters.types">
          <span>Click&Collect</span>
        </label>
        <label for="delivery" class="h-10 flex-1 flex justify-center items-center gap-2 cursor-pointer">
          <input type="checkbox" class="form-checkbox" id="delivery" value="delivery" name="type" v-model="filters.types">
          <span>En livraison</span>
        </label>
      </div>
    </div>

    <div class="mb-4">
      <label class="form-label">Par statut</label>
      <div class="border rounded-md flex">
        <label for="cancelled" class="h-10 border-r flex-1 flex justify-center items-center gap-2 cursor-pointer">
          <input type="checkbox" class="form-checkbox" id="cancelled" value="cancelled" name="status" v-model="filters.status">
          <span>Annulée</span>
        </label>
        <label for="pending" class="h-10 border-r flex-1 flex justify-center items-center gap-2 cursor-pointer">
          <input type="checkbox" class="form-checkbox" id="pending" value="pending" name="status" v-model="filters.status">
          <span>En cours</span>
        </label>
        <label for="completed" class="h-10 flex-1 flex justify-center items-center gap-2 cursor-pointer">
          <input type="checkbox" class="form-checkbox" id="completed" value="completed" name="status" v-model="filters.status">
          <span>Terminée</span>
        </label>
      </div>
    </div>
    <div class="mb-4">
      <label class="form-label">Par date</label>
      <vue-tailwind-datepicker i18n="fr" :options="options" :formatter="formatter"  v-model="dateSelector" />
    </div>
    <div class="flex justify-end pt-5">
      <button class="btn bg-primary text-white" @click="save">{{$t('cta.add')}}</button>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {DateTime} from "luxon";
import EventBus from "@/EventBus";
import {useVfm} from "vue-final-modal";

export default {
  components: {Modal},
  props: {
    baseFilters: {
      type: Object,
      required: true
    }
  },
  setup(){
    const formatter = {
      date: 'DD/MM/YYYY',
      month: 'MMM'
    }
    const options = {
      shortcuts: {
        today: 'Aujourd\'hui',
        yesterday: 'Hier',
        past: period => period + ' derniers jours',
        currentMonth: 'Mois actuel',
        pastMonth: 'Mois passé'
      },
      footer: {
        apply: 'Appliquer',
        cancel: 'Annuler'
      }
    };
    return {formatter, options}
  },
  data(){
    return {
      dateSelector: {
        startDate: '',
        endDate: ''
      },
      filters: {}
    }
  },
  methods: {
    save(){
      EventBus.emit('order:filters-update', this.filters);
      useVfm().close('order-filter')
    }
  },
  watch: {
    dateSelector: {
      handler: function (val) {
        if(val.startDate && val.endDate){
          this.filters.dateRange = {
            start: DateTime.fromFormat(val.startDate, 'dd/MM/yyyy').toISODate(),
            end: DateTime.fromFormat(val.endDate, 'dd/MM/yyyy').toISODate()
          }
        }
        else{
          this.filters.dateRange = null
        }
      },
      deep: true
    }
  },
  mounted() {
    this.filters = JSON.parse(JSON.stringify(this.baseFilters))
  }
}
</script>
