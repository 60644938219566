<template>
  <modal title="Annuler une commande" size="xs" close-id="cancel-order">
    <div class="py-2 px-2">

      <label class="form-label">Raison de l'annulation</label>
      <select name="" id="" v-model="reason" class="form-select" :class="{'form-input-error': errors.some(e => e.field === 'reason')}">
        <option :value="null">-- Choisir --</option>
        <option value="kitchen_closed">Restaurant fermé</option>
        <option value="out_off_stock">Rupture de stock</option>
        <option value="client_cancel">Annulation demandée par le client</option>
        <option value="merchant_busy">Trop de monde</option>
        <option value="client_impossible_instruction">Instruction du client impossible</option>
        <option value="other">Autre</option>
      </select>
      <small v-if="errors.some(e => e.field === 'reason')" class="text-red-500 text-sm font-medium leading-tight block mt-1">{{errors.find(e => e.field === 'reason').message}}</small>

      <div class="bg-blue-600/10 px-5 py-4 rounded-md mt-5" v-if="reason==='out_off_stock'||reason==='client_impossible_instruction'">
        <span class="text-gray-800 "><b>Attention!</b><br>Pour ne pas perdre un client, il est préférable de l'appeler pour lui changer le contenu de sa commande.</span>
      </div>

      <div class="mt-8 flex justify-between items-center">
        <button class="btn bg-transparent border border-red-500 hover:ring-red-500/20 text-red-600" :disabled="isButtonLoading" @click="cancelOrder">
          <span v-if="!isButtonLoading">Annuler la commande</span>
          <loader v-else/>
        </button>
        <button class="btn bg-gray-100 text-gray-800" @click="closeModal">Retour</button>
      </div>
    </div>
  </modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";
import {successToast} from "@/services/SystemService";
export default {
  components: {Modal},
  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data(){
    return {
      reason: null,
      isButtonLoading: false,
      errors: []
    }
  },
  methods: {
    validate(){
      this.errors = [];

      if(!this.reason) this.errors.push({field: 'reason', message: 'Veuillez choisir une raison'});

      return this.errors.length === 0;
    },
    async cancelOrder(){
      if(!this.validate()) return;
      this.isButtonLoading = true;
      await this.$store.dispatch('order/cancelOrder', {merchantId: this.currentMerchant.id, orderId: this.orderId, reason: this.reason})
          .then(() => {
            successToast('La commande a été annulée avec succès');
          })
      this.isButtonLoading = false;
      this.closeModal();
    },
    closeModal(){
      useVfm().close('cancel-order');
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    })
  },
  mounted() {

  }
}
</script>
