<template>
  <div class="flex flex-col gap-5 md:max-w-[600px] mx-auto">



    <draggable v-model="groups"
               item-key="uid"
               :scroll-sensitivity="800"
               @end="save"
               :forceFallback="true"
               handle=".categoryHandler">
      <template #item="{ element, index }">

        <div class="border bg-white rounded-lg mb-6">
          <div class="h-12 flex items-center justify-between gap-4 pl-4 pr-3" :class="{'opacity-60 bg-gray-50':element.archived}">
            <div class="flex items-center justify-center h-10 cursor-pointer categoryHandler">
              <i class="material-icons text-gray-400">drag_indicator</i>
            </div>
            <strong class="text-lg flex-1 flex items-center leading-none">{{element.name}}<span v-if="element.archived" class="ml-3 text-xs rounded-md font-medium bg-gray-200 px-2 py-[3px]">Archivée</span></strong>
            <div class="text-gray-700 bg-gray-100 flex items-center gap-1 px-2 py-[2px] rounded-md text-sm" v-if="false">
              <i class="material-icons text-sm">schedule</i>
              <span class="text-xs"><!-- @todo Schedule description --></span>
            </div>
            <div class="flex gap-2">
              <button class="btn bg-gray-300 border border-gray-300 px-2 flex items-center h-9 hover:ring-gray-100 disabled:opacity-0" @click="moveCategory('UP', element.id)" :disabled="index === 0"><i class="material-icons text-xl">arrow_upward</i></button>
              <button class="btn bg-gray-300 border border-gray-300 px-2 flex items-center h-9 hover:ring-gray-100 disabled:opacity-0" @click="moveCategory('DOWN', element.id)" :disabled="index === groups.length-1"><i class="material-icons text-xl">arrow_downward</i></button>
            </div>
          </div>

          <draggable v-model="element.items"
                     item-key="uid"
                     group="products"
                     @end="save"
                     handle=".productHandler">
            <template #item="item">
              <div class="border-t flex px-3 gap-3 items-center py-4 sm:py-0" :class="{'bg-[#F4F4F4]': false, 'bg-gray-50':element.archived||item.element.archived}" :key="item.element.uid">
                <div class="px-1 flex items-center justify-center h-14 cursor-grab productHandler">
                  <i class="material-icons text-gray-400">drag_indicator</i>
                </div>
                <div class="flex items-center h-14 w-10">
                  <img :src="item.element.image.small ?? item.element.image.base" v-if="item.element.image" class="w-10 rounded-md h-10" :class="item.element.image" alt="">
                  <div class="w-10 h-10 bg-gray-100 rounded-md flex justify-center items-center" v-else><i class="material-icons text-gray-400">add_a_photo</i></div>
                </div>
                <div class="flex flex-col justify-center gap-[2px] flex-1">
                  <span class="text-sm sm:text-base font-bold text-gray-800 leading-tight">{{item.element.name}}</span>
                  <small class="text-xs text-gray-500">{{getMenuPriceLine(item.element)}}</small>
                </div>
                <div class="flex gap-2">
                  <button class="btn bg-gray-100 border px-2 flex items-center h-9 hover:ring-gray-100 disabled:opacity-0" @click="moveProduct('UP', element.id, item.element.id)" :disabled="index === 0 && item.index === 0"><i class="material-icons text-xl">arrow_upward</i></button>
                  <button class="btn bg-gray-100 border px-2 flex items-center h-9 hover:ring-gray-100 disabled:opacity-0" @click="moveProduct('DOWN', element.id, item.element.id)" :disabled="index === groups.length-1 && item.index === element.items.length-1"><i class="material-icons text-xl">arrow_downward</i></button>
                </div>
              </div>
            </template>
          </draggable>

        </div>

      </template>
    </draggable>



  </div>
</template>

<script>
import {formatPrice} from "@/services/FormatService";
import {getMenuPriceLine} from "@/services/MenuService";
import {mapGetters} from "vuex";
import draggable from "vuedraggable";

export default {
  components: {draggable},
  setup(){
    return {formatPrice,getMenuPriceLine}
  },
  data(){
    return {
      groups: []
    }
  },
  computed: {
    ...mapGetters({
      selectedMenuItems: 'menu/selectedMenuItems',
      archivedItemsVisibility: 'menu/archivedItemsVisibility',
      menu: 'menu/menu',
      currentMerchant: 'currentMerchant'
    }),
  },

  methods: {

    moveCategory(direction, catId) {
      const categoryCurrentIndex = this.groups.findIndex(g => g.id === catId);
      const category = this.groups[categoryCurrentIndex];

      this.groups.splice(categoryCurrentIndex, 1);
      if(direction === 'UP'){
        this.groups.splice(categoryCurrentIndex-1, 0, category);
      }
      else{
        this.groups.splice(categoryCurrentIndex+1, 0, category);
      }
    },

    moveProduct(direction, catId, productId){
      const categoryCurrentIndex = this.groups.findIndex(g => g.id === catId);
      const category = this.groups[categoryCurrentIndex];
      const productCurrentIndex = category.items.findIndex(p => p.id === productId);
      const product = category.items[productCurrentIndex];

      this.groups[categoryCurrentIndex].items.splice(productCurrentIndex, 1);
      if(direction === 'UP'){
        if(productCurrentIndex === 0){
          // Change category (Up) and push to the end
          this.groups[categoryCurrentIndex-1].items.push(product);
        }
        else{
          // Minus 1
          this.groups[categoryCurrentIndex].items.splice(productCurrentIndex-1, 0, product);
        }
      }
      else{
        if(productCurrentIndex === category.items.length){
          // Shift in next category
          this.groups[categoryCurrentIndex+1].items.unshift(product);
        }
        else{
          this.groups[categoryCurrentIndex].items.splice(productCurrentIndex+1, 0, product);
        }
      }
    },


    save(){
      const categories = [];
      const items = [];

      this.groups.forEach((g, categoryIndex) => {
        categories.push({
          id: g.id,
          sort: categoryIndex,
          itemIds: g.items.map(i => i.uid),
        });
        g.items.forEach((i, productIndex) => {
          items.push({
            id: i.id,
            sort: productIndex
          });
        });
      });

      // @todo update store
      this.$store.dispatch('menu/updateMenuSort', {merchantId: this.currentMerchant.id, categories, items});
    },


    getGroup(){
      const cat = JSON.parse(JSON.stringify(this.menu.categories));
      this.groups =  cat.filter(c => this.archivedItemsVisibility ? true : c.archived===false).map((c) => {
        const items = this.menu.items.filter(i => c.itemIds.indexOf(i.uid) !== -1 )
            .filter(i => this.archivedItemsVisibility ? true : i.archived===false)
            .map((i,index) => {
              if(i.archived)
                i.status = 'archived';
              else if(i.unavailable)
                i.status = 'unavailable';
              else
                i.status = 'available';
              i.sort = index;
              return i;
            })
            .sort((a,b) => a.sort - b.sort)
        c.items = c.itemIds.map((uid) => items.find(i => i.uid === uid)).filter(e => e !== undefined);
        return c;
      })
          .sort((a,b) => a.sort - b.sort);
    }
  },
  beforeUnmount() {
    this.save();
  },
  mounted() {
    this.getGroup();
  }
}
</script>
