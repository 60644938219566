import axios from "@/axios-instance";


export function createNewConversation(merchantId, message){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/ai/conversations/create`, {message})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function getCurrentConversations(merchantId){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/menu/ai/get_current_conversations`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function conversationSendMessage(merchantId, currentConversationId, message){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/ai/conversations/${currentConversationId}/send`, {message})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function getConversation(merchantId, conversationId){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/menu/ai/conversations/${conversationId}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}