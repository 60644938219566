<template>
  <Modal size="xs" v-slot="{ close }" title="Disponibilité par récupération">
    <div>
      <div class="mb-4">

        <label for="onsite" class="flex items-center cursor-pointer p-3 border-t-[1px] border-l-[1px] border-r-[1px] rounded-tl-xl rounded-tr-xl">
          <input type="checkbox" v-model="available.onsite" id="onsite" name="onsite" class="mr-3">
          <div class="font-bold">Disponibles sur place</div>
          <i class="material-icons bg-gray-400 rounded-[9999px] w-[22px] h-[22px] p-4 text-white flex items-center justify-center justify-self-end ml-auto text-lg">restaurant</i>
        </label>

        <label for="pickup" class="flex items-center cursor-pointer p-3 border-[1px]">
          <input type="checkbox" v-model="available.pickup" id="pickup" name="pickup" class="mr-3">
          <div class="font-bold">Disponibles en Click & Collect</div>
          <i class="material-icons bg-gray-400 rounded-[9999px] w-[22px] h-[22px] p-4 text-white flex items-center justify-center justify-self-end ml-auto text-lg">local_mall</i>
        </label>

        <label for="delivery" class="flex items-center cursor-pointer p-3 border-b-[1px] border-l-[1px] border-r-[1px] rounded-bl-xl rounded-br-xl">
          <input type="checkbox" v-model="available.delivery" id="delivery" name="delivery" class="mr-3">
          <div class="font-bold">Disponibles en livraison</div>
          <i class="material-icons bg-gray-400 rounded-[9999px] w-[22px] h-[22px] p-4 text-white flex items-center justify-center justify-self-end ml-auto text-lg">directions_bike</i>
        </label>

      </div>
    </div>

    <div v-if="errors.some(e => e.field === 'selected')" class="flex items-center text-sm text-red-600">
      {{errors.find(e => e.field === 'selected').message}}
    </div>

    <div class="flex justify-end mt-3">
      <button class="btn bg-primary text-white rounded disabled:opacity-50" @click="save">{{ $t('cta.save') }}</button>
    </div>
  </Modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import { mapGetters } from "vuex";
import { useVfm } from "vue-final-modal";

export default {
  components: { Modal },
  setup() {
    const vfm = useVfm();
    return { vfm }
  },
  computed: {
    ...mapGetters({
      menu: 'menu/menu',
      currentMerchant: 'currentMerchant',
      selectedMenuItems: 'menu/selectedMenuItems',
    }),
  },
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      available: {
        onsite: true,
        pickup: true,
        delivery: true,
      },
      errors: []
    }
  },
  methods: {
    validate() {
      this.errors = []
      if(!this.available.onsite && !this.available.pickup && !this.available.delivery) this.errors.push({field: 'selected', message: 'Vous devez choisir au moins un mode'})
      return this.errors.length === 0;
    },

    async save() {
      if(!this.validate()) return;

      await this.$store.dispatch('menu/updateMenuItemBatchAction', {merchantId: this.currentMerchant.id, update: this.products.map(product => ({
        id: product.id,
        available: this.available
      })), categoryForce: true})
      .catch((e) => {
        console.log('Update error', e)
      });

      await this.vfm.closeAll();
    },
  },
}
</script>
