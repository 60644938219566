<template>
  <merchant-layout :title="'Facture '+invoiceRef" has-back-button>
    <iframe :src="invoice.url" frameborder="0" class="w-full h-[calc(100vh-120px)]"></iframe>
  </merchant-layout>
</template>

<script setup lang="ts">
import MerchantLayout from "@/components/Layouts/MerchantLayout.vue";
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
import {getInvoice} from "@/services/AccountingService";
import {useStore} from "vuex";
const route = useRoute();
const store = useStore();

const invoiceRef = ref(route.params.invoiceRef);
const isLoading = ref(true);
const invoice = ref(null);

onMounted(async () => {
  if(!store.getters.currentMerchant) await store.dispatch('getMerchantsAction');
  invoice.value = await getInvoice(store.getters.currentMerchant.id, invoiceRef.value);
  isLoading.value = false;
})

</script>
