import {DateTime, Interval} from "luxon";
import humanizeDuration from "humanize-duration";

export function formatDuration(minutes) {
    return minutes + ' min';
}

export function ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDate(date, format='dd.MM.yyyy HH:mm') {
    return DateTime.fromISO(date).setLocale('fr').toFormat(format);
}


export function formatDateFrom(date, format='dd.MM.yyyy HH:mm', from) {
    return DateTime.fromFormat(date, from).setLocale('fr').toFormat(format);
}

export function formatDistance(metters) {
    return (metters/1000).toFixed(1) + ' km';
}

export function formatPrice(price) {
    if(typeof price == 'string'){
        price = parseFloat(price);
    }
    if(!price && typeof price !== 'number'){
        return '--,-- €'
    }
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price/100)
}
export function formatPercentage(number){
    if(typeof number == 'string'){
        number = parseFloat(number);
    }
    if(!number || typeof number !== 'number'){
        return '--%'
    }
    return number.toFixed(1) + '%';
}

export function formatBigPrice(price) {
    if(price<100000){
        return formatPrice(price)
    }
    else{
        return (price/100000).toFixed(1) + 'k€'
    }
}

export function formatProgressionPercentage(percentage){
    if(percentage>0){
        return '+' + percentage.toFixed(2) + '%';
    }
    else{
        return percentage.toFixed(2) + '%';
    }
}

export function timestampDiffForHumans(date, largest=1) {
    const formatted = Interval
        .fromDateTimes(DateTime.fromSeconds(date).setLocale('fr'), DateTime.now())
        .toDuration()
        .valueOf();

    return humanizeDuration(formatted, {language: 'fr', largest});
}
