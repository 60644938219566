<template>
      <page-block title="Livraison" no-title-padding v-if="order.type === 'delivery'">
        <template v-slot:no-padding>
          <div class="w-full h-40">
            <OrderDeliveryMap :client-position="order.toAddress.position" :restaurant-position="order.fromAddress.position"/>
          </div>
          <div class="flex flex-col p-4">
            <strong class="text-sm mb-2">{{order.toAddress.address}}, {{order.toAddress.city}}</strong>
            <span class="text-gray-400 text-sm">{{order.toAddress.comment}}</span>
            <div class="flex gap-2 mt-3">
              <div class="bg-gray-100 flex-1 text-center text-sm py-2 rounded-md text-gray-500">Distance: {{formatDistance(order.delivery.distance)}}</div>
              <div class="bg-gray-100 flex-1 text-center text-sm py-2 rounded-md text-gray-500">Coût total : {{formatPrice(order.delivery.price)}}</div>
            </div>
          </div>
          <div class="flex justify-between items-center gap-4 p-4 border-t" v-if="order.delivery.courier">
            <div class="h-12 w-12 rounded-full bg-center bg-cover" :style="'background-image: url('+order.delivery.courier.avatar+')'"></div>
            <div class="flex flex-col items-start justify-center flex-1">
              <span class="text-gray-500 text-xs" v-if="!order.delivery.selfDelivery">{{$t('orders.deliver_by')}}</span>
              <span class="font-bold">{{ order.delivery.courier.name }}</span>
            </div>


            <button class="btn bg-red-200/30 text-sm flex items-center gap-2 px-3 text-red-600 hover:ring-0" v-if="!order.delivery.courier.banned" @click="banCourier"><i class="text-base material-icons">do_not_disturb</i>Bannir</button>
            <div class="flex items-center gap-2" v-else>
              <i class="text-base material-icons text-red-600">do_not_disturb</i>
              <span class="text-sm">Livreur banni</span>
            </div>

          </div>
          <div class="flex justify-between gap-4 p-4 border-t" v-if="order.delivery.selfDelivery">
            <div class="h-12 w-12 rounded-full bg-primary flex items-center justify-center">
              <i class="material-icons text-white">home</i>
            </div>
            <div class="flex flex-col items-start justify-center flex-1">
              <span class="font-bold">{{ order.delivery.selfDelivery ? $t('order.self_delivery') : order.delivery.courier.name }}</span>
            </div>
          </div>
          <div class="flex justify-between gap-4 p-4 border-t flex-col">
            <div class="flex justify-between">
              <span class="text-sm font-bold">{{ $t('order.preparation_plus_delivery') }}</span>
              <span class="text-sm font-bold">{{getTotalOrderDurationWithSteps(order.statusSteps)}}</span>
            </div>
            <div>
              <order-status-history :order="order" v-if="order.statusSteps"/>
            </div>
          </div>
        </template>
      </page-block>
</template>


<script>
import OrderStatusHistory from '@/components/Order/OrderStatusHistory.vue'
import {getTotalOrderDurationWithSteps} from "@/services/TimeService";
import {formatDistance, formatPrice} from "@/services/FormatService";
import OrderDeliveryMap from "@/components/Order/OrderDeliveryMap.vue";
import {useModal} from "vue-final-modal";
import ConfirmCourierBan from "@/components/Order/Modal/ConfirmCourierBan.vue";

export default {
  components: {OrderStatusHistory, OrderDeliveryMap},
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  setup(){
    const confirmBan = useModal({component: ConfirmCourierBan})
    return {confirmBan}
  },
  methods: {
    formatPrice, formatDistance, getTotalOrderDurationWithSteps,
    async banCourier(){
      await this.confirmBan.patchOptions({attrs: {courierId: this.order.delivery.courier.id, courierName: this.order.delivery.courier.name}});
      await this.confirmBan.open();
    }
  }
}
</script>
