<template>
  <div class="grid items-center grid-cols-[25px,auto]" v-for="(step,i) in history">
    <div class="flex flex-col items-center justify-center h-full">
      <div class="flex-1 w-[2px]" :class="{'bg-red-600':step.status === 'canceled', 'bg-green-600': step.status!=='canceled', 'opacity-0': i===0}"></div>
      <i class="material-icons text-sm text-white bg-green-600 w-4 h-4 rounded-full flex justify-center text-xs items-center" v-if="step.status === 'taken'">notifications</i>
      <i class="material-icons text-sm text-white bg-red-600 w-4 h-4 rounded-full flex justify-center text-xs items-center" v-else-if="step.status === 'canceled'">close</i>
      <i class="material-icons text-sm text-white bg-green-600 w-4 h-4 rounded-full flex justify-center text-xs items-center" v-else>check</i>
      <div class="flex-1 w-[2px] bg-green-600" :class="{'opacity-0': i===(history.length-1)}"></div>
    </div>
    <div class="py-1">
      <div class="bg-gray-100 px-3 py-1 rounded-lg flex flex-col" :class="{'bg-red-100':step.status === 'canceled'}">
        <span class="text-gray-500 text-sm" v-if="step.status === 'taken' && isPreorder">{{ getHoursFromIso(step.time) }} - {{ $t('order.status.ring_on_device') }}</span>
        <span class="text-gray-500 text-sm" v-else>{{ getHoursFromIso(step.time) }} - {{ $t('order.status.'+step.status) }}</span>

        <div class="flex items-center" v-if="step.status === 'preparation' && announcedTime">
          <i class="material-icons text-base">arrow_right</i>
          <span class="text-sm font-medium">Temps annoncé : {{announcedTimeFormatted}}</span>
        </div>
        <div class="flex items-center" :class="{'text-red-600': realPreparationTime>announcedTime}" v-if="step.status === 'preparation_done' && realPreparationTime">
          <i class="material-icons text-base">arrow_right</i>
          <span class="text-sm font-medium">Temps réel : {{realPreparationTimeFormatted}}</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {getHoursFromIso, getHoursFromTimestamp} from '@/services/TimeService'
import {DateTime} from "luxon";
import {formatDuration} from "@/services/FormatService";
export default {
  methods: {getHoursFromIso},
  setup(){
    return {getHoursFromTimestamp}
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    history(){
      return this.order.statusSteps;
    },
    isPreorder(){
      return this.history.some(s => s.status === 'preorder')
    },
    announcedTime(){
      const preparationStart = this.history.find(s => s.status === 'preparation');
      if(!preparationStart || !this.order.indicatedDoneAt) return null;

      const preparation = DateTime.fromISO(preparationStart.time);
      const indicatedDoneAt = DateTime.fromISO(this.order.indicatedDoneAt);

      return indicatedDoneAt.diff(preparation).toFormat('m')
    },
    realPreparationTime(){
      const preparationStart = this.history.find(s => s.status === 'preparation');
      const preparationEnd = this.history.find(s => s.status === 'preparation_done');
      if(!preparationStart || !preparationEnd) return null;

      const start = DateTime.fromISO(preparationStart.time);
      const end = DateTime.fromISO(preparationEnd.time);

      return end.diff(start).toFormat('m')
    },
    announcedTimeFormatted(){
      if(!this.announcedTime) return null;
      return formatDuration(this.announcedTime)
    },
    realPreparationTimeFormatted(){
      if(!this.realPreparationTime) return null;
      return formatDuration(this.realPreparationTime)
    },
  }
}
</script>
