<template>
  <modal title="Commentaire interne" size="xs" close-id="internal-comment">
    <div>
      <textarea class="form-input py-2 h-32 h-auto" v-model="comment" placeholder="Ecrire un message pour vous et votre équipe au sujet de cette réservation..."></textarea>
    </div>
    <div class="mt-2 flex justify-end">
      <button class="btn bg-primary text-white" :disabled="isButtonLoading" @click="save">
        <span v-if="!isButtonLoading">{{$t('cta.save')}}</span>
        <loader v-else class="h-4 w-4"/>
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {addInternalComment} from "@/services/BookingService";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";
import {errorToast, successToast} from "@/services/SystemService";
export default {
  components: {Modal},
  props: {
    booking:{
      type: Object,
      required: true
    }
  },
  data(){
    return {
      comment: '',
      isButtonLoading: false
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    })
  },
  methods: {
    async save(){
      this.isButtonLoading = true;
      await addInternalComment(this.currentMerchant.id, this.booking.id, this.comment)
          .then(() => {
            this.closeModal();
            this.$store.commit('booking/SET_BOOKING_INTERNAL_COMMENT', {bookingId: this.booking.id, comment: this.comment});
            successToast('Commentaire ajouté avec succès');
          })
          .catch(() => {
            errorToast('Une erreur est survenue lors de l\'ajout du commentaire');
          })
      this.isButtonLoading = false;
    },
    closeModal(){
      useVfm().close('internal-comment');
    }
  },
  mounted() {
    this.comment = this.booking.intern_message;
  }
}
</script>
