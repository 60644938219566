import axios from "@/axios-instance";

export async function banCourier(merchantId, courierId){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/couriers/ban`, {courierId})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
