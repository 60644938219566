<template>
  <modal :title="booking.id ? 'Modifier une réservation' : 'Nouvelle réservation'" size="xs" close-id="booking_editor">

    <div>
      <label class="form-label">Nom du client</label>
      <input type="text" class="form-input" v-model="booking.name">
      <small v-if="errors.some(e => e.field === 'name')" class="text-red-500 text-sm font-medium leading-tight block mt-1">{{errors.find(e => e.field === 'name').message}}</small>
    </div>

    <div class="mt-4">
      <label class="form-label">Téléphone</label>
      <phone-input v-model="booking.phone" />
      <small v-if="errors.some(e => e.field === 'phone')" class="text-red-500 text-sm font-medium leading-tight block mt-1">{{errors.find(e => e.field === 'phone').message}}</small>
    </div>

    <div class="grid grid-cols-2 mt-4 gap-5">
      <div>
        <label class="form-label">Date de la réservation</label>
        <input class="form-input" :min="minBookingDate" type="date" v-model="booking.booking_for_date">
        <small v-if="errors.some(e => e.field === 'booking_for_date')" class="text-red-500 text-sm font-medium leading-tight block mt-1">{{errors.find(e => e.field === 'booking_for_date').message}}</small>
      </div>

      <div>
        <label class="form-label">Heure d'arrivée</label>
        <select class="form-select disabled:bg-gray-200" v-model="booking.booking_for_hours" :disabled="!isSelectedDataAvailable">
          <option :value="hours" v-for="hours in getHours()">{{hours}}</option>
        </select>
        <small v-if="errors.some(e => e.field === 'booking_for_hours')" class="text-red-500 text-sm font-medium leading-tight block mt-1">{{errors.find(e => e.field === 'booking_for_hours').message}}</small>
      </div>
    </div>

    <div class="grid grid-cols-[100px,auto] mt-4 gap-5">
      <div>
        <label class="form-label">PAX</label>
        <select class="form-select" v-model="booking.persons">
          <option v-for="i in bookingSettings.maxClientPerBooking" :value="i">{{i}}</option>
        </select>
      </div>

      <div>
        <label class="form-label">Salle / Zone</label>
        <select class="form-select" v-model="booking.zone_id">
          <option :value="zone.id" v-for="zone in bookingZones" :key="zone.id">{{zone.name}}</option>
        </select>
      </div>
    </div>

    <div class="mt-5" v-if="showInternalComment">
      <label class="form-label">Commentaire interne</label>
      <textarea class="form-input py-2 min-h-[100px]" v-model="booking.intern_message" placeholder="Ne sera pas visible par le client"></textarea>
    </div>

    <div class="mt-10 flex justify-end gap-4">
      <button class="font-bold text-primary underline" v-if="!showInternalComment" @click="showInternalComment=true">Ajouter un commentaire</button>
      <button class="btn bg-primary text-white" @click="save">Valider</button>
    </div>

  </modal>
</template>

<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {DateTime} from "luxon";
import {useModal, useVfm} from "vue-final-modal";
import {errorToast} from "@/services/SystemService";
import PhoneInput from "@/components/Ui/Form/PhoneInput.vue";
import BookingCancellation from "@/components/Booking/Modal/BookingCancellation.vue";
export default {
  components: {PhoneInput, Modal},
  props: {
    bookingData: {
      type: Object,
      required: false
    }
  },

  computed: {
    ...mapGetters({
      bookingZones: 'booking/bookingZones',
      bookingSettings: 'booking/bookingSettings',
      bookingCurrentMerchantId: 'booking/bookingCurrentMerchantId',
      currentMerchant: 'currentMerchant'
    }),
    minBookingDate(){
      return DateTime.now().toFormat('yyyy-MM-dd');
    },
    isSelectedDataAvailable(){
      if(this.booking.booking_for_date && DateTime.fromISO(this.booking.booking_for_date).startOf('day').toSeconds() < DateTime.now().startOf('day').toSeconds()){
        return false;
      }
      if(!this.booking.booking_for_date){
        return false;
      }
      return true;
    }
  },
  data(){
    return {
      booking: {
        id: null,
        name: null,
        phone: null,
        booking_for_date: null,
        booking_for_hours: null,
        persons: 2,
        zone_id: null,
        intern_message: null
      },
      errors: [],
      isButtonLoading: false,
      showInternalComment: false
    }
  },
  methods: {

    validate(){
      this.errors = [];

      if(!this.booking.name) this.errors.push({field: 'name', message: 'Le nom du client est requis'});
      if(!this.booking.phone) this.errors.push({field: 'phone', message: 'Le numéro de téléphone du client est requis'});
      if(!this.booking.booking_for_date) this.errors.push({field: 'booking_for_date', message: 'La date est requise'});
      if(!this.booking.booking_for_hours) this.errors.push({field: 'booking_for_hours', message: "L'heure est requise"});

      return this.errors.length === 0;
    },
    save(){
      if(!this.validate()) return;

      this.isButtonLoading = true;
      if(this.booking.id){ // Update
        this.$store.dispatch('booking/update', {merchantId: this.currentMerchant.id, booking: this.booking});
      }
      else { // Create
        this.$store.dispatch('booking/create', {merchantId: this.currentMerchant.id, booking: this.booking});
      }
      this.isButtonLoading = false;
      this.close();
    },
    getHours(){
      const hours = [];
      let current = DateTime.now().set({hour: 9, minutes:0});
      const end = DateTime.now().set({hour: 23});
      const isCurrentDay = this.booking.booking_for_date ? DateTime.fromISO(this.booking.booking_for_date).hasSame(DateTime.now(), 'day') : false;
      if(!this.isSelectedDataAvailable){
        return ['Date invalide']
      }
      while(current.toSeconds() <= end.toSeconds()){
        if(isCurrentDay && current.toSeconds() < DateTime.now().toSeconds()) {
          current = current.plus({minutes: 15});
          continue;
        }
        hours.push(current.toFormat('HH:mm'));
        current = current.plus({minutes: 15});
      }
      return hours;
    },
    close(){
      useVfm().closeAll();
    },
  },
  created() {
    if(this.bookingData){
      const booking = JSON.parse(JSON.stringify(this.bookingData));
      this.booking.id = booking.id;
      this.booking.name = booking.user.name;
      this.booking.phone = booking.user.phone;
      this.booking.persons = booking.persons;
      this.booking.zone_id = booking.zone.id;
      this.booking.intern_message = booking.intern_message;
      this.showInternalComment = booking.intern_message !== null;
      this.booking.booking_for_date = DateTime.fromISO(booking.booking_for).toFormat('yyyy-MM-dd');
      this.booking.booking_for_hours = DateTime.fromISO(booking.booking_for).toFormat('HH:mm');
    }
  },
  async mounted() {
    if(!this.bookingSettings || this.bookingCurrentMerchantId !== this.currentMerchant.id) await this.$store.dispatch('booking/getSettings', {merchantId: this.currentMerchant.id});
    if(!this.bookingZones) await this.$store.dispatch('booking/getBookingZones', {merchantId: this.currentMerchant.id})
    if(this.bookingZones.length === 0){
      this.$router.push({name: 'booking_settings'});
      errorToast(this.$t('errors.first_add_booking_zone'))
      this.close();
    }
    if(!this.bookingData){
      this.booking.zone_id = this.bookingZones[0].id;
      this.booking.booking_for_date = DateTime.now().toFormat('yyyy-MM-dd');
    }
  }
}
</script>
