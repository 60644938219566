<template>
  <page-block title="Meilleurs clients" no-title-padding>
    <template v-slot:title-right>
      <div class="border rounded-md overflow-hidden flex">
        <button class="px-4 h-10 cursor-pointer" :class="[type==='orders'? 'bg-primary text-white font-bold' : 'bg-white text-gray-800']" @click="type='orders'">Commandes</button>
        <button class="px-4 h-10 cursor-pointer" :class="[type==='turnover'? 'bg-primary text-white font-bold' : 'bg-white text-gray-800']" @click="type='turnover'">CA</button>
      </div>
    </template>
    <template v-slot:no-padding>
      <ListStats :items="items"/>
    </template>
  </page-block>
</template>

<script>
import ListStats from "@/components/Analytics/ListStats.vue";
import {formatPrice} from "@/services/FormatService";

export default {
  components: {ListStats},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      type: 'turnover'
    }
  },
  computed: {
    items(){
      let totalValue;
      if(this.type === 'turnover')
        totalValue = Math.max(...this.data.bestClients.map(b=>b.amount))
      else
        totalValue = Math.max(...this.data.bestClients.map(b=>b.orders));

      return this.data.bestClients.map((b) => {
        const value = this.type === 'turnover' ? b.amount : b.orders;
        return {
          id: b.id,
          name: b.name,
          v: value,
          value: this.type === 'turnover' ? formatPrice(value) : value,
          percentage: value / totalValue * 100
        }
      }).sort((a,b) => b.v - a.v);
    }
  }
}
</script>
