//@ts-nocheck
import axios from "@/axios-instance";
import {getAccountingMonth, getAccountingMonthList} from "@/services/AccountingService";

export default {
    namespaced: true,
    state: {
        summaryList: [],
        accountingMonth: [],
        accountingMerchant: null
    },
    mutations: {
        SET_ACCOUNTING_MERCHANT(state, merchant) {
            state.accountingMerchant = merchant;
        },
        SET_SUMMARY_LIST(state, summaryList) {
            state.summaryList = summaryList
        },
        RESET_ACCOUNTING_MONTHS(state){
            state.accountingMonth = [];
        },
        SET_ACCOUNTING_MONTH(state, {year, month, data}) {
            const index = state.accountingMonth.findIndex(item => item.year === year && item.month === month);
            if (index !== -1) {
                state.accountingMonth[index] = {data, year, month, epoch: new Date().getTime()};
            } else {
                state.accountingMonth.push({data, year, month, epoch: new Date().getTime()});
            }
        },
        REMOVE_ACCOUNTING_MONTH(state, {year, month}) {
            const index = state.accountingMonth.findIndex(item => item.year === year && item.month === month);
            if (index !== -1) {
                state.accountingMonth.splice(index, 1);
            }
        }
    },
    getters: {
        summaryList: (state) => state.summaryList,
        accountingMonth: (state) => state.accountingMonth,
        accountingMerchant: (state) => state.accountingMerchant
    },
    actions: {
        async getSummaryList({rootGetters, commit}, {merchantId}){
            const list = await getAccountingMonthList(merchantId);
            commit('SET_SUMMARY_LIST', list);
            commit('SET_ACCOUNTING_MERCHANT', merchantId);
        },
        async getSummaryMonthAction({commit}, {merchantId, year, month}){
            commit('REMOVE_ACCOUNTING_MONTH', {year, month});
            const data = await getAccountingMonth(merchantId, year, month);
            commit('SET_ACCOUNTING_MONTH', {year, month, data});
            commit('SET_ACCOUNTING_MERCHANT', merchantId);
        }
    }
}
