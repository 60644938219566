<template>
  <ModalConfirm
      modal-id="confirm-courier-ban"
      button-confirm-text="Bannir"
      confirm-button-color="bg-red-600"
      @confirm="ban"
  >
    <template v-slot:description>
      <div>Souhaitez-vous bannir le livreur {{courierName}} de votre restaurant ?</div>
      <div class="mt-3">Il ne recevra plus de commandes de votre part.</div>
    </template>
  </ModalConfirm>
</template>

<script>
import ModalConfirm from "@/components/Ui/Modals/ModalConfirm.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";
import {banCourier} from "@/services/CourierService";
import {successToast} from "@/services/SystemService";

export default {
  components: {ModalConfirm},
  props: {
    courierId: {
      type: Number,
      required: true
    },
    courierName: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({currentMerchant: 'currentMerchant'})
  },
  methods: {
    async ban(){
      await banCourier(this.currentMerchant.id, this.courierId);
      successToast('Le livreur a bien été banni de votre restaurant.');
      useVfm().close('confirm-courier-ban');
      this.$store.commit('order/SET_COURIER_BAN_STATUS', {courierId: this.courierId, banned: true});
    }
  },
}
</script>
