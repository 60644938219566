<template>
  <ModalConfirm
      modal-id="confirm-category-archive"
      :button-confirm-text="products.length>0 ? $t('menu.category.archive_button_products', {count: products.length})  : 'Archiver la catégorie'"
      @confirm="archiveCategory"
  >
    <template v-slot:description>
      <span v-if="products.length>0">{{ $t('menu.category.confirm_archive_text', {count: products.length}) }}</span>
      <span v-else>Souhaitez-vous archiver la catégorie "{{category.name}}"?</span>
    </template>
  </ModalConfirm>
</template>

<script>
import ModalConfirm from "@/components/Ui/Modals/ModalConfirm.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";
export default {
    components: {ModalConfirm},
    props: {
        categoryId: {
            type: Number,
            required: true
        }
    },
    methods: {
        async archiveCategory(){
            await this.$store.dispatch('menu/setArchiveStatusCategoryAction', {
                merchantId: this.currentMerchant.id,
                categoryId: this.categoryId,
                archived: true
            });
            if(this.products.length>0){
                await this.$store.dispatch('menu/updateMenuItemBatchAction', {
                    merchantId: this.currentMerchant.id,
                    update: this.products.map(p => ({id:p.id, status: "archived"}))
                });
            }
            useVfm().closeAll();
        }
    },
    computed: {
        ...mapGetters({
            currentMerchant: 'currentMerchant',
            menu: 'menu/menu'
        }),
        products(){
            const itemIds = this.menu.categories.find(category => category.id === this.categoryId).itemIds;
            return this.menu.items.filter(i => !i.archived).filter(i => itemIds.includes(i.uid));
        },
        category(){
            return this.menu.categories.find(category => category.id === this.categoryId);
        }
    }
}
</script>
