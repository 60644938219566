<template>
  <ModalSliding close-id="menu-item-editor" background-color="bg-gray-100">

    <div class="grid grid-cols-1 md:grid-cols-[auto,320px] xl:grid-cols-[auto,400px] flex flex-col">

      <!--Left-->
      <div class="pt-3 !h-[100dvh] h-[100vh] flex flex-col">
        <div class="flex items-center gap-4 mb-4 px-4">
          <button class="flex items-center justify-center bg-white border px-4 py-1 gap-2 rounded-md hover:bg-gray-50" @click="close"><i class="text-lg material-icons">arrow_back</i>Retour</button>
          <span class="text-xl font-bold uppercase" v-if="item">Modifier un produit</span>
          <span class="text-xl font-bold uppercase" v-else>Créer un produit</span>
        </div>

        <div class="overflow-y-auto flex-1 pb-5 px-4">

          <page-block>
            <template v-slot:base>
              <div class="px-6 py-5">
                <div class="grid md:grid-cols-[auto,25%,25%] gap-4">
                  <div>
                    <label class="font-bold">Nom du produit<span class="text-red-600">*</span></label>
                    <input @input="fieldChange" type="text" class="form-input mt-2" v-model="name" :disabled="currentMerchant.features.menuMinorEditionOnly" :class="{'form-input-error':errors.some(e => e.error === 'name')}" placeholder="ex: Daube niçoise">
                    <small v-if="errors.some(e => e.error === 'name')" class="text-red-600">{{errors.find(e=>e.error==='name').message}}</small>
                  </div>
                  <div>
                    <label class="font-bold">Ordre d'affichage</label>
                    <select @change="fieldChange" class="form-select mt-2" v-model="sort">
                      <option :value="0">En premier</option>
                      <option :value="i+1" v-for="(p,i) in currentCategorySort" :key="p.uid">Après {{p.name}}</option>
                    </select>
                  </div>
                  <div>
                    <label class="font-bold block">Catégorie<span class="text-red-600">*</span></label>

                    <select v-model="currentCategory" v-if="menu.categories.filter(c => c.archived===false).length > 0" class="form-select mt-2" :class="{'form-input-error':errors.some(e => e.error === 'category')}">
                      <option :value="null">--Sélectionnez--</option>
                      <option :value="cat.id" v-for="cat in menu.categories.filter(c => c.archived===false)">{{cat.name}}</option>
                    </select>
                    <button class="btn-sm mt-1 bg-primary text-white" v-else @click="addCategory">Ajouter une catégorie</button>

                    <small v-if="errors.some(e => e.error === 'category')" class="text-red-600 block mt-1">{{errors.find(e=>e.error==='category').message}}</small>
                  </div>
                </div>


                <div class="mt-5">
                  <div>
                    <label class="font-bold">Description</label>
                    <textarea @change="fieldChange" class="form-input mt-2 py-1 min-h-[120px] h-full" :class="{'form-input-error':errors.some(e => e.error === 'description')}" v-model="description" placeholder="ex: Daube niçoise"></textarea>
                    <small v-if="errors.some(e => e.error === 'description')" class="text-red-600">{{errors.find(e=>e.error==='description').message}}</small>
                  </div>
                </div>

                <div class="mt-5 w-1/3">
                  <div>
                    <label class="font-bold">Temps de préparation moyen</label>
                    <input type="number" v-model="time_preparation" class="form-input mt-1" placeholder="En minutes">
                    <small v-if="errors.some(e => e.error === 'time_preparation')" class="text-red-600">{{errors.find(e=>e.error==='time_preparation').message}}</small>
                  </div>
                </div>


                <div class="grid md:grid-cols-[180px,auto] gap-4 mt-5">
                  <div class="flex flex-col  w-44 md:w-full">
                    <label class="font-bold">Photo du produit</label>


                    <div v-show="!imageUrl">
                      <label for="product_file" class="cursor-pointer aspect-square rounded-lg mt-2 flex items-center border relative justify-center" >
                        <i class="material-icons text-[4.5rem] text-gray-400">photo_camera</i>
                        <div class="h-8 w-8 rounded-full flex items-center justify-center bg-gray-300 absolute mt-10 ml-14">
                          <i class="material-icons text-xl">upload</i>
                        </div>
                      </label>
                      <input type="file" accept="image/*" id="product_file" @input="uploadImage" class="hidden">
                    </div>

                    <div v-show="imageUrl">
                      <label for="product_file" class="aspect-square rounded-lg mt-2 flex items-start justify-end bg-center bg-cover cursor-pointer"
                             :style="{'background-image': 'url('+imageUrl+')'}">
                        <div class="h-7 w-7 bg-red-600 text-white flex items-center justify-center rounded-full relative -right-2 -top-2 cursor-pointer"
                        @click.prevent="removeImage">
                          <i class="material-icons text-lg">close</i>
                        </div>
                      </label>
                      <div class="h-0 flex items-end">
                        <button class="btn bg-gray-800 text-white w-1/2 px-0 mb-2 ml-2" @click="updateImage">Modifier</button>
                      </div>
                      <input type="file" accept="image/*" id="product_file" @input="uploadImage" class="hidden">
                    </div>

                  </div>

                  <div>
                    <label class="font-bold">Plus de détails</label>
                    <ProductMeta v-model="metas"/>
                  </div>
                </div>
                <div class="mt-5">
                  <table class="w-full">
                    <tr>
                      <th class="text-left">
                        <div class="flex items-center py-2 px-3 cursor-pointer" @click="setAllDeliveryModeAvailable()">
                          <input type="checkbox" :checked="available.delivery&&available.pickup&&available.onsite" class="form-checkbox mr-3 pointer-events-none">
                          <span class="font-bold">Disponibilité</span>
                        </div>
                      </th>
                      <th class="text-left py-2 px-3">
                        <span class="font-bold">Prix</span>
                      </th>
                      <th class="text-left py-2 px-3">
                        <span class="font-bold">Taux TVA</span>
                      </th>
                    </tr>
                    <tr>
                      <td class="border">
                        <label class="w-full px-3 py-2 flex items-center cursor-pointer hover:bg-gray-50">
                          <input @change="fieldChange" type="checkbox" class="form-checkbox mr-3" v-model="available.onsite">
                          <span class="font-medium flex-1 hidden md:block">Disponible sur place</span>
                          <span class="font-medium flex-1 md:hidden">Sur place</span>
                        </label>
                      </td>
                      <td class="relative border min-w-[70px]">
                        <input type="number" min="0" v-model="price.onsite" :disabled="currentMerchant.features.menuMinorEditionOnly" @change="fieldChange" placeholder="--,-- €"
                               :class="[
                                     price.onsite>0 ? 'font-medium': '',
                                     errors.some(e => e.error === 'onsite_price') ? 'bg-red-100/70' : 'bg-transparent'
                                     ]" class="text-right disabled:opacity-50 disabled:bg-gray-100 absolute px-3 w-full h-full outline-none focus:ring-4 focus:rounded-sm focus:ring-blue-400/70 resize-none left-0 focus:shadow-xl top-0  focus:z-50" step="0.01">
                      </td>
                      <td class="relative border">
                        <select v-model="vatPercentage.onsite"  :disabled="currentMerchant.features.menuMinorEditionOnly" @change="fieldChange" :class="{'bg-red-100/70':errors.some(e => e.error === 'onsite_vat')}" class=" disabled:opacity-50 disabled:bg-gray-100 font-medium form-select border-0 rounded-none focus:ring-4 focus:rounded-sm focus:ring-blue-400/70 focus:z-50 w-full h-full absolute top-0 left-0">
                          <option value="0">Pas applicable</option>
                          <option value="550">5.5%</option>
                          <option value="1000">10%</option>
                          <option value="2000">20%</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td class="border">
                        <label class="w-full px-3 py-2 flex items-center cursor-pointer hover:bg-gray-50">
                          <input @change="fieldChange" type="checkbox" class="form-checkbox mr-3" v-model="available.pickup">
                          <span class="font-medium flex-1 hidden md:block">Disponible en Click&Collect</span>
                          <span class="font-medium flex-1 md:hidden">Click&collect</span>
                        </label>
                      </td>
                      <td class="relative border min-w-[70px]">
                        <input type="number" min="0" @change="fieldChange" v-model="price.pickup" placeholder="--,-- €"
                               :class="[
                                     price.pickup>0 ? 'font-medium': '',
                                     errors.some(e => e.error === 'pickup_price') ? 'bg-red-100/70' : 'bg-transparent'
                                     ]"
                               class="text-right absolute px-3 w-full h-full outline-none focus:ring-4 focus:rounded-sm focus:ring-blue-400/70 resize-none left-0 focus:shadow-xl top-0 focus:z-50" step="0.01">
                      </td>
                      <td class="relative border">
                        <select v-model="vatPercentage.pickup" :disabled="currentMerchant.features.menuMinorEditionOnly" @change="fieldChange" :class="{'bg-red-100/70':errors.some(e => e.error === 'pickup_vat')}" class=" disabled:opacity-50 disabled:bg-gray-100 font-medium form-select border-0 rounded-none focus:ring-4 focus:rounded-sm focus:ring-blue-400/70 focus:z-50 w-full h-full absolute top-0 left-0">
                          <option value="0">Pas applicable</option>
                          <option value="550">5.5%</option>
                          <option value="1000">10%</option>
                          <option value="2000">20%</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td class="border">
                        <label class="w-full px-3 py-2 flex items-center cursor-pointer hover:bg-gray-50">
                          <input @change="fieldChange" type="checkbox" class="form-checkbox mr-3" v-model="available.delivery">
                          <span class="font-medium flex-1 hidden md:block">Disponible en livraison</span>
                          <span class="font-medium flex-1 md:hidden">Livraison</span>
                        </label>
                      </td>
                      <td class="relative border min-w-[70px]">
                        <input type="number" min="0"
                               :class="[
                                     price.delivery>0 ? 'font-medium': '',
                                     errors.some(e => e.error === 'delivery_price') ? 'bg-red-100/70' : 'bg-transparent'
                                     ]"
                               @change="fieldChange" v-model="price.delivery" placeholder="--,-- €"
                               class="text-right absolute px-3 w-full h-full outline-none focus:ring-4 focus:rounded-sm focus:ring-blue-400/70 resize-none left-0 focus:shadow-xl top-0 focus:z-50" step="0.01">
                      </td>
                      <td class="relative border">
                        <select v-model="vatPercentage.delivery" :disabled="currentMerchant.features.menuMinorEditionOnly" @change="fieldChange" :class="{'bg-red-100/70':errors.some(e => e.error === 'delivery_vat')}" class=" disabled:opacity-50 disabled:bg-gray-100 font-medium form-select border-0 rounded-none focus:ring-4 focus:rounded-sm focus:ring-blue-400/70 focus:z-50 w-full h-full absolute top-0 left-0">
                          <option value="0">Pas applicable</option>
                          <option value="550">5.5%</option>
                          <option value="1000">10%</option>
                          <option value="2000">20%</option>
                        </select>
                      </td>
                    </tr>
                  </table>

                  <div class="flex flex-col mt-2">
                    <small v-if="errors.some(e => e.error === 'available')" class="text-red-600">- {{errors.find(e=>e.error==='available').message}}</small>
                    <small v-if="errors.some(e => e.error === 'delivery_price')" class="text-red-600">- {{errors.find(e=>e.error==='delivery_price').message}}</small>
                    <small v-if="errors.some(e => e.error === 'pickup_price')" class="text-red-600">- {{errors.find(e=>e.error==='pickup_price').message}}</small>
                    <small v-if="errors.some(e => e.error === 'onsite_price')" class="text-red-600">- {{errors.find(e=>e.error==='onsite_price').message}}</small>
                  </div>

                </div>
              </div>
              <OptionBlock :product-id="item ? item.id : null" type="mandatory" @updateOptions="updateOptionSort($event, 'mandatory')" :options="optionMandatory"/>
              <OptionBlock :product-id="item ? item.id : null" type="optional" @updateOptions="updateOptionSort($event, 'optional')" :options="optionOptional"/>
            </template>
          </page-block>

        </div>

        <div class="h-14 flex items-center z-50 shadow-[0_-5px_15px_rgba(0,0,0,0.2)] px-4">
          <button class="btn bg-primary text-white" @click="save" :disabled="isButtonLoading">{{$t('cta.save')}}</button>
        </div>
      </div>



      <div class="border-l bg-white items-start pt-4 xl:pt-10 justify-center hidden md:flex">
        <IphoneMockup>
          <div class="w-full h-[300px] bg-gray-100 bg-center bg-cover" v-if="imageUrl" :style="'background-image: url('+imageUrl+');'"></div>
          <div v-else class="h-[300px] bg-gray-100 w-full"></div>
          <div class="w-full px-4 py-3">
            <span class="text-xl font-bold" v-if="name">{{name}}</span>
            <span class="text-xl font-bold text-gray-300" v-else>Nom du produit</span>
            <div class="flex flex-wrap mt-1 gap-1 mt-1 mb-2">
              <div class="text-white bg-yellow-400 px-3 h-6 text-xs flex items-center rounded-md font-medium" v-if="metas.some(m => m === 'highlight')">Produit Star</div>
              <div class="bg-gray-200 text-gray-600 px-3 h-6 text-xs flex items-center rounded-md font-medium" v-for="m in previewMeta" :key="m">{{$t('product_meta.'+m)}}</div>
            </div>
            <div class="text-sm text-gray-600 mt-0" v-if="description">{{description}}</div>
            <div class="text-sm text-gray-300 mt-0" v-else>Description du produit...</div>
          </div>


          <div class="border-t-4 flex flex-col border-gray-100 px-4 py-6" v-for="m in options">
            <span class="font-medium text-sm mb-1">{{m.name}}</span>
            <span class="text-xs text-gray-400" v-if="m.min===0">Optionnel maximum {{m.max}}</span>
            <span class="text-xs text-gray-400" v-else-if="m.min>0 && m.max>m.min">* Obligatoire maximum {{m.max}}</span>
            <span class="text-xs text-gray-400" v-else-if="m.min>0 && m.max===m.min && m.max===1">* Obligatoire</span>
            <span class="text-xs text-gray-400" v-else-if="m.min>0 && m.max===m.min">* Obligatoire {{m.max}} choix</span>

            <div class="flex items-center mt-3" v-for="value in menu.modifiers.filter(d => m.itemIds.includes(d.uid))">
              <div class="mr-2">
                <div class="cursor-not-allowed h-4 w-4 border border-gray-500 rounded-full" v-if="m.max === 1"></div>
                <div class="cursor-not-allowed h-4 w-4 border border-gray-500" v-else-if="m.quantitySelector === false"></div>
                <div v-else-if="m.quantitySelector === true" class="h-4 flex items-center">
                  <i class="cursor-not-allowed material-icons text-xl" :class="[value.unavailable ? 'text-gray-300' : 'text-blue-600']">add_circle</i>
                </div>
              </div>
              <div class="flex justify-between items-center flex-1" :class="[value.unavailable ? 'text-gray-400' : 'text-gray-900 font-medium']">
                <span class="text-sm" :class="{'line-through':value.unavailable}">{{ value.name }}</span>

                <span v-if="value.unavailable" class="text-xs text-gray-400">INDISPONIBLE</span>
                <span class="text-xs text-gray-300" v-else-if="value.price>0">{{ formatPrice(value.price) }}</span>
              </div>
            </div>
          </div>




          <template v-slot:fixed>
            <div class="absolute bottom-0 left-[10px] w-[calc(100%-20px)] bg-white rounded-b-[60px] px-3 shadow-[0_-2px_10px_rgba(0,0,0,0.1)] h-20">
              <div class="h-16 flex items-center justify-between px-4 gap-4">
                <div class="flex items-center">
                  <button class="cursor-not-allowed h-8 w-8 bg-gray-100 rounded-full text-xl font-bold">-</button>
                  <span class="text-xl font-bold px-2">1</span>
                  <button class="cursor-not-allowed h-8 w-8 bg-gray-100 rounded-full text-xl font-bold">+</button>
                </div>
                <div class="flex-1">
                  <button class="cursor-not-allowed bg-[#559F1B] h-9 rounded-md font-medium text-white text-sm flex items-center justify-center text-center w-full">Ajouter au panier</button>
                </div>
              </div>
            </div>
          </template>
        </IphoneMockup>
      </div>


    </div>


  </ModalSliding>
</template>

<script>
import IphoneMockup from "@/components/Ui/Elements/IphoneMockup.vue";
import ModalSliding from "@/components/Ui/Modals/ModalSliding.vue";
import PageBlock from "@/components/Ui/Elements/PageBlock.vue";
import ProductMeta from "@/components/Menu/Editor/ProductMeta.vue";
import OptionBlock from "@/components/Menu/Editor/OptionBlock.vue";
import {mapGetters} from "vuex";
import {useModal, useVfm} from "vue-final-modal";
import {mediaUploadImage} from "@/services/MediaService";
import {removeProductImage} from "@/services/MerchantService";
import EventBus from "@/EventBus";
import MenuCategoryEditor from "@/views/Menu/MenuCategoryEditor.vue";
import {formatPrice} from "../../services/FormatService";
import merchant from "@/stores/merchant";

export default {
  components: {OptionBlock, ProductMeta, PageBlock, ModalSliding, IphoneMockup},
  setup(){
    const vfm = useVfm();
    const categoryModal = useModal({component: MenuCategoryEditor});
    return {vfm, categoryModal}
  },
  props: {
    category: {
      type: Number,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  data(){
    return {
      currentCategory: null,
      name: '',
      sort: 0,
      time_preparation: 10,
      description: '',
      meta: '',
      imageUrl: null,
      imageData: null,
      metas: [],
      modifierGroups: [],
      price: {
        delivery: null,
        pickup: null,
        onsite: null
      },
      vatPercentage: {
        delivery: 1000,
        pickup: 1000,
        onsite: 1000
      },
      available: {
        delivery: true,
        pickup: true,
        onsite: true
      },
      errors: [],
      isButtonLoading: false
    }
  },
  methods: {
    formatPrice,

    updateOptionSort(e, type){
      if(type === 'mandatory'){
        this.modifierGroups = Object.values([...e, ...this.optionOptional].map(e => e.uid));
      }
      else if(type === 'optional'){
        this.modifierGroups = Object.values([...this.optionMandatory, ...e].map(e => e.uid));
      }
    },

    async addCategory(){
      await this.categoryModal.patchOptions({attrs: {}});
      await this.categoryModal.open();
    },
    close(){
      useVfm().close('menu-item-editor');
    },
    setAllDeliveryModeAvailable(){
      if(this.available.delivery&&this.available.pickup&&this.available.onsite)
        this.available.delivery = this.available.pickup = this.available.onsite = false;
      else
        this.available.delivery = this.available.pickup = this.available.onsite = true;

      this.fieldChange();
    },
    fieldChange(){
      if(this.errors.length>0) this.validate();
    },

    async updateImage(){
      let image = null;

      if(this.imageData && this.imageData.some(i => i.size === 'base')) image =  this.imageData.find(i => i.size === 'base').url;
      else if(this.item && this.item.image.base) image = this.item.image.base;
      else return;

      await mediaUploadImage(image, this.currentMerchant.id, 'merchant-product-image', ['800x800', '250x250'])
          .then((result) => {
            this.imageUrl = null;
            this.imageUrl = result.find(r => r.size === '800x800').url;
            this.imageData = result;
          })
          .catch((e) => {
            console.log('Image upload error', e)
          })
    },

    async uploadImage(e){
      if(e.target.files.length === 0) return;

      await mediaUploadImage(e, this.currentMerchant.id, 'merchant-product-image', ['800x800', '250x250'])
          .then((result) => {
            this.imageUrl = null;
            this.imageUrl = result.find(r => r.size === '800x800').url;
            this.imageData = result;
          })
          .catch((e) => {
            console.log('Image upload error', e)
          })
    },

    removeImage() {
      this.imageUrl = null;
      removeProductImage(this.currentMerchant.id, this.item.id)
    },

    validate(){
      this.errors = [];
      if(!this.name) this.errors.push({error: 'name', message: 'Le nom du produit est obligatoire'})
      if(!this.currentCategory) this.errors.push({error: 'category', message: "Dans quelle catégorie se trouve le produit ?"})
      if(!this.available.delivery&&!this.available.pickup&&!this.available.onsite) this.errors.push({error: 'available', message: 'Le produit doit être disponible au moins sur un mode de livraison'})
      if(this.available.delivery && !this.price.delivery) this.errors.push({error: 'delivery_price', message: this.$t('errors.price_delivery_required')})
      if(this.available.pickup && !this.price.pickup) this.errors.push({error: 'pickup_price', message: this.$t('errors.price_pickup_required')})
      if(this.available.onsite && !this.price.onsite) this.errors.push({error: 'onsite_price', message: this.$t('errors.price_onsite_required')})
      if(this.available.delivery && this.vatPercentage.delivery === null) this.errors.push({error: 'delivery_vat', message: this.$t('errors.price_delivery_required')})
      if(this.available.pickup && this.vatPercentage.pickup === null) this.errors.push({error: 'pickup_vat', message: this.$t('errors.price_pickup_required')})
      if(this.available.onsite && this.vatPercentage.onsite === null) this.errors.push({error: 'onsite_vat', message: this.$t('errors.price_onsite_required')})
      return this.errors.length === 0;
    },
    async save(){
      if(!this.validate()){
        return;
      }
      this.isButtonLoading = true;
      if(this.item){ // Update
        await this.update();
      }
      else{ // Create
        await this.create();
      }

      // Sort


    },
    async update(){
      await this.$store.dispatch('menu/updateMenuItemBatchAction', {merchantId: this.currentMerchant.id, update: [{
          id: this.item.id,
          uid: this.item.uid,
          name: this.name,
          metas: this.metas,
          vat_onsite: this.vatPercentage.onsite,
          vat_pickup: this.vatPercentage.pickup,
          vat_delivery: this.vatPercentage.delivery,
          modifierGroups: this.modifierGroups,
          description: this.description,
          price_onsite: this.price.onsite*100,
          price_pickup: this.price.pickup*100,
          price_delivery: this.price.delivery*100,
          highlighted: this.metas.some(e => e === 'highlight'),
          status: this.item.status,
          imageData: this.imageData,
          categoryId: this.currentCategory,
          oldCategory: this.category !== this.currentCategory ? this.category : null,
          preparationDuration: this.time_preparation,
          available: this.available,
          sort: this.sort
        }], categoryForce: true})
          .catch((e) => {
            console.log('Update error', e)
            this.isButtonLoading = false;
          });
      await this.vfm.closeAll();
    },
    async create(){
      await this.$store.dispatch('menu/createMenuItemAction', {merchantId: this.currentMerchant.id, data: {
          name: this.name,
          sort: this.sort,
          description: this.description,
          imageData: this.imageData,
          metas: this.metas,
          modifierGroups: this.modifierGroups,
          price: {
            onsite: this.price.onsite*100,
            pickup: this.price.pickup*100,
            delivery: this.price.delivery*100
          },
          preparationDuration: this.time_preparation,
          vatPercentage: this.vatPercentage,
          available: this.available,
          categoryId: this.currentCategory
        }})
          .catch((e) => {
            console.log('Update error', e)
            this.isButtonLoading = false;
          });

      await this.vfm.closeAll();
    }
  },
  computed: {
    merchant() {
      return merchant
    },
    ...mapGetters({
      currentMerchant: 'currentMerchant',
      menu: 'menu/menu'
    }),
    previewMeta(){
      return this.metas.filter(m => m!=='highlight');
    },
    options(){
      return this.modifierGroups.map(uid => this.menu.modifierGroups.find(m => m.uid===uid));
    },
    optionMandatory(){
      return this.options.filter(o => o.min>0);
    },
    optionOptional(){
      return this.options.filter(o => o.min===0);
    },
    currentCategorySort(){
      if(!this.currentCategory) return [];
      const currentCategory = this.menu.categories.find(o => o.id === this.currentCategory);
      const sortItems = [];
      currentCategory.itemIds.forEach((uid) => {
        if(this.item && uid === this.item.uid) return;
        const d = this.menu.items.find(p => p.uid === uid);
        if(d.archived === true) return;
        sortItems.push(d);
      });
      return sortItems;
    }
  },
  mounted() {
    this.currentCategory = this.category;
    if(this.item){
      const currentCategory = this.menu.categories.find(c => c.itemIds.includes(this.item.uid));
      if(currentCategory){
        this.currentCategory = currentCategory.id;
        this.sort = this.item.sort;
      }
      this.name = this.item.name;

      if(this.item.image){
        this.imageUrl = this.item.image.large;
      }
      this.description = this.item.description;
      this.metas = this.item.metadata;
      this.available = this.item.orderModeAvailable;
      this.price.delivery = this.item.price.delivery/100;
      this.price.pickup = this.item.price.pickup/100;
      this.price.onsite = this.item.price.onsite/100;
      this.vatPercentage = this.item.vatPercentage;
      this.modifierGroups = this.item.children;
      this.time_preparation = this.item.preparationDuration;
      if(this.item.highlighted) this.metas.push('highlight');

      EventBus.on('menu:product:updated', (productId) => {
        if(productId === this.item.id){
          const item = this.menu.items.find(i => i.id === productId);
          this.modifierGroups = item.children;
        }
      });
    }
    else{

      if(!this.currentMerchant.configuration.hasVat){
        this.vatPercentage.onsite = 0;
        this.vatPercentage.pickup = 0;
        this.vatPercentage.delivery = 0;
      }

      this.sort = this.currentCategorySort.length;
      EventBus.on('menu:modifier-group:pending-attach', (uid) => {
        this.modifierGroups.push(uid);
      });
    }

    EventBus.on('menu:modifier-group:deleted-uid', (uid) => {
      if(this.modifierGroups.includes(uid)){
        this.modifierGroups = this.modifierGroups.filter(m => m!==uid);
      }
    });
  }
}
</script>
