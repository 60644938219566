<template>
  <modal title="Annuler une réservation" size="xs" close-id="cancel-booking-modal">


    <div>
      <label class="form-label">Raison de l'annulation</label>
      <select class="form-select" v-model="reason">
        <option value="full">Restaurant complet</option>
        <option value="closed">Fermé</option>
        <option value="merchant_client_cancel">Annulation du client</option>
      </select>
    </div>

    <div class="mt-5">
      <label class="form-label">Envoyer un message au client ?</label>
      <textarea class="form-input min-h-[100px] py-2" placeholder="Votre message..." v-model="message"></textarea>
    </div>

    <div class="mt-6 flex justify-between">
      <button class="btn bg-gray-200 text-black" @click="close">Fermé</button>
      <button class="btn bg-red-600 text-white hover:ring-red-500/20" @click="cancel">Annuler la réservation</button>
    </div>

  </modal>
</template>

<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";
export default {
  components: {Modal},
  props: {
    bookingId: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      reason: 'full',
      message: ''
    }
  },
  methods: {
    close(){
      useVfm().close('cancel-booking-modal');
    },
    cancel(){
      this.$store.dispatch('booking/cancel', {
        merchantId: this.currentMerchant.id,
        bookingId: this.bookingId,
        reason: this.reason,
        message: this.message
      });
      useVfm().closeAll();
    }
  },
  computed: {
    ...mapGetters({
      bookings: 'booking/bookings',
      bookingToConfirm: 'booking/bookingToConfirm',
      currentMerchant: 'currentMerchant'
    }),
    currentBooking(){
      if(this.bookings.items.some(e => e.id === this.booking.id)){
        return this.bookings.items.find(e => e.id === this.booking.id)
      }
      if(this.bookingToConfirm.some(e => e.id === this.booking.id)) {
        return this.bookingToConfirm.find(e => e.id === this.booking.id)
      }
    }
  }
}
</script>
