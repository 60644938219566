<template>
  <div class="border bg-white rounded-md px-6 py-4 flex flex-col" :class="{'ring-4 ring-[#559F1B]/20 border-[#559F1B]': hubrise}">
    <div class="flex items-center justify-start">
      <img src="/images/integrations/hubrise.png" class="h-12">
      <div class="flex gap-2 items-center">
        <span class="text-xl font-bold ml-4">HubRise</span>
        <span class="text-white bg-[#559F1B] pl-2 pr-3 h-6 rounded-full font-bold text-sm flex items-center ml-2" v-if="hubrise"><i class="material-icons text-base mr-1">check</i>Activé</span>
      </div>
    </div>
    <div class="pt-4 flex-1">
      <span class="block leading-7" v-if="!hubrise">HubRise connecte Delicity aux solutions que vous utilisez tous les jours. Recevez vos commandes dans votre logiciel de caisse et bien plus encore... Réalisez tous vos rêves d'intégration.</span>


      <div class="flex flex-col items-start text-gray-600 gap-1" v-else>
        <span><b class="font-medium">Point de vente:</b> {{ hubrise.auth.locationName }} ({{hubrise.auth.locationId}})</span>
        <span><b class="font-medium">Catalogue:</b> {{hubrise.auth.catalogName}} ({{hubrise.auth.catalogId}})</span>
        <span><b class="font-medium">Liste de clients:</b> {{hubrise.auth.customerListName}} ({{hubrise.auth.customerListId}})</span>
        <span><b class="font-medium">Synchronisation menu depuis HubRise:</b> {{hubrise.isMenuMaster ? '🟢 Activé' : '🔴 Désactiver'}}</span>
      </div>
    </div>
    <div class="mt-8 flex flex-wrap gap-4">
      <button class="btn bg-primary text-white" :disabled="isButtonLoading" @click="hubriseAuth" v-if="!hubrise">Connexion</button>
      <a href="https://www.hubrise.com/" target="_blank" class="flex hover:ring-0 items-center btn bg-gray-100 text-gray-600">Site de HubRise</a>
      <button class="btn bg-primary text-white" @click="openHubriseModal" v-if="hubrise">Paramètres</button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {destroyAuth, getCurrentAccount} from "@/services/Integration/HubriseService";
import {useModal} from "vue-final-modal";
import HubriseModal from "@/components/Integrations/Modals/HubriseModal.vue";

export default {
  setup(){
    const HubriseSettingModal = useModal({component: HubriseModal});
    return {HubriseSettingModal}
  },
  data(){
    return {
      isButtonLoading: false
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
    hubrise(){
      if(!this.currentMerchant.integrations.some(e => e.type === 'hubrise')) return false;
      return this.currentMerchant.integrations.find(e => e.type === 'hubrise');
    }
  },
  methods: {
    openHubriseModal(){
      this.HubriseSettingModal.open();
    },
    async hubriseAuth(){
      this.isButtonLoading = true;
      const callback = location.origin + this.$router.resolve({name: 'integrations_validation_hubrise'}).fullPath
      location.href = await getCurrentAccount(this.currentMerchant.id, callback);
      this.isButtonLoading = false;
    },

  }
}
</script>
