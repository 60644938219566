<template>
  <modal title="Ajouter une fermeture exceptionnelle" size="medium" close-id="create-closure-modal">
    <template v-slot:content>
      <div class="px-6 py-4">

        <div class="border rounded-md">
          <div class="border-b  py-5 px-6 grid grid-cols-[35%,auto] items-center">
            <span class="font-bold text-gray-600">Date de la fermeture</span>
            <div class="flex justify-between md:flex-row flex-col md:gap-0 gap-3">
              <button class="h-9 px-5 rounded-full" :class="[dateSelect==='today'?'bg-primary font-bold text-white':'border']" @click="dateSelect='today'">Aujourd'hui</button>
              <button class="h-9 px-5 rounded-full" :class="[dateSelect==='tomorrow'?'bg-primary font-bold text-white':'border']" @click="dateSelect='tomorrow'">Demain</button>
              <button class="h-9 px-5 rounded-full" :class="[dateSelect==='close'?'bg-primary font-bold text-white':'border']" @click="dateSelect='close'">Autres dates</button>
            </div>
          </div>

          <div class="border-b  py-5 px-6 grid grid-cols-[35%,auto] items-center" v-if="dateSelect==='close'">
            <span class="font-bold text-gray-600">Début de la fermeture</span>

            <div class="flex justify-between gap-5 md:flex-row flex-col">

              <vue-tailwind-datepicker :class="{'ring-2 ring-red-500/30 border-red-500 rounded-md':errors.some(e => e.field === 'startDate')}"
                                       i18n="fr"
                                       class="h-10 flex-1"
                                       as-single
                                       :disable-date="dDate"
                                       :formatter="formatter"
                                       v-model="close.startDate" />

              <div class="border h-10 flex-1 border-gray-300 rounded-lg flex" :class="{'ring-2 ring-red-500/30 border-red-500':errors.some(e => e.field === 'startHour')}">
                <div class="px-2 border-r flex items-center ">
                  <i class="text-lg material-icons">schedule</i>
                </div>
                <select name="" class="h-10 text-sm bg-transparent form-select border-0 focus:ring-0" @change="errors.length>0 ? validate() : false" v-model="close.startHour">
                  <option :value="null">-Choisir-</option>
                  <option :value="value" v-for="value in getHourInterval()">{{value}}</option>
                </select>
              </div>

            </div>

          </div>


          <div class="border-b py-5 px-6 grid grid-cols-[35%,auto] items-center" v-if="dateSelect==='close'">
            <span class="font-bold text-gray-600">Fin de la fermeture</span>

            <div class="flex justify-between gap-5 md:flex-row flex-col">

              <vue-tailwind-datepicker i18n="fr"
                                       :class="{'ring-2 ring-red-500/30 border-red-500 rounded-md':errors.some(e => e.field === 'endDate')}"
                                       class="h-10 flex-1"
                                       as-single
                                       :disable-date="dDate"
                                       :formatter="formatter"
                                       v-model="close.endDate" />

              <div class="border h-10 flex-1 border-gray-300 rounded-lg flex" :class="{'ring-2 ring-red-500/30 border-red-500':errors.some(e => e.field === 'endHour')}">
                <div class="px-2 border-r flex items-center">
                  <i class="text-lg material-icons">schedule</i>
                </div>
                <select name="" class="text-sm h-10 bg-transparent form-select border-0 focus:ring-0" @change="errors.length>0 ? validate() : false" v-model="close.endHour">
                  <option :value="null">-Choisir-</option>
                  <option :value="value" v-for="value in getHourInterval()">{{value}}</option>
                </select>
              </div>
            </div>

          </div>


          <div class=" py-5 px-6 grid grid-cols-[35%,auto] items-center">
            <span class="font-bold text-gray-600">Type de fermeture</span>
            <div class="md:h-10 h-24 border w-full flex md:items-center rounded-xl md:flex-row flex-col">

              <label for="close_restaurant" class="h-full flex-1 flex items-center px-4 cursor-pointer">
                <input type="radio" @change="errors.length>0 ? validate() : false" name="close_type" v-model="type" value="all" id="close_restaurant" class="form-radio mr-2">
                <span>Le restaurant</span>
              </label>

              <label for="close_orders" class="h-full flex-1 flex items-center px-4 md:border-l border-t md:border-t-0 cursor-pointer">
                <input type="radio" @change="errors.length>0 ? validate() : false" name="close_type" v-model="type" value="order" id="close_orders" class="form-radio mr-2">
                <span>Les commandes</span>
              </label>

            </div>
          </div>
          <small v-if="errors.some(e => e.field === 'main')" class="text-red-500 mt-4 text-sm font-medium leading-tight block mt-1">{{errors.find(e => e.field === 'main').message}}</small>

        </div>

        <div class="flex justify-end w-full mt-5">
          <button class="btn bg-primary text-white" @click="save" :disabled="isLoading">
            <span v-if="!isLoading">Enregistrer</span>
            <loader v-else class="h-4 w-4"/>
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";
import {DateTime, Interval} from "luxon";
import {createMerchantClosure, updateMerchantClosure} from "@/services/MerchantService";
import {errorToast} from "@/services/SystemService";
export default {
  props: {
    closure: {
      type: Object,
      default: null
    }
  },
  setup(){
    const formatter = {
      date: 'DD/MM/YYYY',
      month: 'MMM'
    }
    const dDate = (date) => {
      return date < DateTime.now().startOf('day').toJSDate();
    }
    return {formatter, dDate}
  },
  components: {Modal},
  data(){
    return {
      dateSelect: 'today',
      type: 'all',
      isLoading : false,
      id: null,
      close: {
        startDate: "",
        endDate: "",
        startHour: null,
        endHour: null,
        start: null,
        end: null
      },
      errors:[]
    }
  },
  methods: {
    closeModal(){
      useVfm().close('create-closure-modal')
    },
    getHourInterval(){
      const interval = Interval.fromDateTimes(DateTime.now().startOf('day'), DateTime.now().endOf('day'));
      return interval.splitBy({minutes: 15})
          .map((interval) => {
            return interval.start.toFormat('HH:mm')
          });
    },
    validate(){
      this.errors = [];

      if(this.dateSelect === 'close'){
        if(!this.close.startDate) this.errors.push({field: 'startDate'})
        if(!this.close.endDate) this.errors.push({field: 'endDate'})
        if(!this.close.startHour) this.errors.push({field: 'startHour'})
        if(!this.close.endHour) this.errors.push({field: 'endHour'})
      }

      if(!this.type) this.errors.push({field: 'type'})

      if(this.close.startDate && this.close.endDate && this.close.startHour && this.close.endHour){
        const start = DateTime.fromFormat(`${this.close.startDate} ${this.close.startHour}`, 'dd/MM/yyyy HH:mm');
        const end = DateTime.fromFormat(`${this.close.endDate} ${this.close.endHour}`, 'dd/MM/yyyy HH:mm');
        if(start.toSeconds() > end.toSeconds()){
          this.errors.push({field: 'endDate'})
          this.errors.push({field: 'endHour'})
          this.errors.push({field: 'main', message: 'La date de fin doit être supérieure à la date de début...'})
        }
      }

      return this.errors.length===0;
    },
    prefill(){
      if(this.dateSelect === 'today'){
        this.close.start = DateTime.now().startOf('day').toISO()
        this.close.end = DateTime.now().endOf('day').toISO()
      }
      else if(this.dateSelect === 'tomorrow'){
        this.close.start = DateTime.now().plus({days: 1}).startOf('day').toISO()
        this.close.end = DateTime.now().plus({days: 1}).endOf('day').toISO()
      }
      else if(this.dateSelect === 'close'){
        this.close.start = DateTime.fromFormat(`${this.close.startDate} ${this.close.startHour}`, 'dd/MM/yyyy HH:mm').setZone('Europe/Paris').toISO();
        this.close.end = DateTime.fromFormat(`${this.close.endDate} ${this.close.endHour}`, 'dd/MM/yyyy HH:mm').setZone('Europe/Paris').toISO()
      }
    },
    async save(){
      this.prefill();
      if(!this.validate()) return;
      this.isLoading = true;


      if(this.id){
        const data = await updateMerchantClosure(this.currentMerchant.id, this.id, this.close.start, this.close.end, this.type)
          .catch((e) => {
            errorToast(this.$t('errors.error_occurred'))
          })
        this.$store.commit('SET_MERCHANT_SCHEDULE', data);
      }
      else{
        const data = await createMerchantClosure(this.currentMerchant.id, this.close.start, this.close.end, this.type)
          .catch((e) => {
            errorToast(this.$t('errors.error_occurred'))
          })
        this.$store.commit('SET_MERCHANT_SCHEDULE', data);
      }

      this.isLoading = false;
      this.closeModal();
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
  },
  async mounted() {
    if(!this.currentMerchant) await this.$store.dispatch('getMerchantsAction');

    if(this.closure){
      this.id = this.closure.id;
      const start = DateTime.fromISO(this.closure.punctualStart);
      const end = DateTime.fromISO(this.closure.punctualEnd);

      if(
        start.toSeconds() === Math.round(DateTime.now().startOf('day').toSeconds())
        && end.toSeconds() === Math.round(DateTime.now().endOf('day').toSeconds())
      ){
        this.dateSelect = 'today';
      }
      else if(
        start.toSeconds() === Math.round(DateTime.now().plus({day:1}).startOf('day').toSeconds())
        && end.toSeconds() === Math.round(DateTime.now().plus({day:1}).endOf('day').toSeconds())
      ){
        this.dateSelect = 'tomorrow';
      }
      else {
        this.dateSelect = 'close';
        this.close.startDate = start.toFormat('dd/MM/yyyy');
        this.close.endDate = end.toFormat('dd/MM/yyyy');
        this.close.startHour = start.toFormat('HH:mm');
        this.close.endHour = end.toFormat('HH:mm');
      }

      this.type = this.closure.type;
    }
  }
}
</script>
