<template>
  <ModalSliding background-color="bg-gray-100" close-id="menu-category-editor">
    <div class="grid grid-cols-1 xl:grid-cols-[60%,auto] h-full">
      <div class="px-4 py-3 overflow-y-auto">
        <div class="flex items-center gap-4 mb-4">
          <button class="flex items-center justify-center bg-white border px-4 py-1 gap-2 rounded-md hover:bg-gray-50" @click="close"><i class="text-lg material-icons">arrow_back</i>Retour</button>
          <span class="text-xl font-bold uppercase" v-if="categoryId">{{ $t('menu.category.update_title') }}</span>
          <span class="text-xl font-bold uppercase" v-else>{{ $t('menu.category.create_title') }}</span>
        </div>

        <page-block>


          <div class="bg-gray-100 px-4  py-3 rounded-md mb-4 flex items-center" v-if="categoryId && category.archived">
            <i class="material-icons text-lg mr-3 text-gray-400">visibility_off</i>
            <span class="font-medium flex-1 text-gray-800">Cette catégorie est archivée</span>
            <button class="text-primary font-bold" @click="unArchive">Remettre en ligne</button>
          </div>

          <div class="grid grid-cols-[auto,40%] gap-4">
            <div>
              <label class="font-bold">{{ $t('menu.category.name') }}</label>
              <input type="text" v-model="category.name" :class="{'form-input-error':errors.some(e=>e.field==='name')}" class="form-input mt-2" :placeholder="$t('menu.category.name_placeholder')">
              <small v-if="errors.some(e => e.field === 'name')" class="text-red-500 text-sm font-medium leading-tight block mt-1">{{errors.find(e => e.field === 'name').message}}</small>

            </div>
            <div>
              <label class="font-bold">{{ $t('menu.category.sort') }}</label>
              <select v-model="category.sort" class="form-select mt-2">
                <option :value="0">En premier</option>
                <option :value="i+1" v-for="(c,i) in menu.categories.filter(i => i.id !== this.categoryId && i.archived===false)">Après {{c.name}}</option>
              </select>
            </div>
          </div>

          <div class="mt-5">
            <div>
              <label class="font-bold">{{ $t('menu.category.description') }}</label>
              <textarea v-model="category.description" class="form-input mt-2 py-1 min-h-[120px] h-full" :placeholder="$t('menu.category.description_placeholder')"></textarea>
            </div>
          </div>



          <div class="mt-5">
            <b>{{ $t('menu.category.availability') }}</b>

            <label for="all" class="flex items-center mt-2 cursor-pointer">
              <input type="radio" v-model="availability" @change="changeAvailabilityMode" name="availability" id="all" value="all" class="form-radio mr-3">
              <span class="text-gray-600">Disponibles tous les jours et sur tous les créneaux (cas général)</span>
            </label>


            <label for="some_days" class="flex items-center mt-2 cursor-pointer">
              <input type="radio" v-model="availability" @change="changeAvailabilityMode" name="availability" id="some_days" value="some_days" class="form-radio mr-3">
              <span class="text-gray-600">Disponibles que certains jours (ex: brunch le samedi et dimanche)</span>
            </label>
            <div class="flex items-center gap-4 mt-3 mb-8 pl-7" v-if="availability==='some_days'">
              <label :for="'some_day-'+i" v-for="i in 7" class="cursor-pointer">
                <input type="checkbox" name="some-days" :value="i" :id="'some_day-'+i" v-model="av.someDays" @change="someDayUpdate" class="mr-2 form-checkbox">
                <span>{{$t('days.'+i)}}</span>
              </label>
            </div>



            <label for="some_shifts" class="flex items-center mt-2 cursor-pointer">
              <input type="radio" v-model="availability" @change="changeAvailabilityMode" name="availability" id="some_shifts" value="some_shifts" class="form-radio mr-3">
              <span class="text-gray-600">Disponibles que sur certains créneaux (ex: formule midi)</span>
            </label>


            <div class="pl-7 mt-3 mb-8" v-if="availability==='some_shifts'">

              <div class="rounded-md overflow-hidden border w-56 flex">
                <select class="form-select border-0 text-sm border-r rounded-none flex-1 focus:ring-0" @change="someSlotUpdate" v-model="av.someSlots.start">
                  <option :value="null">Choisir</option>
                  <template v-for="hours in getDayHours()">
                    <option :value="hours">
                      {{hours.value}}
                    </option>
                  </template>
                </select>
                <select class="form-select border-0 text-sm  rounded-none flex-1 focus:ring-0" @change="someSlotUpdate" v-model="av.someSlots.end">
                  <option :value="null">Choisir</option>
                  <template v-for="hours in getDayHours()">
                    <option :value="hours" v-if="!(av.someSlots.start && av.someSlots.start.seconds >= hours.seconds)">
                      {{hours.value}}
                    </option>
                  </template>
                </select>
              </div>

            </div>



            <label for="timetable" class="flex items-center mt-2 cursor-pointer">
              <input type="radio" v-model="availability" @change="changeAvailabilityMode" name="availability" id="timetable" value="timetable" class="form-radio mr-3">
              <span class="text-gray-600">Disponibles que certains jours + certains créneaux</span>
            </label>
            <div class="pl-7 mt-3 mb-8 gap-4 flex flex-col" v-if="availability==='timetable'">

              <div class="grid grid-cols-[110px,auto] items-center" v-for="day in av.timetable" >
                <label :for="'timetable-'+day.day" class="cursor-pointer">
                  <input type="checkbox" name="some-days" :value="day.day" @change="timetableUpdate" :id="'timetable-'+day.day" v-model="day.enabled" class="mr-2 form-checkbox">
                  <span>{{$t('days.'+day.day)}}</span>
                </label>

                <div class="rounded-md overflow-hidden border w-56 flex" v-if="day.enabled">
                  <select class="form-select border-0 text-sm border-r rounded-none flex-1 focus:ring-0" @change="timetableUpdate" v-model="day.start">
                    <option :value="null">Choisir</option>
                    <template v-for="hours in getDayHours()">
                      <option :value="hours">
                        {{hours.value}}
                      </option>
                    </template>
                  </select>
                  <select class="form-select border-0 text-sm  rounded-none flex-1 focus:ring-0" @change="timetableUpdate" v-model="day.end">
                    <option :value="null">Choisir</option>
                    <template v-for="hours in getDayHours()">
                      <option :value="hours" v-if="!(day.start && day.start.seconds >= hours.seconds)">
                        {{hours.value}}
                      </option>
                    </template>
                  </select>
                </div>

              </div>

            </div>


            <label for="one_date" class="flex items-center mt-2 cursor-pointer">
              <input type="radio" v-model="availability" @change="changeAvailabilityMode" name="availability" id="one_date" value="one_date" class="form-radio mr-3">
              <span class="text-gray-600">Disponibles qu'à une certaine date (ex: formule Saint-Valentin)</span>
            </label>
            <div class="pl-7 mt-3 mb-8" v-if="availability==='one_date'">
              <input type="date" class="form-input max-w-[200px]" :min="minDate" @input="dayOnlyUpdate" v-model="av.dateOnly">
            </div>



            <label for="between_two_date" class="flex items-center mt-2 cursor-pointer">
              <input type="radio" v-model="availability" @change="changeAvailabilityMode" name="availability" id="between_two_date" value="between_two_date" class="form-radio mr-3">
              <span class="text-gray-600">Disponibles entre deux dates (ex: semaine de Noël)</span>
            </label>
            <div class="pl-7 mt-3 mb-8" v-if="availability==='between_two_date'">
              <div class="flex gap-3">
                <div>
                  <span class="font-medium">Début</span>
                  <input type="date" class="form-input max-w-[200px] mt-1" @change="updateBetweenDate" :min="minDate" v-model="av.betweenTwoDates.start">
                </div>
                <div>
                  <span class="font-medium">Fin</span>
                  <input type="date" class="form-input max-w-[200px] mt-1" @change="updateBetweenDate" :min="av.betweenTwoDates.start" v-model="av.betweenTwoDates.end">
                </div>
              </div>
            </div>

          </div>



          <div class="mt-5" v-if="false">
            <b>Catégorie en précommande uniquement</b>
            <label class="mt-2 flex items-center cursor-pointer text-gray-600" for="preorder_only"><input type="checkbox" id="preorder_only" class="form-checkbox mr-2">Les produits de cette catégorie ne sont disponibles qu'en précommande</label>
          </div>
          <div class="mt-5" v-if="false">
            <b>Temps minimum pour la précommande</b>
            <label for="" class="flex items-center mt-2">
              <input type="radio" class="form-radio mr-3">
              <span class="text-gray-600">Minimum 2h avant</span>
            </label>
            <label for="" class="flex items-center mt-2">
              <input type="radio" class="form-radio mr-3">
              <span class="text-gray-600">Minimum la veille pour le lendemain</span>
            </label>
            <label for="" class="flex items-center mt-2">
              <input type="radio" class="form-radio mr-3">
              <span class="text-gray-600">Minimum 1 jour avant (soit 24h)</span>
            </label>
            <label for="" class="flex items-center mt-2">
              <input type="radio" class="form-radio mr-3">
              <span class="text-gray-600">Personnaliser</span>
            </label>
          </div>
        </page-block>


        <div class="flex justify-between">
          <button class="btn bg-primary text-white mt-4" :disabled="isButtonLoading" @click="save">{{ $t('cta.save')}}</button>
          <button class="btn bg-transparent font-medium text-sm border border-red-600/50 text-red-600 focus:ring-red-400/50 hover:ring-red-400/50 mt-4"
                  :disabled="isButtonLoading"
                  v-if="categoryId && !category.archived"
                  @click="archive">{{products.length > 0 ? 'Archiver la catégorie et ses produits' : 'Archiver la catégorie'}}</button>
        </div>
      </div>
    </div>
  </ModalSliding>
</template>

<script>
import ModalSliding from "@/components/Ui/Modals/ModalSliding.vue";
import PageBlock from "@/components/Ui/Elements/PageBlock.vue";
import {mapGetters} from "vuex";
import {useModal, useVfm} from "vue-final-modal";
import ConfirmArchiveCategory from "@/components/Menu/Modals/ConfirmArchiveCategory.vue";
import {Interval, DateTime} from "luxon";
import ScheduleManagement from "@delicity/backend-schedule-management";

export default {
  components: {PageBlock, ModalSliding},
  props: {
    categoryId: {
      type: Number,
      default: null
    }
  },
  setup(){
    const archiveConfirmModal = useModal({component: ConfirmArchiveCategory});
    return {archiveConfirmModal}
  },
  data(){
    return {
      category: {
        name: null,
        sort: 0,
        description: null,
      },
      timetable: null,
      availability: "all",
      av: {
        someDays: [],
        someSlots: {start: null, end: null},
        dateOnly: null,
        timetable: [
          {day: 1, enabled: false, start: null, end: null},
          {day: 2, enabled: false, start: null, end: null},
          {day: 3, enabled: false, start: null, end: null},
          {day: 4, enabled: false, start: null, end: null},
          {day: 5, enabled: false, start: null, end: null},
          {day: 6, enabled: false, start: null, end: null},
          {day: 7, enabled: false, start: null, end: null},
        ],
        betweenTwoDates: {start: null, end: null}
      },
      isButtonLoading: false,
      errors: []
    }
  },
  methods: {
    importAvailability(availability){
      if(availability.slots.length===0) return;

      if(this.availability === 'some_days'){
        this.av.someDays = Array.from(new Set(availability.slots.map(s => s.day)).values());
        this.someDayUpdate();
      }
      else if(this.availability === 'between_two_date') {
        this.av.betweenTwoDates = availability.betweenTwoDates;
        this.updateBetweenDate();
      }
      else if(this.availability === 'some_shifts'){
        const start = availability.slots[0].start
        const end = availability.slots[0].end;

        this.av.someSlots.start = {
          value: start,
          seconds: DateTime.now().set({weekDay: 1, hour: start.split(':')[0], minute: start.split(':')[1]}).startOf('minutes').toSeconds(),
          weekDay: 1
        };
        this.av.someSlots.end = {
          value: end,
          seconds: DateTime.now().set({weekDay: 1, hour: end.split(':')[0], minute: end.split(':')[1]}).startOf('minutes').toSeconds(),
          weekDay: 1
        };

        this.someSlotUpdate();
      }
      else if(this.availability === 'timetable'){
        availability.slots.forEach((slot) => {
          const timetableIndex = this.av.timetable.findIndex(t => t.day === slot.day);
          this.av.timetable[timetableIndex] = {
            day: slot.day,
            enabled: true,
            start: {
              value: slot.start,
              seconds: DateTime.now().set({weekDay: 1, hour: slot.start.split(':')[0], minute: slot.start.split(':')[1]}).startOf('minutes').toSeconds(),
              weekDay: 1
            },
            end: {
              value: slot.end,
              seconds: DateTime.now().set({weekDay: 1, hour: slot.end.split(':')[0], minute: slot.end.split(':')[1]}).startOf('minutes').toSeconds(),
              weekDay: 1
            }
          };
        });
        this.timetableUpdate();
      }
      else if(this.availability === 'one_date'){
        this.av.dateOnly = availability.startDate;
        this.dayOnlyUpdate();
      }
    },
    changeAvailabilityMode(){
      this.resetTimetable();
      if(this.availability === 'some_days'){
        this.someDayUpdate();
      }
      else if(this.availability === 'some_shifts'){
        this.someSlotUpdate();
      }
      else if(this.availability === 'timetable'){
        this.timetableUpdate();
      }
      else if(this.availability === 'one_date'){
        this.dayOnlyUpdate();
      }
    },
    resetTimetable(slots=[]){
      this.timetable = new ScheduleManagement(slots,[], {closeFilter: [], closeModeFilter: []});
    },



    dayOnlyUpdate(){
      const date = DateTime.fromFormat(this.av.dateOnly, 'yyyy-MM-dd');
      const slots = [];
      slots.push({
        day: date.weekday,
        start: '00:00',
        end: '23:59'
      });
      this.resetTimetable(slots);
    },

    someDayUpdate(){
      const slots = [];
      for(let day of this.av.someDays){
        slots.push({
          day: day,
          start: '00:00',
          end: '23:59'
        });
      }
      this.resetTimetable(slots);
    },


    updateBetweenDate(){
      const slots = [];
      for (let i = 1; i <= 7; i++) {
        slots.push({
          day: i,
          start: '00:00',
          end: '23:59'
        });
      }
      this.resetTimetable(slots);
    },

    someSlotUpdate(){
      if(!this.av.someSlots.start || !this.av.someSlots.end) return;
      const slots = [];
      for (let i = 1; i <= 7; i++) {
        slots.push({
          day: i,
          start: this.av.someSlots.start.value,
          end: this.av.someSlots.end.value
        });
      }
      this.resetTimetable(slots);
    },

    timetableUpdate(){
      const slots = [];
      for(const timetable of this.av.timetable) {
        if (timetable.start && timetable.end && timetable.enabled) {
          slots.push({
            day: timetable.day,
            start: timetable.start.value,
            end: timetable.end.value
          });
        }
      }
      this.resetTimetable(slots);
    },

    validate(){
      this.errors = [];
      if(!this.category.name) this.errors.push({field: 'name', message: 'Entrez le nom de la catégorie'})
      return this.errors.length === 0;
    },

    close(){
      useVfm().close('menu-category-editor');
    },


    async save(){
      if(!this.validate()) return;


      this.isButtonLoading = false;
      if(this.categoryId){
        // Update
        await this.$store.dispatch('menu/updateMenuCategoryBatchAction', {
          merchantId: this.currentMerchant.id,
          update: [{
            ...this.category,
            availability: this.timetable.slots,
            availabilityType: this.availability,
            availabilityBetweenTwoDates: this.availability === 'between_two_date' ? this.av.betweenTwoDates : null,
            availabilityDateOnly: this.av.dateOnly
          }]
        });
      }
      else {
        // Create
        await this.$store.dispatch('menu/createCategoryAction', {
          merchantId: this.currentMerchant.id,
          name: this.category.name,
          description: this.category.description,
          sort: this.category.sort,
          availability: this.timetable.slots,
          availabilityType: this.availability,
          availabilityBetweenTwoDates: this.availability === 'between_two_date' ? this.av.betweenTwoDates : null,
          availabilityDateOnly: this.av.dateOnly
        });
      }
      this.isButtonLoading = false;
      this.close();
    },
    archive(){
      this.archiveConfirmModal.patchOptions({attrs: {categoryId: this.categoryId}});
      this.archiveConfirmModal.open();
    },
    async unArchive(){
      await this.$store.dispatch('menu/setArchiveStatusCategoryAction', {
        merchantId: this.currentMerchant.id,
        categoryId: this.categoryId,
        archived: false
      });
      this.close();
    },
    getDayHours(){
      let intervalStart = DateTime.now().set({weekDay: 1}).startOf('day');
      let intervalEnd = DateTime.now().set({weekDay: 1}).endOf('day');
      const interval = Interval.fromDateTimes(intervalStart, intervalEnd);
      const split = interval.splitBy({minutes: 15});
      return split.map((d) => {
        return {
          seconds: d.start.toSeconds(),
          value: d.start.toFormat('HH:mm'),
          weekDay: d.start.weekday
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      menu: 'menu/menu',
      currentMerchant: 'currentMerchant'
    }),
    minDate(){
      return DateTime.now().toFormat('yyyy-MM-dd');
    },
    products(){
      if(!this.categoryId) return [];

      const itemIds = this.menu.categories.find(category => category.id === this.categoryId).itemIds;
      return this.menu.items.filter(i => !i.archived).filter(i => itemIds.includes(i.uid));
    },
  },
  created(){
    this.resetTimetable();

    if(this.categoryId) {
      const category = JSON.parse(JSON.stringify(this.menu.categories.find(c => c.id === this.categoryId)));
      this.category.name = category.name;
      this.category.description = category.description;
      this.category.archived = category.archived;
      this.category.sort = this.menu.categories.filter(i => i.archived===false).findIndex(c => c.id === this.categoryId);
      this.category.id = category.id;
      if(category.availability){
        this.availability = category.availability.type;
        this.importAvailability(category.availability);
      }
    }
  }
}
</script>
