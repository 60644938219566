import introJs from "intro.js";

const globalSteps = [
  // {
  //   id: 'analytics',
  //   steps: [
  //     {
  //       title: 'Bienvenue',
  //       intro: 'Bienvenue sur votre back-office marchand ! 👋'
  //     },
  //     {
  //       intro: 'Faisons un tour d\'horizon des fonctionnalités auxquelles vous avez accès'
  //     },
  //     {
  //       element: '#tour_needHelp',
  //       intro: 'Vous pourrez retrouver toutes les informations en cliquant ici',
  //       position: 'bottom',
  //     },
  //     {
  //       element: '#tour_merchantLink',
  //       intro: 'Cliquez ici pour accéder aux informations de votre établissement',
  //       position: 'bottom',
  //     }
  //   ]
  // },
  {
    id: 'merchant',
    steps: [
      {
        title: 'Bienvenue',
        intro: '😀😀😀😀😀'
      },
    ]
  },
  // {
  //   id: 'orders_preorders',
  //   steps: [
  //     {
  //       title: 'Bienvenue',
  //       intro: 'Page précommandes'
  //     },
  //   ]
  // },
  {
    id: 'menu',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page produits'
      },
    ]
  },
  {
    id: 'menu_options',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page options'
      },
    ]
  },
  {
    id: 'booking',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page réservations'
      },
    ]
  },
  {
    id: 'booking_settings',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page paramètres réservation'
      },
    ]
  },
  {
    id: 'clients',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page clients'
      },
    ]
  },
  {
    id: 'marketing',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page marketing'
      },
    ]
  },
  {
    id: 'accounting_summary_list',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page récpa mensuels'
      },
    ]
  },
  {
    id: 'accounting_bank_transfers',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page virements bancaires'
      },
    ]
  },
  {
    id: 'accounting_platform_invoices',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page factures Delicity'
      },
    ]
  },
  {
    id: 'accounting_courier_invoices',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page factures livreurs'
      },
    ]
  },
  {
    id: 'hours',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page horaires'
      },
    ]
  },
  {
    id: 'settings_account',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page compte'
      },
    ]
  },
  {
    id: 'settings_password',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page changement mot de passe'
      },
    ]
  },
  {
    id: 'settings_admin',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page administratif'
      },
    ]
  },
  {
    id: 'integrations',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page intégrations'
      },
    ]
  },
  {
    id: 'product_details',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page détails des produits'
      },
    ]
  },
  {
    id: 'product_add',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page créer un produit'
      },
    ]
  },
  {
    id: 'options_details',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page détails des options'
      },
    ]
  },
  {
    id: 'options_add',
    steps: [
      {
        title: 'Bienvenue',
        intro: 'Page créer une option'
      },
    ]
  },
]

const availableTourRoutes = [
  'orders_list',
  'orders_settings',
  'analytics',
]

export function siteTour(tourId, stepsWithConditions){
  const hasDoneTour = localStorage.getItem('tour_'+tourId);
  if(hasDoneTour) return;

  let guide = introJs();

  const steps = buildStepsFromConditions(stepsWithConditions);

  guide.setOptions({
    steps: steps,
    showBullets: false,
    nextLabel: 'Suivant',
    prevLabel: 'Précédent',
    doneLabel: 'Terminé'
  })
  guide.start();

  guide.oncomplete(() => localStorage.setItem('tour_'+tourId, 'done'));
  guide.onexit(() => localStorage.setItem('tour_'+tourId, 'done'));

  return guide;
}

export function resetTour(tourId){
  localStorage.removeItem('tour_'+tourId);
}

export function hasTourAvailable(route){
  return(availableTourRoutes.includes(route));
}

function buildStepsFromConditions(stepsWithConditions){
  return stepsWithConditions.filter(step => step.toAdd).map(step => step.option)
}
