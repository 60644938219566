<template>
  <div :class="containerClass">
    <select v-model="country" :disabled="disabled" :class="countrySelectorClass">
      <option :value="c.code" v-for="c in countryList">{{c.flag}} {{c.code}}</option>
    </select>
    <input type="text" :disabled="disabled" :class="inputClass" v-model="phone">
  </div>
</template>

<style>
.phone-input__container {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  height: 35px;
}
.phone-input-country__selector {
  height: 100%;
  background-color: #fff;
  padding: 0 25px 0 15px;
  outline: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23333' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.8' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  -webkit-print-color-adjust: exact;
  background-repeat: no-repeat;
  print-color-adjust: exact;
  background-position: right 5px center;
  background-size: 1.5em 1.5em;

}
.phone-input__input {
  height: 100%;
  background-color: #fff;
  outline: none;
  border-left: 1px solid #ddd;
  padding-left: 10px;
  flex: 1;
}
.phone-input__input:disabled {
  background-color: #f4f4f4;
  opacity: 0.75;
}
.phone-input-country__selector:disabled {
  background-color: #f4f4f4;
}
</style>

<script>
import CountryList from 'country-list-with-dial-code-and-flag'
import {AsYouType, parsePhoneNumber, ParseError} from 'libphonenumber-js'
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    hasCountrySelector: {
      type: Boolean,
      default: true
    },
    countrySelectorClass: {
      type: String,
      default: 'phone-input-country__selector'
    },
    inputClass: {
      type: String,
      default: 'phone-input__input'
    },
    containerClass: {
      type: String,
      default: 'phone-input__container'
    },
    defaultCountry: {
      type: String,
      default: 'FR'
    },
    modelValue: {
      default: ''
    }
  },
  data(){
    return {
      phone: '',
      country: '',
      countryList: [],
      isValidPhone: false
    }
  },
  methods: {
    isValid(){
      return this.isValidPhone;
    }
  },
  watch: {
    phone(){
      if(this.phone === '' || this.phone === null){
         this.$emit('update:modelValue', null);
        return;
      }
      try {
        const phoneNumber = parsePhoneNumber(this.phone, this.country);
        // Format phone number as you type
        this.phone = new AsYouType(this.country).input(this.phone);
        // Update & Send isValid Event
        if(this.isValidPhone !== phoneNumber.isValid()){
          this.isValidPhone = !this.isValidPhone;
          this.$emit('isValid', this.isValidPhone);
        }
        // Update v-model value
        this.$emit('update:modelValue', this.isValidPhone ? phoneNumber.formatInternational() : null);
      }
      catch (error) {
        if (error instanceof ParseError) {
          console.log(error.message)
        } else {
          throw error
        }
      }
    },
    modelValue(){
      this.phone = this.modelValue;
    }
  },
  computed: {

  },

  mounted() {
    this.country = this.defaultCountry;
    this.countryList = CountryList.getAll();
    this.phone = this.modelValue;
  }
}
</script>
