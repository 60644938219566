<template>
  <modal size="large" :no-scroll="true" close-id="search-google-modal">
    <div class="flex justify-end">
      <button @click="closeModal"><i class="material-icons text-3xl">close</i></button>
    </div>
    <div class="flex justify-center items-center flex-col text-center pt-20 pb-32 px-4 md:px-14">
      <img src="/images/google.svg" alt="">
      <h1 class="font-bold text-2xl mt-5">Votre établissement sur Google</h1>
      <span class="text-gray-600 mt-2">Retrouvez votre établissement sur Google pour récupérer toutes les informations basiques (adresses, horaires, fréquentation, etc...)</span>

      <div class="flex relative md:w-2/3 mt-14" v-if="!selected">
        <i class="material-icons h-11 flex items-center justify-center absolute w-12">search</i>
        <input type="text" class="form-input h-11 pl-12" v-model="search" @input="onSearch" placeholder="Rechercher par nom ou par adresse...">
      </div>

      <div class="w-2/3 relative flex item-start h-0" v-if="results.length > 0 && showResult && !selected">
        <div class="absolute z-50 w-full max-h-[300px] bg-white border rounded-md border-gray-300 mt-1 overflow-y-auto">
          <div class="flex flex-col items-start text-left pl-14 pr-6 py-2 border-b last:border-0 cursor-pointer hover:bg-primary/5" @click="select(result)" v-for="result in results">
            <b class="text-gray-800">{{result.name}}</b>
            <span class="leading-tight text-gray-700">{{result.address}}</span>
          </div>
        </div>
      </div>

      <div class="flex justify-start border p-2 rounded-md mt-14 w-2/3 " v-if="selected">
        <div class="h-10 w-10 bg-primary rounded-md text-white flex items-center justify-center">
          <i class="material-icons text-xl">store</i>
        </div>
        <div class="flex-1 flex flex-col text-left justify-center text-sm pl-3 leading-tight">
          <b>{{selected.name}}</b>
          <span>{{selected.address}}</span>
        </div>
        <div>
          <button class="leading-none text-xs text-red-700 flex items-center h-10 font-medium" @click="selected=null">
            Changer
            <i class="material-icons text-sm ml-1">close</i>
          </button>
        </div>
      </div>


    </div>
    <div class="flex justify-end w-full">
      <button class="btn bg-primary text-white" @click="save" :disabled="!selected">{{$t('cta.save')}}</button>
    </div>
  </modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {searchMerchantOnGoogle, setMerchantPlaceId} from "@/services/MerchantService";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";
export default {
  components: {Modal},
  data(){
    return {
      search: '',
      results: [],
      showResult: false,
      searchTimeout: null,
      selected: null
    }
  },
  methods: {
    onSearch(){
      if(this.searchTimeout){
        clearTimeout(this.searchTimeout);
      }
      if(this.search.length<3){
        this.results = [];
        this.showResult = false;
        return;
      }
      this.searchTimeout = setTimeout(async () => {
        await searchMerchantOnGoogle(this.search)
            .then((r) => {
              this.results = r;
              this.showResult = true;
            })
            .catch(() => {
              this.showResult = false;
              this.results = [];
            })
      }, 500);
    },
    select(result){
      this.showResult = false;
      this.selected = result;
    },
    async save(){
      if(!this.selected) return;
      await setMerchantPlaceId(this.currentMerchant.id, this.selected.id);
      this.$store.commit('SET_MERCHANT_ONBOARDING_VALUE', {type: 'googleId', value: true});
      this.closeModal();
    },
    closeModal(){
      useVfm().close('search-google-modal');
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: "currentMerchant"
    })
  },
  mounted() {

  }
}
</script>
