<template>
  <div class="flex flex-col h-[100vh]">
    <div class="bg-primary flex items-center justify-center py-2">
      <img src="/images/logosquare.png" class="h-36">
    </div>
    <div class="flex lg:justify-center py-10 flex-col text-center items-center px-5 mx-auto flex-1">
      <span class="font-bold text-3xl lg:text-4xl">Bienvenue sur Delicity</span>
      <span class="mt-4 text-lg lg:text-2xl text-gray-800 leading-tight">La solution du restaurant pour livrer ses clients <br><b class="underline">en direct</b> et <b class="underline">sans commission</b>. 💙</span>
      <loader class="mt-20 mb-4"/>
      <span class="text-sm">Création de votre restaurant en cours...</span>
    </div>
    <div class="text-center py-10">
      <span class="text-sm font-medium">Made in France with love. 🇫🇷</span>
    </div>

  </div>
</template>

<script>
import Loader from "@/components/Ui/Elements/Loader.vue";
import {checkAccountCreation} from "@/services/OnboardingService";
import {errorToast} from "@/services/SystemService";

export default {
  components: {Loader},
  methods: {
    checkForAccountCreation(){
      console.log('Checking for account creation', this.$route.query.tally_id);
      checkAccountCreation(this.$route.query.tally_id)
    }
  },
  mounted() {

    if(!this.$route.query.tally_id) {
      errorToast('Une erreur est survenue lors de la création de votre compte. Veuillez vous connecter avec les identifiants que vous avez reçus par email.');
      this.$router.push('/login')
      return;
    }

    setInterval(() => {
      this.checkForAccountCreation();
    }, 4000);
    this.checkForAccountCreation();
  }
}
</script>
