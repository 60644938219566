<template>
  <merchant-layout title="Intégrations">
    <div class="mb-6 text-center md:text-left">
      <span class="text-lg">Connectez votre boutique Delicity à d'autres services.</span>
    </div>
    <div class="grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6">
      <hubrise-block/>
<!--      <rushour-block/>-->
    </div>
    <div>
      <IntegrationMenuSyncHistory/>
    </div>
  </merchant-layout>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import HubriseBlock from "@/components/Integrations/HubriseBlock.vue";
import RushourBlock from "@/components/Integrations/RushourBlock.vue";
import IntegrationMenuSyncHistory from "@/components/Integrations/IntegrationMenuSyncHistory.vue";

export default {
  components: {IntegrationMenuSyncHistory, RushourBlock, HubriseBlock},
  data(){
    return {
      integrations: ['hubrise', 'rushour']
    }
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    })
  },
  async mounted() {
    if(!this.currentMerchant) await this.$store.dispatch('getMerchantsAction');
  }
}
</script>
