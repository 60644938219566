<template>
  <div class="bg-white shadow-lg rounded-md overflow-hidden flex flex-col">
    <div class="h-20 w-full flex items-center px-6" :style="'background-color:'+headerColor">
       <i class="material-icons text-[2.6rem] text-white">{{ icon }}</i>
    </div>
    <div class="pb-1 pt-4 px-6 pr-10">
      <span class="uppercase text-primary font-bold text-lg leading-tight">{{title}}</span>
    </div>
    <div class="flex-1 px-6  text-gray-700 leading-tight">
      <span class="text-sm">{{description}}</span>
    </div>
    <div class="flex justify-start px-6 py-5">
      <a :href="href" target="_blank" class="cursor-pointer btn text-sm h-9 bg-primary text-white block flex items-center">Commencer</a>
    </div>
  </div>
</template>

<script>
export default {
  href: {
    type: String,
    default: '#'
  },
  props: {
    headerColor: {
      type: String,
      default: '#ccc'
    },
    icon: {
      type: String,
      default: 'auto_stories'
    },
    href: {
      type: String,
      default: '#'
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    }
  }
}
</script>
