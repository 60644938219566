<template>
  <div class="rounded-lg mb-5">
    <div class="flex justify-between items-center h-16 bg-[#333333] pl-3 rounded-t-xl">
      <div class="flex items-center pr-3 cursor-grab">
        <i class="material-icons text-gray-400">drag_indicator</i>
      </div>
      <div class="flex flex-col flex-1">
        <span class="font-bold text-white leading-none">{{option.name}}</span>
        <small class="text-gray-400">{{ optionSubtitle }}</small>
      </div>
      <div class="h-14 flex items-center justify-center px-2">
        <menu-button no-button no-border icon="more_vert" icon-class="text-white">
          <template v-slot:menu>
            <menu-button-item :disabled="currentMerchant.features.menuMinorEditionOnly" icon="edit" text="Modifier l'option" @click="updateOption"/>
            <menu-button-item icon="auto_awesome" text="Rendre l'option facultative" v-if="option.min>0" @click="setModifierGroupOptional"/>
            <menu-button-item icon="auto_awesome" text="Rendre l'option obligatoire" v-if="option.min===0" @click="setModifierGroupMandatory"/>
            <menu-button-item icon-class="text-red-500" :disabled="currentMerchant.features.menuMinorEditionOnly" icon="delete" text="Supprimer cette option" @click="deleteModifierGroup"/>
          </template>
        </menu-button>
      </div>
    </div>

    <table class="w-full">
      <tr v-for="modifier in modifiers.sort((a,b) => a.sort - b.sort)">
        <td class="border px-4 py-3 relative h-12">
          <input type="text" v-model="modifier.name" :disabled="currentMerchant.features.menuMinorEditionOnly" @change="updateModifier(modifier)" placeholder="Nom de l'option" class="disabled:opacity-50 disabled:bg-gray-100 font-medium absolute px-3 w-full h-full outline-none focus:ring-4 focus:rounded-sm focus:ring-blue-400/70 resize-none left-0 focus:shadow-xl top-0 bg-transparent focus:z-50" step="0.01">

          <div @click="linkValueToProduct(modifier)" class="absolute h-12 top-0 px-3 cursor-pointer flex text-primary items-center right-0" v-if="menu.items.some(m => m.archived===false && m.id === modifier.parentProductId)">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-primary h-4 pr-1">
              <path d="M8.58995 11.4101C8.99995 11.8001 8.99995 12.4401 8.58995 12.8301C8.19995 13.2201 7.55995 13.2201 7.16995 12.8301C5.21995 10.8801 5.21995 7.71007 7.16995 5.76007L10.7099 2.22007C12.6599 0.270068 15.8299 0.270068 17.7799 2.22007C19.7299 4.17007 19.7299 7.34007 17.7799 9.29007L16.2899 10.7801C16.2999 9.96007 16.1699 9.14007 15.8899 8.36007L16.3599 7.88007C17.5399 6.71007 17.5399 4.81007 16.3599 3.64007C15.1899 2.46007 13.2899 2.46007 12.1199 3.64007L8.58995 7.17007C7.40995 8.34007 7.40995 10.2401 8.58995 11.4101ZM11.4099 7.17007C11.7999 6.78007 12.4399 6.78007 12.8299 7.17007C14.7799 9.12007 14.7799 12.2901 12.8299 14.2401L9.28995 17.7801C7.33995 19.7301 4.16995 19.7301 2.21995 17.7801C0.269946 15.8301 0.269946 12.6601 2.21995 10.7101L3.70995 9.22007C3.69995 10.0401 3.82995 10.8601 4.10995 11.6501L3.63995 12.1201C2.45995 13.2901 2.45995 15.1901 3.63995 16.3601C4.80995 17.5401 6.70995 17.5401 7.87995 16.3601L11.4099 12.8301C12.5899 11.6601 12.5899 9.76007 11.4099 8.59007C10.9999 8.20007 10.9999 7.56007 11.4099 7.17007Z" fill="currentColor"/>
            </svg>
            <span class="text-sm font-medium">{{menu.items.find(m => m.id === modifier.parentProductId).name}}</span>
          </div>

        </td>
        <td class="border px-4 w-[90px] md:w-[150px] text-right relative">
          <input type="number" :disabled="currentMerchant.features.menuMinorEditionOnly" v-model="modifier.price" @change="updateModifier(modifier)" placeholder="Gratuit" :class="{'font-medium':modifier.price}" class="disabled:opacity-50 disabled:bg-gray-100 text-right absolute px-3 w-full h-full outline-none focus:ring-4 focus:rounded-sm focus:ring-blue-400/70 resize-none left-0 focus:shadow-xl top-0 bg-transparent focus:z-50" step="0.01">
        </td>
        <td class="border relative w-[125px] md:w-[180px]">
          <select @change="updateModifier(modifier)" v-model="modifier.unavailable" class="form-select text-sm md:text-base absolute font-medium text-gray-800 top-0 left-0 border-0 h-full rounded-none focus:ring-4 focus:rounded-sm focus:ring-blue-400/70 focus:z-50 ">
            <option :value="false">✅ Disponible</option>
            <option :value="true">❌ Indisponible</option>
          </select>
        </td>
      </tr>
    </table>
    <div v-if="modifiers.length===0" class="border px-4 py-2 text-center">
      <span class="text-gray-500 mr-2">Pas de choix dans cette option</span>
      <button class="text-primary underline font-bold">Ajouter des choix</button>
    </div>
  </div>
</template>

<script>
import MenuButton from "@/components/Ui/Elements/MenuButton.vue";
import MenuButtonItem from "@/components/Ui/Elements/MenuButtonItem.vue";
import {mapGetters} from "vuex";
import {formatPrice} from "../../../services/FormatService";
import OptionEditor from "@/components/Menu/Editor/OptionEditor.vue";
import {useModal} from "vue-final-modal";
import EventBus from "@/EventBus";
import linkModifierToItem from "@/components/Menu/Modals/LinkModifierToItem.vue";

export default {
  components: {MenuButton, MenuButtonItem},
  props: {
    option: {
      type: Object,
      default: () => {}
    },
    productId: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      required: true
    }
  },
  data(){
    return {
      modifiers: null
    }
  },
  setup(){
    const optionEditorModal = useModal({component: OptionEditor});
    const linkModifierToItemModal = useModal({component: linkModifierToItem});
    return {optionEditorModal, linkModifierToItemModal}
  },
  methods: {
    formatPrice,
    linkValueToProduct(modifier){
      this.linkModifierToItemModal.patchOptions({attrs: {forceSave: true, modifierUid: modifier.uid, actualLink: modifier.parentProductId}});
      this.linkModifierToItemModal.open();
    },
    updateModifier(m){
      this.$store.dispatch('menu/updateMenuModifierBatchAction', {
        merchantId: this.currentMerchant.id,
        modifierGroupId: this.option.id,
        update: [{
          id: m.id,
          name: m.name,
          price: Math.round(m.price * 100),
          description: m.description,
          available: !m.unavailable,
          productId: m.parentProductId ?? null
        }]
      })
    },
    updateOption(){
      if(this.currentMerchant.features.menuMinorEditionOnly) return;
      this.optionEditorModal.patchOptions({attrs: {type: this.type, optionUid: this.option.uid, productId:this.productId}});
      this.optionEditorModal.open();
    },
    setModifierGroupOptional(){
      this.$store.dispatch('menu/updateMenuModifierGroupBatchAction', {merchantId: this.currentMerchant.id, update: [{
          id: this.option.id,
          sort: this.option.sort,
          name: this.option.name,
          min: 0,
          max: this.option.max,
          quantitySelector: this.option.quantitySelector
        }]
      })
    },
    setModifierGroupMandatory(){
      this.$store.dispatch('menu/updateMenuModifierGroupBatchAction', {merchantId: this.currentMerchant.id, update: [{
          id: this.option.id,
          sort: this.option.sort,
          name: this.option.name,
          min: this.option.max,
          max: this.option.max,
          quantitySelector: this.option.quantitySelector
        }]
      })
    },
    deleteModifierGroup(){
      this.$store.dispatch('menu/detachModifierGroupAction', {
        merchantId: this.currentMerchant.id,
        modifierGroupId: this.option.id,
        productId: this.productId
      })
    },
    syncOptions() {
      this.modifiers = JSON.parse(JSON.stringify(this.menu.modifiers.filter(modifier => this.option.itemIds.includes(modifier.uid))));
      this.modifiers = this.modifiers.map((m) => {
        m.price = m.price === 0 ? m.price = null : m.price/100;
        return m;
      })
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant',
      menu: 'menu/menu'
    }),
    optionSubtitle(){
      const parts = [];
      if(this.option.min===0){
        parts.push(this.$t('options.optionnal_choose', {count:this.option.max}))
      }
      else if(this.option.min>0 && this.option.min===this.option.max){
        parts.push(this.$t('options.mandatory_chooses', {count:this.option.min}))
      }
      else if(this.option.min>0 && this.option.min!==this.option.max){
        parts.push(this.$t('options.mandatory_chooses_between', {min:this.option.min, max:this.option.max}))
      }
      if(this.option.quantitySelector){
        parts.push(this.$t('options.multiple_same_select'))
      }
      return parts.join(', ')
    }
  },
  created() {
    this.syncOptions();
    EventBus.on('menu:modifier:updated', (modifierId) => {
      this.syncOptions();
    })
  }
}
</script>
