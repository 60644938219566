<template>
  <ModalSliding background-color="bg-gray-100" close-id="client-page">
    <div class="flex flex-col h-full">
      <div class="px-4 pt-3">
        <div class="flex justify-between">
          <div class="flex items-center gap-4">
            <button class="flex items-center justify-center bg-white border px-4 py-1 gap-2 rounded-md hover:bg-gray-50" @click="close"><i class="text-lg material-icons">arrow_back</i>Retour</button>
            <span class="text-xl font-bold uppercase">Détails client</span>
          </div>
        </div>
      </div>

      <div class="px-6 py-4 flex-1 flex justify-center items-center flex-col gap-4" v-if="isPageLoading">
        <loader/>
        <small>Chargement du client...</small>
      </div>

      <div class="flex-1 overflow-y-auto w-full px-6 py-4" v-else>
        <div class="grid grid-cols-3 md:grid-cols-6 gap-3">
          <div class="col-span-3 bg-white border rounded-md py-5 px-6 flex justify-start items-center">
            <div>
              <img :src="client.avatar" class="h-20 w-20 rounded-full">
            </div>
            <div class="flex flex-col ml-5">
              <span class="text-2xl font-bold mb-1">{{client.name}}</span>
              <div>
                <span class="text-gray-500">{{client.phone}} · {{client.email}}</span>
              </div>
            </div>
          </div>
          <div class="bg-white border rounded-md flex flex-col md:justify-center items-center py-3 px-2 md:py-5 md:px-6 text-center">
            <span class="text-xl md:text-2xl lg:text-[2.5em] mb-2 font-bold text-primary">{{ timestampDiffForHumans(client.clientSince) }}</span>
            <span class="text-sm md:text-base font-semibold text-gray-700 leading-tight">Client depuis</span>
          </div>
          <div class="bg-white border rounded-md flex flex-col md:justify-center items-center py-3 px-2 md:py-5 md:px-6 text-center">
            <span class="text-xl md:text-2xl lg:text-[2.5em] mb-2 font-bold text-primary">{{ client.orderIds.length }}</span>
            <span class="text-sm md:text-base font-semibold text-gray-700 leading-tight">Commandes</span>
          </div>
          <div class="bg-white border rounded-md flex flex-col md:justify-center items-center py-3 px-2 md:py-5 md:px-6 text-center">
            <span class="text-xl md:text-2xl lg:text-[2.5em] mb-2 font-bold text-primary">{{ client.bookingIds.length }}</span>
            <span class="text-sm md:text-base font-semibold text-gray-700 leading-tight">Réservations</span>
          </div>
        </div>

        <div class="mt-10">
          <span class="text-2xl font-bold uppercase">{{$t('client.address', {count: addresses.length})}}</span>

          <div class="grid grid-cols-3 mt-4 gap-3" v-if="addresses.length>0">
            <div class="border bg-white px-5 py-4 rounded-md flex flex-col" v-for="address in addresses">
              <span class="font-bold flex-1">{{address.name}}</span>
              <span>{{address.address}}, {{address.zip}} {{address.city}}</span>
              <span v-if="address.comment" class="text-gray-400">"{{address.comment}}"</span>
            </div>
          </div>

        </div>
        <div class="mt-10">
          <span class="text-2xl font-bold uppercase">{{$t('client.order', {count: orders.length})}}</span>



          <div class="page-block relative mt-4" v-if="orders.length>0">
            <div class="absolute h-full w-full z-50 flex items-center justify-center" v-if="isPageLoading"><loader class="h-8 w-8"/></div>
            <table class="page-table" :class="{'opacity-40':isPageLoading}">
              <thead>
              <tr>
                <th>#Ref</th>
                <th>Date</th>
                <th>Client</th>
                <th>Montant</th>
                <th>Type</th>
                <th></th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in orders" :key="item.id" :class="{'bg-red-50/50':item.status === 'canceled'}">
                <td class="w-20 md:w-40">{{item.ref}}</td>
                <td>
                  <span v-if="item.status !== 'preorder'">{{formatIsoToHuman(item.takenAt)}}</span>
                  <span v-else>{{ $t('orders.preorder_for', {hour: formatIsoToHuman(item.preorderFor)})}}</span>
                </td>
                <td>{{item.client.name}}</td>
                <td>{{formatPrice(item.amount)}}</td>
                <td>
                  <span v-if="item.type==='delivery'">{{ item.courier ? $t('orders.deliver_by', {name: item.courier.name}) : $t('type.delivery') }}</span>
                  <span v-if="item.type==='pickup'">{{$t('type.pickup')}}</span>
                  <span v-if="item.type==='on_site_counter'">{{$t('type.on_site_counter')}}</span>
                </td>
                <td>
                  <div class="flex justify-end items-center h-full">
                    <order-status-badge v-if="item.status !== 'preorder'" :status="item.status"/>
                    <OrderPreorderBadge v-else :ring-at="item.preorderRingAt" :confirmed="item.preorderConfirmedAt!==null" :order="item"/>
                  </div>
                </td>
                <td class="w-10 text-right">
                  <button class="btn-sm xl:text-base text-white bg-primary rounded-md px-5 py-2 font-bold" @click="openOrder(item.id)">{{$t('cta.details')}}</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>




        </div>
        <div class="mt-10">
          <span class="text-2xl font-bold uppercase">{{$t('client.booking', {count: bookings.length})}}</span>

          <div class="page-block mt-4" v-if="bookings.length > 0">
            <table class="w-full page-table">
              <tr v-for="booking in bookings" class="border-b last:border-none" :key="booking.id">
                <td class=" w-[9rem]">
                  <span>{{ booking.ref }}</span>
                </td>
                <td class="lg:w-[18%] capitalize">
                  <span>{{formatDate(booking.booking_for, 'ccc DD HH:mm')}}</span>
                </td>
                <td class="lg:w-[18%] text-center">
                  <span>{{booking.user.name}}</span>
                </td>
                <td>
                  <span class="flex items-center"><i class="material-icons text-2xl mr-2">group</i>{{ $t('booking.person_count', {persons: booking.persons}) }}</span>
                </td>
                <td>
                  <span>{{booking.zone.name}}</span>
                </td>
                <td>
                  <div class="flex justify-end items-center h-full">
                    <BookingValidateButton :bookingId="booking.id" v-if="booking.status==='taken'"></BookingValidateButton>
                    <BookingStatus :status="booking.status" v-else></BookingStatus>
                  </div>
                </td>
                <td class="w-10 text-right">
                  <button class="btn-sm text-white bg-primary rounded-md px-5 py-2 font-bold" @click="openBookingDetails(booking)">Détails</button>
                </td>
              </tr>
            </table>
          </div>



        </div>
      </div>



    </div>
  </ModalSliding>
</template>

<script>
import {mapGetters} from "vuex";
import ModalSliding from "@/components/Ui/Modals/ModalSliding.vue";
import {getClient} from "@/services/ClientService";
import {formatDate, formatPrice, timestampDiffForHumans} from "../../services/FormatService";
import {errorToast} from "@/services/SystemService";
import {useModal, useVfm} from "vue-final-modal";
import {formatIsoToHuman} from "../../services/TimeService";
import OrderStatusBadge from "@/components/Order/OrderStatusBadge.vue";
import OrderPreorderBadge from "@/components/Order/OrderPreorderBadge.vue";
import Order from "@/views/Orders/Order.vue";
import BookingValidateButton from "@/components/Booking/BookingValidateButton.vue";
import BookingStatus from "@/components/Booking/BookingStatus.vue";
import BookingDetails from "@/components/Booking/Modal/BookingDetails.vue";

export default {
  components: {
    OrderStatusBadge,
    ModalSliding,
    OrderPreorderBadge,
    BookingValidateButton,
    BookingStatus
  },
  setup(){
    const bookingDetailModal = useModal({component: BookingDetails});
    const orderModal = useModal({component: Order});
    return {orderModal, bookingDetailModal}
  },
  props: {
    clientId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
  },
  data(){
    return {
      isPageLoading: true,
      client: null,
      addresses: [],
      orders: [],
      bookings: [],
    }
  },
  methods: {
    formatDate,
    formatPrice,
    formatIsoToHuman,
    timestampDiffForHumans,
    close(){
      useVfm().close('client-page')
    },
    async openOrder(id){
      await this.orderModal.patchOptions({attrs: {orderId: id}});
      await this.orderModal.open();
    },
    openBookingDetails(booking){
      this.bookingDetailModal.patchOptions({attrs: {booking}});
      this.bookingDetailModal.open();
    }
  },
  async mounted() {
    const {client, addresses, orders, bookings} = await getClient(this.currentMerchant.id, this.clientId)
        .catch(() => {
          errorToast('Une erreur est survenue lors du chargement du client...');
          this.close();
        })
    this.client = client;
    this.addresses = addresses;
    this.orders = orders.items;
    this.bookings = bookings.items;
    this.isPageLoading = false;
  }
}
</script>
