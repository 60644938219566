<template>
  <table class="w-full">
    <tr v-for="(item,i) in items">
      <td class="border-t px-4">
        <div class="flex items-center py-2">
          <div class="flex items-center justify-center font-bold rounded-md text-lg min-h-[2.5rem] w-10 pt-1" :class="{
            'bg-[#EDBD13] text-white': i===0,
            'bg-[#9E9E9E] text-white': i===1,
            'bg-[#ED6E13] text-white': i===2,
            'bg-primary text-white': i>2,
          }">{{ i+1 }}</div>
          <span class="ml-4 font-bold flex-1 line-clamp-1 hover:line-clamp-6 leading-tight">{{ item.name }}</span>
        </div>
      </td>
      <td class="border-t border-l w-3/5">
        <div class="pl-6 pr-20">
          <div class="flex items-center w-full h-2">
            <div :style="'width: '+item.percentage+'%;'" class="bg-primary h-2 rounded-md"></div>
            <div class="flex items-center justify-start w-0 whitespace-nowrap ml-2 text-sm">{{item.value}}</div>
          </div>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>
