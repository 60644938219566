<template>
  <div class="flex justify-center"
       style="background-image: url(/images/empty.svg); background-size: 300px; background-position-y: -35px">
    <div class="flex flex-col justify-center items-center py-10 md:w-1/3 text-center">
      <span class="text-3xl font-bold mb-3">{{title}}</span>
      <span class="text-lg text-gray-600">{{description}}</span>
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Pas de données"
    },
    description: {
      type: String,
      default: "Aucune donnée à afficher pour le moment"
    }
  }
}
</script>