<template>
  <div class="w-full border-t w-full py-2">
    <div class="h-16 flex justify-between items-center px-6">
      <div v-if="type==='mandatory'">
        <b class="text-gray-800">Option(s) obligatoire(s)</b>
        <span class="ml-2 text-gray-600 text-sm">(ex: composition, format...)</span>
      </div>
      <div v-if="type==='optional'">
        <b class="text-gray-800">Option(s) facultative(s)</b>
        <span class="ml-2 text-gray-600 text-sm">(ex: suppléments)</span>
      </div>
      <div class="flex items-center gap-5" v-if="!currentMerchant.features.menuMinorEditionOnly">
        <button class="font-bold text-primary underline text-sm disabled:opacity-50" @click="assignOption()">Utiliser une option existante</button>
        <button class="btn bg-primary text-white flex items-center rounded px-3" @click="addOption"><i class="material-icons text-lg mr-1">add</i>Ajouter</button>
      </div>
    </div>
    <div class="px-6 mt-2">

      <draggable v-model="optionList" item-key="uid">
        <template #item="{element}">
          <OptionBlockValue :product-id="productId" :option="element" :type="type"/>
        </template>
      </draggable>

    </div>
  </div>
</template>

<script>

import OptionBlockValue from "@/components/Menu/Editor/OptionBlockValue.vue";
import {useModal} from "vue-final-modal";
import OptionEditor from "@/components/Menu/Editor/OptionEditor.vue";
import draggable from "vuedraggable";
import AssignExistingOptionToItem from "@/components/Menu/Modals/AssignExistingOptionToItem.vue";
import {mapGetters} from "vuex";

export default {
  props: {
    type: {
      type: String,
      default: "mandatory"
    },
    options: {
      type: Array,
      default: () => []
    },
    productId: {
      type: Number,
      default: null
    }
  },
  data(){
    return {
      optionList: []
    }
  },
  setup(){
    const optionEditorModal = useModal({component: OptionEditor});
    const assignExistingOptionToItem = useModal({component: AssignExistingOptionToItem});
    return {optionEditorModal,assignExistingOptionToItem}
  },
  methods: {
    addOption(){
      this.optionEditorModal.patchOptions({attrs: {type: this.type, productId: this.productId}});
      this.optionEditorModal.open();
    },
    assignOption(){
      this.assignExistingOptionToItem.patchOptions({attrs: {type: this.type, productId: this.productId, ignoreOptions: this.optionList.map(o => o.id)}});
      this.assignExistingOptionToItem.open();
    }
  },
  watch: {
    optionList(val, old){
      if(old.length > 0 && old.map(e=>e.uid).join('') !== val.map(e=>e.uid).join('')){
        console.log('Option list trigger')
        this.$emit('updateOptions', val);
      }
    },
    options(val, old){
      this.optionList = this.options;
    }
  },
  mounted() {
      this.optionList = this.options;
  },
  components: {OptionBlockValue, draggable},
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    })
  }
}
</script>
