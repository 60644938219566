import {useModal} from "vue-final-modal";
import UnlockFeature from "@/components/Subscription/UnlockFeature.vue";

export async function unlockFeature(){
    return;
    return await useModal({
        component: UnlockFeature,
        attrs: {}
    }).open();
}
