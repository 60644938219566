<template>
  <modal title="Ajouter une catégorie" size="xs" close-id="category-selector-modal">
    <div class="px-2 py-2">

      <div class="bg-primary/5 px-5 py-2 rounded-md mb-6">
        <span class="text-gray-700 text-sm"> Ajouter jusqu'à {{currentMerchant.quota.maxMerchantCategories}} catégories pour décrire au mieux votre activité et être plus visible sur l'application</span>
      </div>


      <select class="form-input" v-model="selected" :class="{'form-input-error':errors.some(e => e.field === 'selector')}">
        <option :value="null">-- choisir --</option>
        <option :value="category.id" v-for="category in merchantCategories">{{category.name}}</option>
      </select>
      <small v-if="errors.some(e => e.field === 'selector')" class="text-red-500 text-sm font-medium leading-tight block mt-1">{{errors.find(e => e.field === 'selector').message}}</small>

      <div class="flex gap-4 justify-between flex-row-reverse">
        <button class="btn bg-primary text-white mt-6" @click="validate">Ajouter</button>
        <button class="btn bg-transparent border border-red-500 text-red-500 mt-6 hover:ring-0" v-if="defaultValue" @click="deleteCategory">Supprimer</button>
      </div>

    </div>
  </modal>
</template>

<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import EventBus from "@/EventBus";
import {useModal, useVfm} from "vue-final-modal";
export default {
  components: {Modal},
  props: {
    index: {
      type: Number,
      required: true
    },
    defaultValue: {
      type: Number,
      required: false
    }
  },
  data(){
    return {
      selected: null,
      errors: []
    }
  },
  methods: {
    deleteCategory(){
      EventBus.emit('category-selector-modal:validate', {index: this.index, value: null});
      useVfm().close('category-selector-modal');
    },
    validate(){
      this.errors = [];
      if(!this.selected) this.errors.push({field: 'selector', message: 'Veuillez sélectionner une catégorie'});
      if(this.errors.length>0) return;

      EventBus.emit('category-selector-modal:validate', {index: this.index, value: this.selected});
      useVfm().close('category-selector-modal');
    }
  },
  computed: {
    ...mapGetters({
      'merchantCategories': 'system/merchantCategories',
      'systemQuota': "system/quota",
      'currentMerchant': "currentMerchant"
    })
  },
  mounted() {
    this.selected = this.defaultValue;
  }
}
</script>
