<template>
  <page-block title="Récupération" class="h-full">

    <div class="flex flex-col pt-2">

      <div class="py-4">
        <div class="flex justify-between">
          <span class="font-bold">Livraison</span>
          <span>{{formatPercentage(repartition.delivery)}}</span>
        </div>
        <div class="w-full h-2 bg-gray-200 rounded-xl mt-2"><div class="bg-primary h-2 rounded-xl" :style="'width: '+repartition.delivery+'%;'"></div></div>
      </div>

      <div class="py-4">
        <div class="flex justify-between">
          <span class="font-bold">Click&Collect</span>
          <span>{{formatPercentage(repartition.pickup)}}</span>
        </div>
        <div class="w-full h-2 bg-gray-200 rounded-xl mt-2"><div class="bg-gray-500 h-2 rounded-xl" :style="'width: '+repartition.pickup+'%;'"></div></div>
      </div>


      <div class="py-4">
        <div class="flex justify-between">
          <span class="font-bold">Sur place</span>
          <span>{{formatPercentage(repartition.on_site_counter)}}</span>
        </div>
        <div class="w-full h-2 bg-gray-200 rounded-xl mt-2"><div class="bg-pink-300 h-2 rounded-xl" :style="'width: '+repartition.on_site_counter+'%;'"></div></div>
      </div>

    </div>

  </page-block>
</template>
<script>
import PageBlock from "@/components/Ui/Elements/PageBlock.vue";
import {formatPercentage} from "../../services/FormatService";
export default {
  methods: {formatPercentage},
  components: {PageBlock},
  props: {
    repartition: {
      type: Object,
      required: true
    }
  }
}
</script>
