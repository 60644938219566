<template>
  <div class="grid grid-cols-3 sm:grid-cols-5 gap-4">
    <div class="border-2 border-gray-500 rounded-full h-12 px-4 flex items-center justify-center cursor-pointer" @click="openCategorySelectorModal(i, item.id)" v-for="(item,i) in selected" :key="item.id">
      <span class="truncate font-bold leading-tight text-gray-500 text-sm">{{item.name}}</span>
    </div>
    <div class="border border-dashed border-gray-300 rounded-full h-12 px-4 flex items-center justify-center cursor-pointer" @click="openCategorySelectorModal(i+(selected.length-1))" v-for="i in currentMerchant.quota.maxMerchantCategories-selected.length">
      <i class="material-icons text-gray-400">add</i>
    </div>
    <div class="border border-gray-300 bg-gray-200 rounded-full h-12 px-3 flex items-center justify-star" v-for="i in systemQuota.maxMerchantCategories-currentMerchant.quota.maxMerchantCategories">
      <div class="h-7 w-7 bg-primary rounded-full flex items-center justify-center"><i class="material-icons text-base text-white">lock</i></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {useModal} from "vue-final-modal";
import CategorySelectorModal from "@/components/Merchant/Modal/CategorySelectorModal.vue";
import EventBus from "@/EventBus";

export default {
  setup(){
    const categorySelectorModal = useModal({component: CategorySelectorModal})
    return {categorySelectorModal}
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      selected: []
    }
  },
  computed: {
    ...mapGetters({
      'merchantCategories': 'system/merchantCategories',
      'systemQuota': "system/quota",
      'currentMerchant': "currentMerchant"
    })
  },
  methods: {
    openCategorySelectorModal(index, defaultValue = null){
      this.categorySelectorModal.patchOptions({attrs: {defaultValue, index}});
      this.categorySelectorModal.open();
    }
  },
  async mounted() {
    if(!this.merchantCategories) await this.$store.dispatch('system/getMerchantCategories');
    if(this.modelValue.length){
      this.modelValue.forEach((c) => {
        if(this.selected.length >= this.currentMerchant.quota.maxMerchantCategories) return;
        this.selected.push(this.merchantCategories.find((mc) => mc.id === c))
      })
    }

    EventBus.on('category-selector-modal:validate', ({index, value}) => {
      if(value){
        this.selected.splice(parseInt(index), 1, this.merchantCategories.find((mc) => mc.id === value));
      }
      else{
        this.selected.splice(parseInt(index), 1);
      }
      this.$emit('update:modelValue', this.selected.map((c) => c.id));
    })
  }
}
</script>
