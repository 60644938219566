<template>
    <page-block :title="title">
        <div class="h-[280px]" v-if="!isLoading">
            <canvas :id="chartId"></canvas>
        </div>
        <div class="h-[280px] flex items-end gap-1 md:gap-2 xl:gap-3 pb-2" v-else>
            <div class="animate-pulse bg-slate-200/80 flex-1 rounded"  :style="'height: '+randomIntFromInterval(60,90)+'%; animation-delay:'+(i*80)+'ms'" v-for="i in 30"></div>
        </div>
    </page-block>
</template>

<script>
import {Chart, registerables} from "chart.js";
import {randomIntFromInterval} from "@/services/SystemService";
Chart.register(...registerables);

export default {
    props: {
        isLoading: {
            default: false,
            type: Boolean
        },
        title: {
            type: String,
            required: false
        },
        type: {
            type: String,
            default: 'bar'
        },
        labels: {
            type: Array,
            default: () => [0,0]
        },
        values: {
            type: Array,
            default: () => [0,0]
        }
    },
    data(){
        return {
            chartId: null
        }
    },
    methods: {
        randomIntFromInterval,
        loadChart(){

            const ctx = document.getElementById(this.chartId).getContext('2d');
            const data = {
                labels: this.labels,
                datasets: [{
                    label: 'Données',
                    borderWidth:0,
                    data: this.values, // remplacez ces valeurs par vos propres données
                    backgroundColor: '#13548F',
                }]
            };
            new Chart(ctx, {
                type: this.type,
                data: data,
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: false
                        }
                    }
                },
            });
        }
    },
    mounted() {
        if(this.isLoading) return;
        this.chartId = Math.random().toString(36).substring(7);
        this.$nextTick(() => {
            this.loadChart();
        })
    }
}
</script>
