<template>
    <button class="relative" :class="{'flex items-center justify-center py-0 pr-0 px-0': !noButton}">

        <template v-if="customButton">
            <div @click="toggle">
                <slot name="custom-button"></slot>
            </div>
        </template>
        <template v-else>
            <div  @click.stop="sendClickEvent" v-if="!noButton">
                <slot name="buttonContent">
                    <div class="h-full w-full pl-6 pr-4 flex items-center">
                        <slot></slot>
                    </div>
                </slot>
            </div>
            <div class="h-full flex items-center px-1" :class="[ !noBorder ? 'border-l border-white/30': '', 'menu_ui_'+menuId]" @click="toggle">
                <i class="material-icons" :class="['menu_ui_'+menuId, iconClass]">{{ icon }}</i>
            </div>
        </template>



        <transition
                enter-active-class="transition duration-75 ease-out"
                enter-from-class="transform scale-95 -translate-y-5 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 -translate-y-5 opacity-0">
            <div class="text-left w-fit font-medium overflow-hidden absolute bg-white dark:bg-gray-800 drop-shadow-lg shadow-xl rounded-md top-full mt-2 right-0 text-gray-900 leading-auto z-20" v-if="open" ref="menu">
                <slot name="menu"></slot>
            </div>
        </transition>

    </button>
</template>


<script>
import {onClickOutside} from "@vueuse/core";
import {ref} from "vue";

export default {
    props: {
        href: {
        },
        iconClass: {
            default: '',
            type: String
        },
        icon: {
            default: 'arrow_drop_down'
        },
        noBorder: {
            default: false,
            type: Boolean
        },
        noButton: {
            default: false,
            type: Boolean
        },
        customButton: {
            default: false,
            type: Boolean
        }
    },
    setup(){
        const menu = ref();
        return {menu}
    },
    data(){
        return {
            open: false,
            menuId: null
        }
    },
    methods: {
        sendClickEvent(){
            if(typeof this.href === 'string'){
                window.open(this.href, '_blank');
            }
            else{
                this.$router.push(this.href);
            }
        },
        toggle(){
            this.menuId = Math.random().toString(36).substr(2, 9);
            if(this.open){
                this.open = false;
            }
            else{
                this.open = true;
                this.$nextTick(() => {
                    onClickOutside(this.menu, (e) => {
                        if(this.open){
                            if(!e.target.classList.contains('menu_ui_'+this.menuId)){
                                this.open = false;
                            }
                        }
                    });
                })
            }
        },
    },
    mounted() {

    }
}
</script>
