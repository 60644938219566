import {
    getMerchants,
    getMerchantSidebarAlerts, getOnboardingLink,
    updateMerchantSettings
} from "@/services/MerchantService";
import EventBus from "@/EventBus";

export default {
    state: {
        merchants: [],
        sidebarAlerts: null,
        currentMerchantId: null
    },
    mutations: {
        SET_MERCHANTS(state, merchants) {
            state.merchants = merchants;
        },
        SET_CURRENT_MERCHANT(state, id){
            state.currentMerchantId = id;
        },
        SET_MERCHANT_SIDEBAR_ALERTS(state, alerts){
            state.sidebarAlerts = alerts;
        },
        ADD_SIDEBAR_UNAVAILABLE_PRODUCT(state, count){
            state.sidebarAlerts.unavailableProducts += count;
        },
        SET_MERCHANT_SCHEDULE(state, schedule){
            const merchantIndex = state.merchants.findIndex(merchant => merchant.id === state.currentMerchantId)
            state.merchants[merchantIndex].schedule = schedule;
        },
        SET_MERCHANT_ONBOARDING_VALUE(state, {type, value}){
            const merchantIndex = state.merchants.findIndex(merchant => merchant.id === state.currentMerchantId)
            state.merchants[merchantIndex].onboarding[type] = value;
        },
        REMOVE_MERCHANT_INTEGRATION(state, id){
            const merchantIndex = state.merchants.findIndex(merchant => merchant.id === state.currentMerchantId)
            state.merchants[merchantIndex].integrations = state.merchants[merchantIndex].integrations.filter(integration => integration.id !== id);
        }
    },
    getters: {
        merchants: state => state.merchants,
        sidebarAlerts: state => state.sidebarAlerts,
        currentMerchant: state => state.currentMerchantId ? state.merchants.find(merchant => merchant.id === state.currentMerchantId) : null
    },
    actions: {
        changeCurrentMerchantAction({commit, dispatch}, {merchantId}){
            commit('SET_CURRENT_MERCHANT', merchantId);
            commit('accounting/RESET_ACCOUNTING_MONTHS', null, {root: true});
            dispatch('getMerchantSidebarAlertsAction', {merchantId: merchantId});
            localStorage.setItem('currentMerchant', merchantId);
            EventBus.emit('system:refresh-view');
        },
        async getMerchantsAction({ commit, state, dispatch }) {
            await dispatch('system/getSystemInfos', null, {root: true});
            await dispatch('account/getAccount', null, {root: true});
            const merchants = await getMerchants();
            commit('SET_MERCHANTS', merchants);
            if(localStorage.getItem('currentMerchant') && merchants.some(merchant => merchant.id === parseInt(localStorage.getItem('currentMerchant'),10))) {
                commit('SET_CURRENT_MERCHANT', parseInt(localStorage.getItem('currentMerchant'),10));
            }
            if(!state.currentMerchantId){
                commit('SET_CURRENT_MERCHANT', merchants[0].id);
            }
            dispatch('getMerchantSidebarAlertsAction', {merchantId: state.currentMerchantId});
        },
        async getMerchantSidebarAlertsAction({commit}, {merchantId}){
            const alerts = await getMerchantSidebarAlerts(merchantId);
            commit('SET_MERCHANT_SIDEBAR_ALERTS', alerts);
        },
        async updateMerchantAction({commit, state, dispatch}, {merchantId, data}){
            await updateMerchantSettings(merchantId, data);
            await dispatch('getMerchantsAction');
        },
        async getMerchantScheduleAction({commit}, {merchantId}){
           // const schedule = await updateTimetable(merchantId);

        },
        async updateMerchantScheduleAction({commit}, {merchantId, data}){

        },
        async getOnboardingLink({commit}, {merchantId, type}){
            if(type === 'starting_advise_link'){
                commit('SET_MERCHANT_ONBOARDING_VALUE', {type: 'hasOpenStartAdvice', value: true});
            }
            if(type === 'good_practice_link'){
                commit('SET_MERCHANT_ONBOARDING_VALUE', {type: 'hasOpenGoodPractices', value: true});
            }
            window.open(await getOnboardingLink(merchantId, type), '_blank');
        }
    }
}
