<template>
  <VueFinalModal
      class="flex justify-start items-center"
      contentTransition="vfm-slide-left"
      :modal-id="modalId"
      swipe-to-close="left"
      :content-class="['h-[100vh] w-[250px] relative']">
    <SideNav/>
  </VueFinalModal>
</template>

<script setup lang="ts">
import {useVfm, VueFinalModal} from 'vue-final-modal'
import {ref} from "vue";
const vfm = useVfm()
import SideNav from "@/components/Layouts/SideNav.vue";
const modalId = ref('modal-'+Math.random().toString(36).substring(7))
function close() {
  vfm.close(modalId.value);
}
</script>
