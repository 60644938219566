import axios from "@/axios-instance";
import stores from "@/stores";
import {getCurrentAccount} from "@/services/AccountService";

export async function checkAccountCreation(tallyId){
    return new Promise(((resolve, reject) => {
        axios.post('/v1/merchants/onboarding/check', {tallyId})
            .then((response) => {
                // Login if possible
                stores.dispatch('loginTokenAction', {token:response.data.hash})
                    .then(async () => {
                        location.href = '/onboarding';
                    })
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
