<template>
  <div class="border bg-white rounded-md px-6 py-4 flex flex-col" :class="{'ring-2 ring-[#559F1B] border-[#559F1B]': rushour}">
    <div class="flex items-center justify-start">
      <img src="/images/integrations/rushour.png" class="rounded h-12">
      <div class="flex gap-2 items-center">
        <span class="text-xl font-bold ml-4">RusHour</span>
        <span class="text-white bg-[#559F1B] pl-2 pr-3 h-6 rounded-full font-bold text-sm flex items-center ml-2" v-if="rushour"><i class="material-icons text-base mr-1">check</i>Activé</span>
      </div>
    </div>
    <div class="pt-4 flex-1">
      <span class="block leading-7">RusHour permet aux restaurants et dark kitchens de centraliser toutes leurs commandes en livraison et à emporter sur une seule tablette et une seule imprimante. Contactez RusHour pour lancer l'intégration.</span>
    </div>
    <div class="mt-8 flex items-center flex-wrap gap-4">
      <a href="https://www.rushour.io/" target="_blank" class="flex hover:ring-0 items-center btn bg-gray-100 text-gray-600">Site de RusHour</a>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
    rushour(){
      if(!this.currentMerchant.integrations.some(e => e.type === 'rushour')) return false;
      return this.currentMerchant.integrations.find(e => e.type === 'rushour');
    }
  },
}
</script>
