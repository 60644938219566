<template>
  <Modal size="xs" v-slot="{close}" title="Modifier les taux de TVA">
    <div class="pt-2 pb-3 grid grid-cols-[auto,150px] gap-3">



      <div class="flex items-center">
        <span class="">TVA sur place</span>
      </div>
      <div>
        <select class="form-input" v-model="vat.onsite">
          <option :value="null">--Choisir--</option>
          <option value="0">Pas applicable</option>
          <option value="550">5.5%</option>
          <option value="1000">10%</option>
          <option value="2000">20%</option>
        </select>
      </div>

      <div class="flex items-center">
        <span class="">TVA à emporter</span>
      </div>
      <div>
        <select class="form-input" v-model="vat.pickup">
          <option :value="null">--Choisir--</option>
          <option value="0">Pas applicable</option>
          <option value="550">5.5%</option>
          <option value="1000">10%</option>
          <option value="2000">20%</option>
        </select>
      </div>

      <div class="flex items-center">
        <span class="">TVA en livraison</span>
      </div>
      <div>
        <select class="form-input" v-model="vat.delivery">
          <option :value="null">--Choisir--</option>
          <option value="0">Pas applicable</option>
          <option value="550">5.5%</option>
          <option value="1000">10%</option>
          <option value="2000">20%</option>
        </select>
      </div>

      <div class="flex items-center mt-4">
        <a href="https://www.economie.gouv.fr/cedef/tva-reduite-restauration" target="_blank" class="text-primary underline">Plus d'information sur la TVA</a>
      </div>
      <div class="flex items-center justify-end mt-4">
        <button class="btn bg-primary text-white" @click="save">{{$t('cta.save')}}</button>
      </div>

    </div>
  </Modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";

export default {
    components: {Modal},
    setup(){
        const vfm = useVfm();
        return {vfm}
    },
    computed: {
        ...mapGetters({
            menu: 'menu/menu',
            currentMerchant: 'currentMerchant',
            selectedMenuItems: 'menu/selectedMenuItems',
        })
    },
    data(){
      return {
        vat: {
          onsite: null,
          pickup: null,
          delivery: null
        }
      }
    },
    methods: {
      async save(){
        const update = this.menu.items
            .filter(i => this.selectedMenuItems.includes(i.id))
            .map((item) => {
              const data = {id: item.id};
              data[`vat_onsite`] = this.vat.onsite;
              data[`vat_pickup`] = this.vat.pickup;
              data[`vat_delivery`] = this.vat.delivery;
              return data;
            });
        await this.$store.dispatch('menu/updateMenuItemBatchAction', {merchantId: this.currentMerchant.id, update});
        this.vfm.closeAll();
      }
    }
}
</script>
