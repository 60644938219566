<template>
  <page-block no-title-padding title="Click&Collect">
    <template v-slot:no-padding>
      <div class="px-4 mb-4">
        <order-status-history :order="order" v-if="order.statusSteps"/>
      </div>
    </template>
  </page-block>
</template>

<script>
import OrderStatusHistory from '@/components/Order/OrderStatusHistory.vue'
export default {
  components: {OrderStatusHistory},
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
