<template>
  <vue-final-modal
      v-bind="$attrs"
      :name="name"
      :modal-id="closeId ?? modalId"
      class="flex justify-center items-center sm:p-2"
      :content-class="['relative max-h-full sm:rounded-lg bg-white md:h-auto md:mx-4  flex flex-col',
      modalSize ?? 'w-full h-full',
      contentClass
      ]">

      <slot name="nav">
          <div class="flex justify-between items-center w-full pl-5 sm:pl-6 py-2 mt-1 pr-3 border-b" v-if="title">
              <span class="font-bold text-lg sm:text-xl leading-none">
                  <slot name="title">{{title}}</slot>
              </span>
              <button class="h-10 w-10 flex justify-center items-center" @click="close">
                  <i class="material-icons text-4xl">close</i>
              </button>
          </div>
      </slot>
      <slot name="content">
          <div class=" flex-1 scroll-large" :class="[noPadding ? '' : 'px-6 py-4', noScroll? '': 'overflow-y-auto']">
              <slot :close="close"></slot>
          </div>
      </slot>

  </vue-final-modal>
</template>


<script>
import {useVfm, VueFinalModal} from 'vue-final-modal'

export default {
    setup(){
        const modalId = 'modal-'+Math.random().toString(36).substring(7);
        const vfm = useVfm()
        return {modalId, vfm}
    },
  props: {
    title: {
      default: null
    },
    size: {
      default: 'medium'
    },
    name: {
      required: false
    },
	  closeId: {
		  type: String,
		  default: null
	  },
    contentClass: {
      default: ''
    },
      noPadding: {
          default: false
      },
    noScroll: {
      default: false
    }
  },
    methods: {
        close() {
            this.vfm.close(this.closeId ?? this.modalId);
        }
    },
  components: {VueFinalModal},
  computed:{
    modalSize(){
      if(this.size === 'fullscreen') {
        return 'w-full h-full';
      }
      if(this.size === 'xlarge') {
        return 'w-full h-full md:w-[950px]';
      }
      if(this.size === 'large') {
        return 'w-full h-full md:w-[800px]';
      }
      if(this.size === 'medium') {
        return 'w-full h-full md:w-[600px]';
      }
      if(this.size === 'small') {
        return 'w-full h-full md:w-[500px]';
      }
      if(this.size === 'xs') {
        return 'w-full h-full md:w-[450px]';
      }
      if(this.size === '2xs') {
        return 'w-[350px]';
      }
      return null;
    }
  }
}
</script>
