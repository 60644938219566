<template>
  <tr v-if="product" :class="{
        'bg-gray-50':product.status==='archived'&&!isRowFocused,
        'bg-gray-100':product.status==='archived',
        'bg-red-50/50':product.status==='unavailable'
    }">
    <td class="w-10">
      <div class="absolute w-full h-full top-0 left-0 flex justify-center items-center">
        <input type="checkbox" class="form-checkbox m-0 w-5 h-5" @change="changeCheck" :checked="selectedMenuItems.some(i => i === product.id)">
      </div>
    </td>
    <td class="w-12 border-b">
      <label :for="'image:'+product.id" class="aspect-square bg-center bg-cover absolute w-full h-full top-0 left-0" v-if="product.image" :style="'background-image: url('+product.image.small+');'"></label>

      <label :for="'image:'+product.id" class="aspect-square  flex items-center justify-center absolute w-full h-full top-0 left-0 text-gray-400 hover:text-gray-500 " v-else>
        <i class="material-icons">add_a_photo</i>
      </label>

      <input type="file" :id="'image:'+product.id" class="hidden" accept="image/*" @input="uploadImage">
    </td>
    <td>
      <input type="text" :disabled="currentMerchant.features.menuMinorEditionOnly" v-model="product.name" @keydown.ctrl="keyDown" @blur="blur" @focus="focus" :id="product.id+'_1'" @change="updateProduct()" class="px-3 text-sm font-medium">
    </td>
    <td>
      <textarea cols="1" rows="1" class="text-xs px-2 py-1" @keydown.ctrl="keyDown" :id="product.id+'_2'" @change="updateProduct()" @focus="textareaFocus($event); focus($event)" @blur="textareaBlur($event); blur($event)" v-model="product.description"></textarea>
    </td>
    <td class="w-[21rem]">
      <div class="flex items-center">
        <div class="text-sm h-12 w-28 py-1 relative">
          <input :disabled="currentMerchant.features.menuMinorEditionOnly" v-model="product.price.onsite" min="0" @keydown.ctrl="keyDown" @blur="blur" @focus="focus" :id="product.id+'_3'" @change="updateProduct()" type="number" class="text-center" step="0.01">
        </div>
        <div class="text-sm h-12 w-28 border-l py-1 relative">
          <input v-model="product.price.pickup" min="0" @keydown.ctrl="keyDown" @blur="blur" @focus="focus" :id="product.id+'_4'" @change="updateProduct()" type="number" class="text-center" step="0.01">
        </div>
        <div class="text-sm h-12 w-28 border-l py-1 relative">
          <input v-model="product.price.delivery" min="0" @keydown.ctrl="keyDown" @blur="blur" @focus="focus" :id="product.id+'_5'" @change="updateProduct()" type="number" class="text-center" step="0.01">
        </div>
      </div>
    </td>
    <td>
      <div class="absolute w-full h-full top-0 left-0 flex justify-center items-center cursor-pointer" @click="product.highlighted=!product.highlighted; updateProduct()">
        <i class="material-icons text-yellow-400" v-if="product.highlighted" >star</i>
        <i class="material-icons text-gray-600" v-else>star_border</i>
      </div>
    </td>
    <td class="w-[10rem]">
      <select class="absolute w-full h-full top-0 left-0 text-center outline-none bg-transparent text-sm" :id="product.id+'_6'" @keydown.ctrl="keyDown" @blur="blur" @focus="focus" v-model="product.status" @change="updateProduct()">
        <option value="available">✅ Disponible</option>
        <option value="unavailable">❌ Indisponible</option>
        <option value="archived">👁 Archivé</option>
      </select>
    </td>
    <td>
      <div class="absolute w-full h-full top-0 left-0 flex items-center justify-center gap-2">
        <span class="text-sm text-gray-700" v-if="product.children.length">{{ product.children.length }} options</span>
        <span class="text-sm text-gray-700" v-else>Pas d'option</span>
        <i class="material-icons text-base" :class="{'text-primary cursor-pointer':product.children.length, 'text-gray-300':!product.children.length}" :id="`tippy-${product.id}`" @mouseover="showTooltip(product)">pending</i>
      </div>
    </td>
    <td class="border-none">
      <button class="absolute w-full h-full top-0 left-0 flex items-center justify-center bg-primary" @click="openItem()">
        <i class="material-icons text-white">arrow_forward</i>
      </button>
    </td>
  </tr>
</template>

<script>
import {mapGetters} from "vuex";
import EventBus from "@/EventBus";
import MenuItemEditor from "@/views/Menu/MenuItemEditor.vue";
import {useModal} from "vue-final-modal";
import {mediaUploadImage} from "@/services/MediaService";
import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/translucent.css'
import {siteTour} from "@/services/TourService";

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      product: null,
      checked: false,
      isRowFocused: false,
      currentFocusedField: null,
      imageData: null,
      tippyInstances: [],
    }
  },
  setup(){
    const productModal = useModal({component: MenuItemEditor});
    return {productModal};
  },
  methods: {
    async openItem(){
      const category = this.menu.categories.find(c => c.itemIds.includes(this.data.uid));
      await this.productModal.patchOptions({attrs: {category: category.id, item: this.data}});
      await this.productModal.open();
      siteTour('product_details');
    },
    async uploadImage(e){
      if(e.target.files.length === 0) return;

      this.isImageLoading = true;
      await mediaUploadImage(e, this.currentMerchant.id, 'merchant-product-image', ['1000x1000', '800x800', '250x250'])
          .then((result) => {
            this.imageUrl = result.find(r => r.size === '800x800').url;
            this.product.image = {
              base: result.find(r => r.size === 'base').url,
              large: result.find(r => r.size === '800x800').url,
              small: result.find(r => r.size === '250x250').url
            };
            this.imageData = result;
            this.updateProduct();
          })
          .catch((e) => {

          })
      this.isImageLoading = false;
    },

    async keyDown(e){
      e.preventDefault();
      if(e.key === 'Control') return;
      const cursor = await this.$store.dispatch('menu/moveTableCursor', {key: e.key});
      const $el = document.getElementById(cursor);
      $el.focus();
      if($el.type === 'number'){
        $el.select();
      }
    },
    focus(e){
      this.$store.dispatch('menu/setCurrentTableCursor', {cursor: e.target.id});
    },
    blur(e){

    },
    changeCheck(){
      if(this.selectedMenuItems.some(i => i === this.product.id)){
        this.$store.commit('menu/REMOVE_SELECTED_MENU_ITEM', this.product.id);
        EventBus.emit('menu:category-uncheck', this.menu.categories.find(c => c.itemIds.includes(this.product.uid)));
      }
      else{
        this.$store.commit('menu/ADD_SELECTED_MENU_ITEM', [this.product.id]);
        const category = this.menu.categories.find(c => c.itemIds.includes(this.product.uid));

        const productIds = category.itemIds
            .map(id => this.menu.items.find(item => item.uid === id))
            .filter(p => (this.archivedItemsVisibility ? true : p.archived === false))
            .map(p => p.id);

        const checkedProductIds = this.selectedMenuItems.filter(p => productIds.includes(p));
        if(checkedProductIds.length === productIds.length){
          EventBus.emit('menu:category-check', category);
        }
      }
    },
    textareaFocus(ev){
      ev.target.style.width = '100%';ev.target.style.height = '250%';ev.target.style.top = '4px';
      this.isRowFocused=true;
    },
    textareaBlur(ev){
      ev.target.style.top = '0';ev.target.style.width = '100%';ev.target.style.height = '100%';
      this.isRowFocused=false;
    },
    async updateProduct(){
      await this.$store.dispatch('menu/updateMenuItemBatchAction', {merchantId: this.currentMerchant.id, update: [{
          id: this.product.id,
          name: this.product.name,
          description: this.product.description,
          price_onsite: this.product.price.onsite*100,
          price_pickup: this.product.price.pickup*100,
          price_delivery: this.product.price.delivery*100,
          highlighted: this.product.highlighted,
          status: this.product.status,
          imageData: this.imageData
        }]})
    },
    processProduct(data){
      const product = JSON.parse(JSON.stringify(data));
      product.price.delivery = product.price.delivery/100;
      product.price.pickup = product.price.pickup/100;
      product.price.onsite = product.price.onsite/100;

      if(product.archived)
        product.status = 'archived';
      else if(product.unavailable)
        product.status = 'unavailable';
      else
        product.status = 'available';

      this.product = product;
    },
    showTooltip(value){
      const options = value.children.map(uid => this.menu.modifierGroups.find(m => m.uid===uid));
      const optionMandatory = options.filter(o => o.min>0).map(opt => opt.name);
      const optionOptional = options.filter(o => o.min===0).map(opt => opt.name);

      const content = `
        <div class="w-[200px]">
          <div class="flex flex-col items-start text-start gap-[.2em] px-0 py-[.7em]">
            ${optionMandatory.map(item => `<div class="font-bold color-[#333333] px-[1.3em] py-0 overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px] leading-6">${item}*</div>`).join('')}
            ${optionMandatory.length && optionOptional.length ? `<div class="w-full my-[6px] h-[1px] bg-[#d1d5db]"></div>` : ''}
            ${optionOptional.map(item => `<div class="color-[#333333] px-[1.3em] py-0 overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px] leading-6">${item}</div>`).join('')}
          </div>
        </div>`

      let idTippy = `#tippy-${value.id}`

      if(!this.tippyInstances.map(instance => '#'+instance[0].reference.id).includes(idTippy))

        this.tippyInstances.push(
          tippy(idTippy, {
            content: content,
            allowHTML: true,
            placement: 'top',
            theme:'options',
            arrow: true,
          })
        )

    },
  },
  computed: {
    ...mapGetters(['currentMerchant']),
    ...mapGetters({
      selectedMenuItems: 'menu/selectedMenuItems',
      archivedItemsVisibility: 'menu/archivedItemsVisibility',
      menu: 'menu/menu',
      currentMerchant: 'currentMerchant'
    })
  },
  watch: {
    data(){
      this.processProduct(this.data);
    }
  },
  unmounted() {
    localStorage.setItem('currentFocusedField', this.currentFocusedField);
  },
  mounted() {
    this.processProduct(this.data);
  }
}
</script>
