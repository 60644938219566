import axios from "@/axios-instance";

export function getBookingToConfirm(merchantId){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/booking/to_confirm`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function getBookingList(merchantId, page, limit){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/booking/list?page=${page}&limit=${limit}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function createBooking(merchantId, payload){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/booking/create`, payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function getBookingSettings(merchantId){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/booking/settings`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function updateBookingSettings(merchantId, settings){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/booking/settings`, settings)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function getBookingZone(merchantId){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/booking/zones`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function createBookingZone(merchantId, zone){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/booking/zones/create`, zone)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function updateBookingZone(merchantId, zone){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/booking/zones/update`, zone)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function archiveBookingZone(merchantId, zoneId){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/booking/zones/archive`, {id: zoneId})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}


export function getBookingShifts(merchantId){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/booking/shifts`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function updateBookingShifts(merchantId, slotId, start, end, enabled){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/booking/shifts/toggle_interval`, {slotId, start, end, enabled})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function getBooking(merchantId, bookingId){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/booking/${bookingId}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function updateBooking(merchantId, bookingId, booking){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/booking/${bookingId}`, booking)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}


export function validateBooking(merchantId, bookingId){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/booking/${bookingId}/confirm`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function declineBooking(merchantId, bookingId, reason, message){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/booking/${bookingId}/decline`, {reason, message})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function addInternalComment(merchantId, bookingId, comment){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/booking/${bookingId}/internal_comment`, {comment})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
