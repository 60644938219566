import {getMerchantCategories, getSystemSettings} from "@/services/SystemService";

export default {
    namespaced: true,
    state: {
        features: null,
        quota: null,
        merchantCategories: null
    },
    mutations: {
        SET_SYSTEM_FEATURES(state, features) {
            state.features = features;
        },
        SET_SYSTEM_QUOTA(state, quota) {
            state.quota = quota;
        },
        SET_MERCHANT_CATEGORIES(state, categories) {
            state.merchantCategories = categories;
        }
    },
    getters: {
        features: state => state.features,
        quota: state => state.quota,
        merchantCategories: state => state.merchantCategories,
    },
    actions: {
        async getSystemInfos({ commit }) {
            const data = await getSystemSettings();
            commit('SET_SYSTEM_FEATURES', data.features);
            commit('SET_SYSTEM_QUOTA', data.quota);
        },
        async getMerchantCategories({commit}){
            const merchantCategories = await getMerchantCategories();
            commit('SET_MERCHANT_CATEGORIES', merchantCategories);
        }
    }
}
