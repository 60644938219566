<template>
  <div class="md:mt-16 sm:mt-4">

    <table class="menu-table">
      <thead>
      <tr>
        <th class="relative">
          <div class="absolute w-full h-full top-0 left-0 flex justify-center items-center cursor-pointer" @click="checkAllToggle">
            <input type="checkbox" :checked="isAllProductChecked" class="form-checkbox pointer-events-none h-5 w-5">
          </div>
        </th>
        <th class="relative">
          <div class="absolute w-full h-full top-0 left-0 flex justify-center items-center">
            <i class="material-icons text-gray-600">photo_camera</i>
          </div>
        </th>
        <th>Nom du produit</th>
        <th>Description</th>
        <th class="no-padding w-[21rem]">
          <div class="flex flex-col h-full">
            <div class="border-b py-1 text-sm flex items-center justify-center flex-1 ">
              <span>Gestion des prix</span>
              <i class="material-icons text-base ml-2 text-gray-500">info_outline</i>
            </div>
            <div class="flex">
              <div class="text-sm w-28 py-1">Sur place</div>
              <div class="text-sm w-28 border-l py-1">Click&Collect</div>
              <div class="text-sm w-28 border-l py-1">Livraison</div>
            </div>
          </div>
        </th>
        <th class="w-14">Pdt Star</th>
        <th>Disponibilité</th>
        <th>Options</th>
        <th class="min-w-[21px]"></th>
      </tr>
      </thead>
      <tbody>


      <template v-if="!search">
        <template v-for="cat in menu.categories.filter(c => archivedItemsVisibility || !c.archived)" :key="cat.itemIds">
          <table-category-row :category="cat"/>
          <template v-if="cat.itemIds.length>0">
            <table-product-row v-for="item in menu.items.filter(c => archivedItemsVisibility || !c.archived).filter(i => cat.itemIds.includes(i.uid))" :key="item.uid" :data="item"/>
          </template>
          <template v-else>
            <tr>
              <td colspan="11">
                <div class="flex items-center justify-center h-12">
                  <span class="text-sm text-gray-600">Pas de produit dans cette catégorie · <a class="cursor-pointer font-semibold text-primary underline" @click="createProduct(cat.id)">Ajouter un produit</a></span>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </template>
      <template v-else>
        <template v-for="cat in groups" :key="cat.itemIds">
          <table-category-row :category="cat"/>
          <template v-if="cat.itemIds.length>0">
            <table-product-row v-for="item in cat.items" :key="item.uid" :data="item"/>
          </template>
          <template v-else>
            <tr>
              <td colspan="11">
                <div class="flex items-center justify-center h-12">
                  <span class="text-sm text-gray-600">Pas de produit dans cette catégorie · <a class="cursor-pointer font-semibold text-primary underline" @click="createProduct(cat.id)">Ajouter un produit</a></span>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </template>






      </tbody>
    </table>
  </div>

</template>


<script>
import TableProductRow from "@/components/Menu/Elements/TableProductRow.vue";
import TableCategoryRow from "@/components/Menu/Elements/TableCategoryRow.vue";
import {mapGetters} from "vuex";
import {getCategoryItems} from "@/services/MenuService";
import EventBus from "@/EventBus";
import {useModal} from "vue-final-modal";
import MenuItemEditor from "@/views/Menu/MenuItemEditor.vue";
import Fuse from "fuse.js";
export default {
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  setup(){
    const productModal = useModal({component: MenuItemEditor});
    return {productModal}
  },
  unmounted() {
    console.log('table unmounted')
  },
  methods: {
    async createProduct(catId){
      await this.productModal.patchOptions({attrs: {category: catId, item:null}});
      await this.productModal.open();
    },
    checkAllToggle(){
      if(this.isAllProductChecked){
        this.$store.commit('menu/SET_SELECTED_MENU_ITEMS', []);
        this.menu.categories.forEach((cat) => {
          EventBus.emit('menu:category-uncheck', cat);
        })
      }
      else{
        this.menu.categories.forEach((cat) => {
          EventBus.emit('menu:category-check', cat);
        })
        this.$store.commit('menu/SET_SELECTED_MENU_ITEMS', this.menu.items.filter(i => this.archivedItemsVisibility ? true : i.archived===false).map(i => i.id));
      }
    }
  },
  components: {TableCategoryRow, TableProductRow},
  computed: {
    ...mapGetters({
      archivedItemsVisibility: 'menu/archivedItemsVisibility',
      selectedMenuItems: 'menu/selectedMenuItems',
      menu: 'menu/menu'
    }),


    groups(){
      const cat = JSON.parse(JSON.stringify(this.menu.categories));
      return cat.filter(c => this.archivedItemsVisibility ? true : c.archived===false).map((c) => {
        const items = this.menu.items.filter(i => c.itemIds.indexOf(i.uid) !== -1 )
          .filter(i => this.archivedItemsVisibility ? true : i.archived===false)
          .map((i,index) => {
            if(i.archived)
              i.status = 'archived';
            else if(i.unavailable)
              i.status = 'unavailable';
            else
              i.status = 'available';
            i.sort = index;
            return i;
          }).sort((a,b) => a.sort - b.sort);
        c.items = c.itemIds.map((uid) => items.find(i => i.uid === uid)).filter(e => e !== undefined);
        if(this.search){
          const fuseActions = new Fuse(c.items, {threshold: 0.3, includeScore: true, minMatchCharLength:0, distance: 35, keys: ['name']});
          c.items = fuseActions.search(this.search).sort((a,b)=>a.score-b.score).map(r => r.item)
        }
        return c;
      })
        .sort((a,b) => a.sort - b.sort)
        .filter((e) => {
          if(this.search) return e.items.length>0;
          else return true;
        })
    },


    isAllProductChecked(){
      return this.selectedMenuItems.length === this.menu.items.filter(i => this.archivedItemsVisibility ? true : i.archived===false).length;
    }
  },
  mounted() {
    EventBus.on('menu:category-checked', (category) => {
      const productIds = category.itemIds
          .map(id => this.menu.items.find(item => item.uid === id))
          .filter(p => !this.selectedMenuItems.includes(p.id) && (this.archivedItemsVisibility ? true : p.archived === false))
          .map(p => p.id);
      this.$store.commit('menu/ADD_SELECTED_MENU_ITEM', productIds);
    });

    EventBus.on('menu:category-unchecked', (category) => {
      const productIds = category.itemIds
          .map(id => this.menu.items.find(item => item.uid === id))
          .filter(p => (this.archivedItemsVisibility ? true : p.archived === false))
          .map(p => p.id);
      const checkedProductIds = this.selectedMenuItems.filter(p => productIds.includes(p));
      if(checkedProductIds.length === productIds.length){
        productIds.forEach((id) => {
          this.$store.commit('menu/REMOVE_SELECTED_MENU_ITEM', id);
        });
      }
    });
  }
}
</script>
