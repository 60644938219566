<template>
  <vue-final-modal
      v-bind="$attrs"
      name="mediaImageUploadModal"
      modal-id="mediaImageUploadModal"
      class="flex justify-center items-center sm:p-2"
      @closed="close"
      :content-class="['bg-white p-5 rounded-md']">

    <div class="md:min-w-[300px] flex justify-center">
      <div class="transition-all" :style="{maxWidth: maxWidth+'px'}" :class="{'opacity-0':maxWidth===50}">
        <img :src="imageSrc" id="croppr" class="w-full"/>
      </div>
    </div>

    <div class="mt-4 flex items-center justify-between gap-3">
      <button class="bg-gray-100 border flex items-center h-9 font-medium rounded-md text-sm px-3" @click="rotate"><i class="material-icons text-lg mr-2">rotate_90_degrees_ccw</i>Pivoter</button>

      <button class="bg-primary text-white border flex items-center h-9 font-medium rounded-md text-sm px-3 disabled:opacity-50" :disabled="isLoading" @click="validate">
        <i class="material-icons text-lg mr-2" v-if="!isLoading">cloud_upload</i>
        <loader class="h-4 w-4 mr-2" v-else/>
        Enregistrer
      </button>
    </div>
  </vue-final-modal>
</template>


<script>
import {useVfm, VueFinalModal} from "vue-final-modal";
import Croppr from 'croppr';
import 'croppr/dist/croppr.css';
import Loader from "@/components/Ui/Elements/Loader.vue";
import EventBus from "@/EventBus";

export default {
  components: {Loader, VueFinalModal},
  props: {
    uploadId: {
      type: String,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    },
    aspectRatio: {
      type: Number,
      default: null
    },
    sizes: {
      type: Array,
      default: ['800x800']
    },
  },
  data(){
    return {
      cropper: null,
      imageDataUrl: null,
      isLoading: false,
      rotation: 0,
      maxWidth: 50,
    }
  },
  methods: {
    close(){
      setTimeout(() => {
        EventBus.emit('media:crop-and-resize:'+this.uploadId+':aborted', true);
      },100)
    },
    rotate(){
      this.rotate90(this.imageDataUrl,(res) => {
        this.imageDataUrl = res;
        document.getElementsByClassName('croppr-imageClipped')[0].src = res;
        document.getElementsByClassName('croppr-image')[0].src = res;
      })
    },
    async getRatio(){
      return new Promise((resolve, reject) => {
        const image = document.getElementById('croppr');
        image.onload = (e) => {
          const maxHeight = window.innerHeight*0.7;
          const ratio = image.clientWidth / image.clientHeight;
          this.maxWidth = (Math.min(ratio*0.7,1)) * Math.min(window.innerWidth*0.8,800);

          if(this.maxWidth / ratio > maxHeight){
            this.maxWidth = maxHeight * ratio;
          }

          setTimeout(() => {
            resolve(true);
          },200)
        }
      });
    },
    rotate90(src, callback){
      this.rotation += 90;
      const img = new Image()
      img.src = src
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.height
        canvas.height = img.width
        canvas.style.position = "absolute"
        const ctx = canvas.getContext("2d")
        ctx.translate(img.height, img.width / img.height)
        ctx.rotate(Math.PI / 2)
        ctx.drawImage(img, 0, 0)
        callback(canvas.toDataURL())
      }
    },
    async validate(){
      this.isLoading=true;
      const image = document.querySelector('img.croppr-image');
      const values = this.cropper.getValue();
      const result = [];
      const sizes = this.aspectRatio === null ? [values.width+'x'+values.height] : this.sizes;
      for(let size of sizes){
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = size.split('x')[0];
        canvas.height = size.split('x')[1];
        ctx.drawImage(image, values.x, values.y, values.width, values.height, 0, 0, canvas.width,canvas.height);
        result.push({
          size: size,
          fileType: 'image/jpeg',
          data: canvas.toDataURL('image/jpeg')
        });
      }
      EventBus.emit('media:crop-and-resize:'+this.uploadId, result);
      useVfm().close('mediaImageUploadModal');
    }
  },
  async mounted() {
    await this.getRatio();

    this.imageDataUrl = this.imageSrc
    this.cropper = new Croppr('#croppr', {
      aspectRatio: this.aspectRatio,
    });
  }
}
</script>
