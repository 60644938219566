<template>
  <div class="w-full h-full bg-cover bg-center flex items-center justify-center px-4" style="background-image: url('/public/images/map_not_available.png');" v-if="error">
    <div class="bg-red-500 text-white text-sm px-3 py-1 font-semibold rounded-md flex items-center gap-2 text-left"><i class="material-icons text-base">error</i>La carte n'est pas disponible pour le moment</div>
  </div>
  <div class="w-full h-full" id="map" ref="map" v-else></div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import {ref} from "vue";

export default {
  setup(){
    const map = ref();
    return {map}
  },
  props: {
    restaurantPosition: {
      required: true
    },
    clientPosition: {
      required: true
    }
  },
  data(){
    return {
      google: false,
      error: false
    }
  },
  methods: {
    createMap(){
      this.map = new this.google.maps.Map(this.map, {
        center: { lat: 43.69993616672983, lng: 7.265005201349112 },
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
        keyboardShortcuts: false,
        styles: [
          {
            featureType: "poi",
            stylers: [{ visibility: "off" }],
          },
        ],
        zoom: 14,
      });

      new this.google.maps.Marker({
        position: { lat: this.restaurantPosition.coordinates[1], lng: this.restaurantPosition.coordinates[0] },
        map: this.map,
        icon: {
          url: '/images/merchant.svg',
          scaledSize: new this.google.maps.Size(30, 30),
          anchor: new this.google.maps.Point(15, 15),
        },
      });
      new this.google.maps.Marker({
        position: { lat: this.clientPosition.coordinates[1], lng: this.clientPosition.coordinates[0] },
        map: this.map,
        icon: {
          url: '/images/client.svg',
          scaledSize: new this.google.maps.Size(30, 30),
          anchor: new this.google.maps.Point(15, 15),
        },
      });

      const bounds = new this.google.maps.LatLngBounds();
      bounds.extend({ lat: this.restaurantPosition.coordinates[1], lng: this.restaurantPosition.coordinates[0] });
      bounds.extend({ lat: this.clientPosition.coordinates[1], lng: this.clientPosition.coordinates[0] });
      this.map.fitBounds(bounds);

      // Create arc between two points
      const arc = new this.google.maps.Polyline({
        path: [
          { lat: this.restaurantPosition.coordinates[1], lng: this.restaurantPosition.coordinates[0] },
          { lat: this.clientPosition.coordinates[1], lng: this.clientPosition.coordinates[0] },
        ],
        geodesic: true,
        strokeColor: "#000",
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });
      arc.setMap(this.map);

    }
  },
  mounted() {
    const loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_API,
      version: "weekly",
      libraries: ["places"]
    });

    loader.load()
        .then((google) => {
          this.google = google;
          this.createMap();
        })
        .catch(e => {
          this.error = true;
        });
  }
}
</script>
