import axios from "@/axios-instance";
import {downloadOrderInvoices} from "@/services/OrderService";

export function getAccountingMonthList(merchantId){
    return new Promise((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/accounting/summary`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getCustomerPortalUrl(merchantId, year, month){
    return new Promise((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/accounting/customer_portal`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}


export function getAccountingMonth(merchantId, year, month){
    return new Promise((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/accounting/summary/${year}/${month}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getAccountingMonthOrders(merchantId, year, month, page){
    return new Promise((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/accounting/summary/${year}/${month}/orders/${page}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getAccountingMonthOrdersAllIds(merchantId, year, month){
    return new Promise((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/accounting/summary/${year}/${month}/orders/all_ids`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}


export async function downloadAllInvoiceForAMonth(merchantId, year, month){
    const allIds = await getAccountingMonthOrdersAllIds(merchantId, year, month);
    return await downloadOrderInvoices(merchantId, allIds);
}

export function getBankTransferByOrderIds(merchantId, orderIds){
    return new Promise((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/accounting/bank_transfers/order_ids`, {orderIds})
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}


export function getBankTransfer(merchantId, page, limit){
    return new Promise((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/accounting/bank_transfers?page=${page}&limit=${limit}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}


export function getBankTransferDetails(merchantId, payoutId, page, limit){
    return new Promise((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/accounting/bank_transfers/${payoutId}?page=${page}&limit=${limit}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getBankBalanceDetails(merchantId, namespace){
    return new Promise((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/accounting/bank_transfers/balance/${namespace}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}


export function accountingDownload(merchantId, year, month, type){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/accounting/summary/${year}/${month}/download?type=${type}`,{responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                if(type === 'summary'){
                    const filename = `summary-${year}-${month}.pdf`;
                    link.setAttribute('download', `${filename}`);
                    document.body.appendChild(link);
                    link.click();
                    resolve(response.data);
                }
                else if(type === 'order_export'){
                    const filename = `export-${year}-${month}.xls`;
                    link.setAttribute('download', `${filename}`);
                    document.body.appendChild(link);
                    link.click();
                    resolve(response.data);
                }
                else if(type === 'manual-orders'){
                    const filename = `envoislivreur-et-paiements-${year}-${month}.xls`;
                    link.setAttribute('download', `${filename}`);
                    document.body.appendChild(link);
                    link.click();
                    resolve(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    }));
}



export function getAccountingReceivedInvoices(merchantId, from, to){
    return new Promise((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/accounting/invoices/received`, {from,to})
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getAccountingIssuedInvoices(merchantId, from, to){
    return new Promise((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/accounting/invoices/issued`, {from,to})
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function getInvoice(merchantId, ref){
    return new Promise((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/accounting/invoices/get`, {ref})
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}


export function downloadCourierMonth(merchantId, months){
    const month = months.join('|');
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/accounting/invoices/courier/${month}/download`,{responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                const filename = `couriers-${month}.zip`;
                link.setAttribute('download', `${filename}`);
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    }));
}
export function downloadPlatformInvoices(merchantId, ids){
    const id = ids.join('|');
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/accounting/invoices/platform/${id}/download`,{responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                let filename = ids.length === 1 ? `facture-delicity.pdf` : `factures-delicity.zip`;
                link.setAttribute('download', `${filename}`);
                document.body.appendChild(link);
                link.click();

                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    }));
}
