<template>
  <page-block :title="$t('order.recap_title')" :noTitlePadding="true">
    <template v-slot:no-padding>
      <div class="px-6 pb-4 flex flex-col gap-2 border-b text-sm">

        <!-- Turnover -->
        <div class="flex justify-between items-center">
          <strong class="text-base">{{ $t('order.price.turnover') }}</strong>
          <strong class="text-lg" v-if="order.price.turnover">{{formatPrice(order.price.turnover)}}</strong>
        </div>

        <div class="flex gap-1 justify-between text-gray-600" :class="{'!text-gray-300':order.price.productRealPrice===0}">
          <svg width="14" height="14" class="h-3 mt-[2px] mr-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.643989 0.819824L0.644008 6.70373C0.644019 10.2188 3.86695 13.0185 7.91347 13.0185L13.8938 13.0185" stroke="currentColor"/>
          </svg>
          <span class="flex-1">{{ $t('order.price.real_product_price') }} (1)</span>
          <span class="ml-2">{{formatPrice(order.price.productRealPrice)}}</span>
        </div>

        <div class="flex gap-1 justify-between text-gray-600" :class="{'!text-gray-300':order.price.productIncreasedPrice===0}">
          <svg width="14" height="14" class="h-3 mt-[2px] mr-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.643989 0.819824L0.644008 6.70373C0.644019 10.2188 3.86695 13.0185 7.91347 13.0185L13.8938 13.0185" stroke="currentColor"/>
          </svg>
          <span class="flex-1">{{ $t('order.price.product_increased_price') }} (2)</span>
          <span class="ml-2">{{formatPrice(order.price.productIncreasedPrice)}}</span>
        </div>

        <div class="flex gap-1 justify-between text-gray-600" :class="{'!text-gray-300':order.price.deliveryCollectedByMerchantPrice===0}">
          <svg width="14" height="14" class="h-3 mt-[2px] mr-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.643989 0.819824L0.644008 6.70373C0.644019 10.2188 3.86695 13.0185 7.91347 13.0185L13.8938 13.0185" stroke="currentColor"/>
          </svg>
          <span class="flex-1">{{ $t('order.price.delivery_collected_by_merchant') }} (3)</span>
          <span class="ml-2">{{formatPrice(order.price.deliveryCollectedByMerchantPrice)}}</span>
        </div>

        <div class="flex gap-1 flex-col text-gray-600">
          <div class="flex gap-1 justify-between text-gray-600"  v-for="discount in order.discounts">
            <svg width="14" height="14" class="h-3 mt-[2px] mr-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.643989 0.819824L0.644008 6.70373C0.644019 10.2188 3.86695 13.0185 7.91347 13.0185L13.8938 13.0185" stroke="currentColor"/>
            </svg>
            <span class=" flex-1">Promotion : {{discount.description}} (5)</span>
            <span class=" ml-2" v-if="discount.value!==0">{{formatPrice(discount.value)}}</span>
          </div>
        </div>

      </div>


      <!-- Delivery title -->
      <div class="px-6 py-4 flex flex-col gap-2 border-b text-sm" v-if="order.type === 'delivery' && !order.delivery.selfDelivery">
        <div class="flex justify-between items-center">
          <strong class="text-base">{{ $t('order.price.delivery') }}  ({{formatDistance(order.delivery.distance)}})</strong>
          <strong class="text-lg">{{formatPrice(order.price.deliveryTotal)}}</strong>
        </div>


        <div class="flex gap-1 justify-between text-gray-600" :class="{'!text-gray-300':order.price.deliveryPaidByClientPrice===0}">
          <svg width="14" height="14" class="h-3 mt-[2px] mr-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.643989 0.819824L0.644008 6.70373C0.644019 10.2188 3.86695 13.0185 7.91347 13.0185L13.8938 13.0185" stroke="currentColor"/>
          </svg>
          <span class="flex-1">{{ $t('order.price.delivery_pay_by_client') }} (2)+(3)</span>
          <span class="ml-2">{{formatPrice(order.price.deliveryPaidByClientPrice)}}</span>
        </div>

        <div class="flex gap-1 justify-between text-gray-600" :class="{'!text-gray-300':order.price.deliveryPaidByMerchantPrice===0}">
          <svg width="14" height="14" class="h-3 mt-[2px] mr-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.643989 0.819824L0.644008 6.70373C0.644019 10.2188 3.86695 13.0185 7.91347 13.0185L13.8938 13.0185" stroke="currentColor"/>
          </svg>
          <span class="flex-1">{{ $t('order.price.delivery_pay_by_merchant') }}</span>
          <span class="ml-2">{{formatPrice(order.price.deliveryPaidByMerchantPrice)}}</span>
        </div>

        <div class="flex gap-1 justify-between text-gray-600" :class="{'!text-gray-300':order.price.deliveryPaidByPlatformPrice===0}">
          <svg width="14" height="14" class="h-3 mt-[2px] mr-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.643989 0.819824L0.644008 6.70373C0.644019 10.2188 3.86695 13.0185 7.91347 13.0185L13.8938 13.0185" stroke="currentColor"/>
          </svg>
          <span class="flex-1">{{ $t('order.price.delivery_pay_by_platform') }}</span>
          <span class="ml-2">{{formatPrice(order.price.deliveryPaidByPlatformPrice)}}</span>
        </div>

        <div class="flex gap-1 justify-between text-gray-600" v-if="order.delivery?.courier">
          <span class="flex-1">{{ $t('order.price.delivery_fee_are_sended_to_courier', {courier: order.delivery.courier.name}) }}</span>
        </div>
      </div>




      <!-- Other cost title -->
      <div class="px-6 py-4 flex flex-col gap-2 border-b text-sm">
        <div class="flex justify-between items-center">
          <strong class="text-base">{{ $t('order.price.other_costs') }}</strong>
          <strong class="text-lg">{{formatPrice(parseInt(order.price.merchantPlatformFee)+parseInt(order.price.merchantBankingFee))}}</strong>
        </div>

        <div class="flex gap-1 justify-between text-gray-600" :class="{'!text-gray-300':order.price.merchantPlatformFee===0}">
          <svg width="14" height="14" class="h-3 mt-[2px] mr-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.643989 0.819824L0.644008 6.70373C0.644019 10.2188 3.86695 13.0185 7.91347 13.0185L13.8938 13.0185" stroke="currentColor"/>
          </svg>
          <span class="flex-1">{{ $t('order.price.platform_fees') }}</span>
          <span class="ml-2">{{formatPrice(order.price.merchantPlatformFee)}}</span>
        </div>

        <div class="flex gap-1 justify-between text-gray-600" :class="{'!text-gray-300':order.price.merchantBankingFee===0}">
          <svg width="14" height="14" class="h-3 mt-[2px] mr-1" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.643989 0.819824L0.644008 6.70373C0.644019 10.2188 3.86695 13.0185 7.91347 13.0185L13.8938 13.0185" stroke="currentColor"/>
          </svg>
          <span class="flex-1">{{ $t('order.price.banking_fees') }}</span>
          <span class="ml-2">{{formatPrice(order.price.merchantBankingFee)}}</span>
        </div>

      </div>


      <!-- Net title -->
      <div class="px-6 py-4 flex flex-col gap-2 border-b text-sm">
        <div class="flex justify-between items-center">
          <strong class="text-base">{{ $t('order.price.net_receive') }} (4)</strong>
          <strong  class="text-lg">{{formatPrice(order.price.netPerceivedByMerchant)}}</strong>
        </div>
        <div class="flex gap-1 justify-between" v-if="order.status !== 'canceled'">
          <span class="flex-1 text-green-600 text-base font-medium" v-if="order.price.netPerceivedByMerchant > order.price.productRealPrice">Soit {{formatPrice(order.price.netPerceivedByMerchant-order.price.productRealPrice)}} de plus que si votre client était venu sur place pour la même commande !</span>
          <span class="flex-1 text-gray-600 text-base" v-if="order.price.netPerceivedByMerchant <= order.price.productRealPrice">Soit {{formatPrice(Math.abs(order.price.netPerceivedByMerchant-order.price.productRealPrice))}} de moins que si votre client était venu sur place pour la même commande</span>
        </div>
      </div>


      <div class="px-6 py-4 flex flex-col gap-2 text-sm" v-if="order.status !== 'canceled'">
        <div class="flex justify-between items-center">
          <strong class="text-base">{{ $t('order.price.platform_real_price') }}</strong>
          <strong class="text-green-500 text-lg">{{order.price.platformRealCostPercentage}}%</strong>
        </div>
        <div class="flex gap-1 justify-between">
          <span class="flex-1 text-gray-600" v-if="order.discounts && order.discounts.length > 0">({{ $t('order.price.net_receive') }} (4) + {{ $t('order.price.discount_total') }} (5)) / {{ $t('order.price.real_product_price') }} (1)</span>
          <span class="flex-1 text-gray-600" v-else>{{ $t('order.price.net_receive') }} (4) + / {{ $t('order.price.real_product_price') }} (1)</span>
        </div>
      </div>


      <!-- Onsite title -->
      <div class="px-6 py-4 flex flex-col gap-2 text-sm border-t" v-if="order.onsitePayment">
        <div class="flex justify-between">
          <strong>{{ $t('order.price.total_to_pay_onsite') }}</strong>
          <strong>{{formatPrice(order.price.priceWt)}}</strong>
        </div>
      </div>

      <div class="p-4 flex flex-col gap-2 text-sm border-t" v-if="order.status === 'canceled'">
        <span class="font-bold text-red-600" v-if="order.onsitePayment">Commande annulée.</span>
        <span class="font-bold text-red-600" v-else>Commande annulée, le client a été remboursé.</span>
      </div>

    </template>
  </page-block>
</template>

<script>
import {formatPrice, formatDistance} from "@/services/FormatService";

export default {
  methods: {formatPrice, formatDistance},
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
