<template>
  <div class="page-block flex flex-col justify-between gap-3 px-6 py-5 rounded-md h-36">

    <div class="flex items-center">
      <img :src="order.client.avatar" class="h-14 w-14 rounded-full">
      <div class="flex flex-col ml-4 flex-1">
        <span class="font-bold text-lg leading-tight">{{order.client.name}}</span>
        <div class="flex items-center gap-2 mt-1">
          <span class="text-gray-600 text-sm">Depuis votre site</span>
          <button class="underline font-medium text-primary text-sm" @click="openClientModal" v-if="order.client.id && currentMerchant.features.clientDetailsEnabled">Voir la fiche client</button>
          <button class="font-medium text-primary text-sm flex items-center gap-1" v-else-if="order.client.id && !currentMerchant.features.clientDetailsEnabled">
            <span class="underline">Voir la fiche client</span>
            <div @click="unlockFeature" class="locked-feature-badge"><i class="material-icons text-sm mr-1">lock</i><span class="text-sm font-medium">{{ $t('system.limited') }}</span></div>

          </button>
        </div>
      </div>
    </div>


    <div class="grid grid-cols-3 gap-2">
      <a :href="'tel:'+order.client.phone.e164" class="flex text-center items-center justify-center h-10 btn bg-primary text-xs text-white px-1">{{order.client.phone.national}}</a>
      <div class="flex-1 h-10 bg-gray-200 rounded-md flex items-center justify-center" v-if="!fidelity">
        <loader class="h-4 w-4"/>
      </div>
      <div class="flex-1 h-10 bg-gray-200 rounded-md flex items-center justify-center" v-if="!fidelity">
        <loader class="h-4 w-4"/>
      </div>
      <div class="flex-1 h-10 bg-gray-200 rounded-md flex items-center text-sm justify-center" v-if="fidelity">
        <span>{{ $t('client.fidelity.orders', {count: fidelity.orders}) }}</span>
      </div>
      <div class="flex-1 h-10 bg-gray-200 rounded-md flex items-center text-sm justify-center" v-if="fidelity">
        <span>{{ $t('client.fidelity.bookings', {count: fidelity.bookings}) }}</span>
      </div>
    </div>


  </div>
</template>

<script>
import {useModal} from "vue-final-modal";
import ClientPage from "@/views/Clients/ClientPage.vue";
import {getClientFidelityStats} from "@/services/ClientService";
import {mapGetters} from "vuex";
import {unlockFeature} from "@/services/Subscription";

export default {
  setup(){
    const clientModal = useModal({component: ClientPage});
    return {
      clientModal
    }
  },
  data(){
    return {
      fidelity: null
    }
  },
  methods: {
    unlockFeature,
    openClientModal(){
      this.clientModal.patchOptions({attrs: {clientId: this.order.client.id}});
      this.clientModal.open();
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    })
  },
  async mounted() {
    this.fidelity = await getClientFidelityStats(this.currentMerchant.id, this.order.client.id)
  }
}
</script>
