<template>
  <div class="pl-2 pr-3 h-9 bg-[#F6EECF] flex items-center gap-2 rounded-full w-[7.5rem]" v-if="status === 'taken' || status === 'preparation' || status === 'preparation_done' || status === 'delivery_ongoing'">
    <i class="material-icons text-[#ED9613] text-xl">pending</i>
    <span class="font-semibold text-[#8A580D]">En cours</span>
  </div>
  <div class="pl-2 pr-3 h-9 bg-[#70C32E]/20 text-green-900 flex items-center w-[7.5rem] gap-2 rounded-full" v-else-if="status === 'delivered'">
    <i class="material-icons text-xl text-[#70C32E]">check_circle</i>
    <span class="font-semibold text-[#427A16]">Terminée</span>
  </div>
  <div class="pl-2 pr-3 h-9 bg-[#FBE3E3] text-red-900 flex items-center w-[7.5rem] gap-2 rounded-full" v-else-if="status === 'canceled'">
    <i class="material-icons text-xl text-[#C00000]">cancel</i>
    <span class="font-semibold text-[#820000]">Annulée</span>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: 'taken'
    }
  }
}
</script>
