import axios from "@/axios-instance";

export async function getAnalytics(merchantId, period, type, unit){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/analytics`, {period, type, unit})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
