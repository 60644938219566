<template>
  <Modal size="small" v-slot="{close}" title="Ajouter une option">
    <div class="pt-2 pb-3">
      <label class="form-label">{{ $t('menu.bulk_option_add', {count: selectedMenuItems.length}) }}</label>
      <select class="form-select" v-model="modifierGroupId">
        <option :value="null">-- Choisir l'option --</option>
        <option :value="modifierGroup.id" v-for="modifierGroup in menu.modifierGroups" :key="modifierGroup.uid">{{modifierGroup.name}} ({{modifierGroup.min === 0 ? 'Option facultative' : 'Option obligatoire'}})</option>
      </select>
      <div class="flex justify-end mt-5">
        <button class="btn bg-primary text-white rounded disabled:opacity-50" :disabled="!modifierGroupId" @click="save">Ajouter</button>
      </div>
    </div>
  </Modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";

export default {
  components: {Modal},
  setup(){
    const vfm = useVfm();
    return {vfm}
  },
  computed: {
    ...mapGetters({
      menu: 'menu/menu',
      currentMerchant: 'currentMerchant',
      selectedMenuItems: 'menu/selectedMenuItems',
    })
  },
  data(){
    return {
      modifierGroupId: null
    }
  },
  methods: {
    save(){
      this.$store.dispatch('menu/attachModifierGroupsBatchAction', {
        merchantId: this.currentMerchant.id,
        modifierGroupIds: [this.modifierGroupId],
        productIds: this.selectedMenuItems
      });
      useVfm().closeAll();
    }
  }
}
</script>
