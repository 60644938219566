<template>
  <div class="h-9 bg-white border-2 border-orange-300 flex items-center justify-center rounded-full w-[10rem]">
    <span class="font-semibold text-gray-900 mr-2">Valider ?</span>
    <button class="h-9 w-6 flex items-center justify-center text-[#FF0000]" @click="decline">
      <i class="material-icons">cancel</i>
    </button>
    <button class="h-9 w-6 pl-1 flex items-center justify-center text-[#70C32E]" @click="validate">
      <i class="material-icons">check_circle</i>
    </button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {useModal} from "vue-final-modal";
import BookingCancellation from "@/components/Booking/Modal/BookingCancellation.vue";

export default {
  setup(){
    const bookingCancelModal = useModal({component: BookingCancellation});
    return {
      bookingCancelModal
    }
  },
  props: {
    bookingId: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    })
  },
  methods: {
    validate(){
      this.$store.dispatch('booking/validate', {merchantId: this.currentMerchant.id, bookingId: this.bookingId})
    },
    decline(){
      this.bookingCancelModal.patchOptions({attrs: {bookingId: this.bookingId}});
      this.bookingCancelModal.open();
    }
  }
}
</script>
