import axios from "@/axios-instance.js";
import {
    cancelOrder,
    confirmPreorder,
    getMerchantOrder,
    getMerchantOrders,
    getMerchantPreorders
} from "@/services/OrderService";
import order from "@/views/Orders/Order.vue";
import {DateTime} from "luxon";

export default {
    namespaced: true,
    state: {
        orderList: [],
        preOrderList: null,
        orderDetails: []
    },
    mutations: {
        ADD_ORDER_DETAILS(state, orderDetails) {
            state.orderDetails.push(orderDetails);
        },
        SET_ORDER_LIST(state, orderList) {
            state.orderList = orderList;
        },
        ADD_TO_ORDER_LIST(state, order) {
            state.orderList.push(...order);
        },
        SET_PRE_ORDER_LIST(state, preOrderList) {
            state.preOrderList = preOrderList;
        },
        SET_COURIER_BAN_STATUS(state, {courierId, banned}) {
            state.orderDetails
                .filter(e => e.delivery && e.delivery.courier && e.delivery.courier.id === courierId)
                .forEach(e => {
                    e.delivery.courier.banned = banned;
                })
        },
        UPDATE_ORDER_STATUS(state, {orderId, status}) {
            if(state.orderList){
                const orderListIndex = state.orderList.findIndex(o => o.id === orderId);
                if(orderListIndex != -1){
                    state.orderList[orderListIndex].status = status;
                }
            }
            if(state.preOrderList && state.preOrderList.items){
                const preorderListIndex = state.preOrderList.items.findIndex(o => o.id === orderId);
                if(preorderListIndex != -1){
                    state.preOrderList.items[preorderListIndex].status = status;
                }
            }
            const orderDetailsIndex = state.orderDetails.findIndex(o => o.id === orderId);
            if(orderDetailsIndex != -1){
                state.orderDetails[orderDetailsIndex].status = status;
            }
        },
        UPDATE_ORDER_PREORDER_CONFIRM(state, {orderId, ringAt, preorderConfirmedAt}) {
            if(state.orderList){
                const orderListIndex = state.orderList.findIndex(o => o.id === orderId);
                if(orderListIndex != -1){
                    state.orderList[orderListIndex].preorderRingAt = ringAt;
                    state.orderList[orderListIndex].preorderConfirmedAt = preorderConfirmedAt;
                }
            }
            if(state.preOrderList && state.preOrderList.items){
                const preorderListIndex = state.preOrderList.items.findIndex(o => o.id === orderId);
                if(preorderListIndex != -1){
                    state.preOrderList.items[preorderListIndex].preorderRingAt = ringAt;
                    state.preOrderList.items[preorderListIndex].preorderConfirmedAt = preorderConfirmedAt;
                }
            }
            const orderDetailsIndex = state.orderDetails.findIndex(o => o.id === orderId);
            if(orderDetailsIndex != -1){
                state.orderDetails[orderDetailsIndex].preorderRingAt = ringAt;
                state.orderDetails[orderDetailsIndex].preorderConfirmedAt = preorderConfirmedAt;
            }
        }
    },
    getters: {
        orderList: state => state.orderList,
        preOrderList: state => state.preOrderList
    },
    actions: {
        async cancelOrder({commit}, {merchantId, orderId, reason}){
            commit('UPDATE_ORDER_STATUS', {orderId, status: 'canceled'});
            await cancelOrder(merchantId, orderId, reason);
        },
        async confirmPreorder({commit}, {merchantId, orderId, datetime}){
            commit('UPDATE_ORDER_PREORDER_CONFIRM', {orderId, ringAt: datetime, preorderConfirmedAt: DateTime.now().toISO()});
            await confirmPreorder(merchantId, orderId, datetime);
        },
        async getOrderListAction({commit}, {merchantId, page, filters}){
            const orders = await getMerchantOrders(merchantId, page, filters);
            commit('SET_ORDER_LIST', orders.items);
            return orders;
        },
        async getPreOrderListAction({commit}, {merchantId, page}){
            const preorders = await getMerchantPreorders(merchantId, page);
            commit('SET_PRE_ORDER_LIST', preorders);
            return preorders;
        },
        async getOrderAction({commit, state, rootGetters}, {orderId}){
            if(state.orderDetails.some(order => order.id === orderId)){
                return state.orderDetails.find(order => order.id === orderId);
            }
            const order = await getMerchantOrder(rootGetters.currentMerchant.id, orderId);
            commit('ADD_ORDER_DETAILS', order);
            return order;
        }
    }
}
