<template>
  <modal-sliding  background-color="bg-gray-100">
    <div class="flex flex-col h-full">
      <div class="px-4 pt-3">
        <div class="flex justify-between">
          <div class="flex items-center gap-4">
            <button class="flex items-center justify-center bg-white border px-4 py-1 gap-2 rounded-md hover:bg-gray-50" @click="close"><i class="text-lg material-icons">arrow_back</i>Retour</button>
            <span class="text-xl font-bold uppercase">Paramètres de votre intégration HubRise</span>
          </div>
        </div>
      </div>

      <div class="px-6 py-10 overflow-y-auto flex-1">


        <div class="bg-primary/10 text-gray-900 flex items-center gap-6 px-5 py-4 rounded-md mb-8" v-if="hubrise.isMenuMaster">
          <i class="material-icons text-primary">cloud_done</i>
          <div class="flex flex-col">
            <span class="">Votre menu est actuellement synchronisé à votre catalogue HubRise !</span>
            <b class="font-bold my-1">-> Cela signifie que vous ne pouvez plus éditer votre menu sur Delicity (sauf les images et les prix Click&Collect / Livraison des produits)</b>
            <span>Car dans la plupart des intégrations de caisse, il est nécessaire d'avoir les mêmes produits sur Delicity que dans votre caisse afin de pouvoir recevoir les commandes.</span>
          </div>
        </div>


        <label class="flex flex-items gap-2">
          <input type="checkbox" class="form-checkbox h-6 w-6" v-model="isMenuMaster">
          <span class="text-lg font-medium flex-1 cursor-pointer">Synchroniser le catalogue HubRise avec le menu Delicity</span>
        </label>

        <div class="bg-red-500/10 px-5 py-4 rounded-md mt-5 flex items-center gap-4" v-if="!hubrise.isMenuMaster && isMenuMaster">
          <i class="material-icons text-red-600">warning</i>
          <span>Attention ! Si vous activez la syncronisation du menu Delicity avec votre catalogue Hubrise, <b>Votre menu Delicity actuel seras écrasé par celui d'Hubrise.</b> les changements auront effets après l'enregistrement de cette page.</span>
        </div>



        <div class="mt-8 bg-white gap-4 rounded-xl">
          <div class="py-4 px-6 flex items-center w-full">
            <button class="h-10 w-10 bg-gray-100 flex items-center justify-center rounded-xl" @click="showAdvancedSettings = !showAdvancedSettings">
              <i class="material-icons">{{ !showAdvancedSettings ? 'expand_more' : 'expand_less' }}</i>
            </button>
            <div class="flex-col flex ml-6 flex-1">
              <span class="text-xl font-bold">Paramètres avancées</span>
              <span class="text-gray-500">Paramètre nécessaire pour intégrer HubRise avec certaines caisses.</span>
            </div>
          </div>
          <div class="grid sm:grid-cols-2 xl:grid-cols-3 px-6 gap-4 py-5 border-t" v-if="showAdvancedSettings">
            <div class="sm:col-span-2 xl:col-span-3">
              <span class="font-bold text-xl">Par mode de récupération</span>
            </div>
            <div>
              <label class="form-label">Référence "sur place"</label>
              <input type="text" class="form-input" v-model="refOnsite">
            </div>
            <div>
              <label class="form-label">Référence "à emporter"</label>
              <input type="text" class="form-input" v-model="refPickup">
            </div>
            <div>
              <label class="form-label">Référence "en livraison"</label>
              <input type="text" class="form-input" v-model="refDelivery">
            </div>
            <div class="sm:col-span-2 xl:col-span-3 mt-5 border-t pt-5">
              <span class="font-bold text-xl">Frais et promotions</span>
            </div>
            <div>
              <label class="form-label">Référence frais de service</label>
              <input type="text" class="form-input" v-model="refServiceFees">
            </div>
            <div>
              <label class="form-label">Référence frais de livraison</label>
              <input type="text" class="form-input" v-model="refDeliveryFees">
            </div>
            <div>
              <label class="form-label">Référence promotion</label>
              <input type="text" class="form-input" v-model="refDiscount">
            </div>
            <div class="sm:col-span-2 xl:col-span-3 mt-5 border-t pt-5">
              <span class="font-bold text-xl">Moyen de paiement</span>
            </div>
            <div>
              <label class="form-label">Référence paiement en ligne</label>
              <input type="text" class="form-input" v-model="refOnlinePayment">
            </div>
            <div>
              <label class="form-label">Référence paiement sur place</label>
              <input type="text" class="form-input" v-model="refOnsitePayment">
            </div>
          </div>
        </div>



      </div>
      <div class="h-16 px-6 border-t bg-white flex items-center justify-between">
        <button class="btn bg-primary text-white" @click="save">{{$t('cta.save')}}</button>
        <button class="btn bg-red-600 text-white" :disabled="isButtonLoading" @click="hubriseDestroy">Déconnexion de HubRise</button>
      </div>
    </div>
  </modal-sliding>
</template>

<script>
import ModalSliding from "@/components/Ui/Modals/ModalSliding.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";
import {errorToast, successToast} from "@/services/SystemService";
import {destroyAuth, updateSettings} from "@/services/Integration/HubriseService";
export default {
  components: {ModalSliding},
  data(){
    return {
      showAdvancedSettings: false,
      showIntegrationHistory: false,
      isMenuMaster: false,
      refOnsite: null,
      refPickup: null,
      refDelivery: null,
      refServiceFees: null,
      refDeliveryFees: null,
      refDiscount: null,
      refOnsitePayment: null,
      refOnlinePayment: null,
      isButtonLoading: false
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
    hubrise(){
      if(!this.currentMerchant) return false;
      if(!this.currentMerchant.integrations.some(e => e.type === 'hubrise')) return false;
      return this.currentMerchant.integrations.find(e => e.type === 'hubrise');
    }
  },
  methods:{
    async hubriseDestroy(){
      this.isButtonLoading = true;
      await destroyAuth(this.currentMerchant.id, this.hubrise.id);
      this.$store.commit('REMOVE_MERCHANT_INTEGRATION', this.hubrise.id);
      this.isButtonLoading = false;
      this.close();
    },

    save(){
      this.isButtonLoading = true;

      updateSettings(this.currentMerchant.id, {
        id: this.hubrise.id,
        isMenuMaster: this.isMenuMaster,
        refOnsite: this.refOnsite,
        refPickup: this.refPickup,
        refDelivery: this.refDelivery,
        refServiceFees: this.refServiceFees,
        refDeliveryFees: this.refDeliveryFees,
        refDiscount: this.refDiscount,
        refOnsitePayment: this.refOnsitePayment,
        refOnlinePayment: this.refOnlinePayment
      })
          .then(() => {
            if(!this.hubrise.isMenuMaster && this.isMenuMaster){
              localStorage.setItem('menu:aggregator:sync', (new Date()/1000)+30);
            }

            successToast('Paramètres enregistrés');
            this.close();
            this.$store.dispatch('getMerchantsAction');
            this.isButtonLoading = false;
          })
          .catch(e => {
            errorToast('Erreur lors de l\'enregistrement des paramètres...');
            this.isButtonLoading = false;
          });
    },
    close(){
      useVfm().closeAll();
    }
  },
  async mounted() {
    if(!this.currentMerchant) await this.$store.dispatch('getMerchantsAction');
    if(!this.hubrise){
      this.close();
      errorToast('HubRise est mal configuré, essayez de le supprimer puis de le réajouter.')
    }
    this.isMenuMaster = this.hubrise.isMenuMaster;
    this.refOnsite = this.hubrise.auth.refOnsite;
    this.refPickup = this.hubrise.auth.refPickup;
    this.refDelivery = this.hubrise.auth.refDelivery;
    this.refServiceFees = this.hubrise.auth.refServiceFees;
    this.refDeliveryFees = this.hubrise.auth.refDeliveryFees;
    this.refDiscount = this.hubrise.auth.refDiscount;
    this.refOnsitePayment = this.hubrise.auth.refOnsitePayment;
    this.refOnlinePayment = this.hubrise.auth.refOnlinePayment;
  }
}
</script>
