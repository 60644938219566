<template>
  <div class="page-block overflow-x-auto">
    <slot name="base">
      <div class="px-4 md:px-6 " :class="[noTitlePadding&&!title ? 'pt-0 md:pt-0' : 'py-3 md:py-5']">

        <div class="page-block--title" v-if="title" :class="{'pb-0 mb-0':noTitlePadding}">
          <span class="page-block--title-title">{{title}}</span>
          <div class="page-block--title-decoration md:block hidden"></div>
          <div class="flex-1 justify-end flex items-center">
            <slot name="title-right"></slot>
          </div>
        </div>

        <slot></slot>
      </div>
      <slot name="no-padding"></slot>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    noTitlePadding: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: false
    }
  }
}
</script>
