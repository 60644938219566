<template>
  <div v-if="selectedMenuItems.length" class="bg-primary flex items-center rounded-full h-9">
    <button @click="resetSelectedMenuItems" class="flex justify-center items-center border-r border-blue-500/50 px-3 py-1 gap-2 text-white h-full">
      <i class="material-icons text-base">close</i>
      <span class="text-sm">{{ $t('menu.fast_action.selected_product_title', {length: selectedMenuItems.length}) }}</span>
    </button>
    <menu-button custom-button icon="more_vert" icon-class="text-white">
      <template v-slot:custom-button>
        <button class="font-bold text-sm text-white px-3 py-1 flex items-center gap-1">
          <span>{{ $t('menu.fast_action.button_title') }}</span>
          {{canSelectedProductBeAvailable}}
          <i class="material-icons text-base">arrow_drop_down</i>
        </button>
      </template>
      <template v-slot:menu>
        <menu-button-item v-if="!DisableSelectedProductHighlight" icon-class="text-gray-600 text-xl" icon="star" :text="$t('menu.fast_action.highlighted')" @click="setProductHighlighted"/>
        <menu-button-item v-if="DisableSelectedProductHighlight" icon-class="text-gray-600 text-xl" icon="star" :text="$t('menu.fast_action.un_highlighted')" @click="setProductUnhighlighted"/>
        <menu-button-item icon-class="text-gray-600 text-xl" icon="rule" :text="$t('menu.fast_action.set_available_or_not')" @click="changeAvailability"/>
        <menu-button-item :disabled="DisableSelectedProductBeArchived" icon-class="text-gray-600 text-xl" icon="local_mall" :text="$t('menu.fast_action.set_available_recuperation')" @click="changeAvailabilityRecuperation"/>
        <menu-button-item icon-class="text-gray-600 text-xl" icon="edit" :text="$t('menu.fast_action.update_prices')" @click="changePrice"/>
        <menu-button-item icon-class="text-gray-600 text-xl" icon="edit" :text="$t('menu.fast_action.update_extras')" @click="changeExtra"/>
        <menu-button-item :disabled="currentMerchant.features.menuMinorEditionOnly" icon-class="text-gray-600 text-xl" icon="edit" :text="$t('menu.fast_action.update_vat')" @click="changeVat"/>
        <menu-button-item :disabled="currentMerchant.features.menuMinorEditionOnly" icon-class="text-gray-600 text-xl" icon="category" :text="$t('menu.fast_action.add_option')" @click="addOptions"/>
        <menu-button-item icon-class="text-gray-600 text-xl" icon="sync_alt" :text="$t('menu.fast_action.move_category')" @click="moveCategory"/>
        <menu-button-item :disabled="currentMerchant.features.menuMinorEditionOnly" icon-class="text-gray-600 text-xl" icon="content_copy" :text="$t('menu.fast_action.duplicate')" @click="duplicate"/>
      </template>
    </menu-button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Popper from "@/components/Ui/Action/Popper.vue";
import EventBus from "@/EventBus";
import {useModal} from "vue-final-modal";
import PriceUpdateModal from "@/components/Menu/Modals/PriceUpdateModal.vue";
import AvailabilityUpdateModal from "@/components/Menu/Modals/AvailabilityUpdateModal.vue";
import ExtraUpdateModal from "@/components/Menu/Modals/ExtraUpdateModal.vue";
import MoveCategoryModal from "@/components/Menu/Modals/MoveCategoryModal.vue";
import AddOptionModal from "@/components/Menu/Modals/AddOptionModal.vue";
import MenuButton from "@/components/Ui/Elements/MenuButton.vue";
import MenuButtonItem from "@/components/Ui/Elements/MenuButtonItem.vue";
import DuplicateProductSetQuantityModal from "@/components/Menu/Modals/DuplicateProductSetQuantityModal.vue";
import UpdateProductsVATModal from "@/components/Menu/Modals/UpdateProductsVATModal.vue";
import AvailabilityRecuperationUpdateModal from "@/components/Menu/Modals/AvailabilityRecuperationUpdateModal.vue";
import DuplicateProductConfirmationModal from "@/components/Menu/Modals/DuplicateProductConfirmationModal.vue";

export default {
  components: {Popper, MenuButton, MenuButtonItem},
  setup(){
    const DuplicateSetQuantityModal = useModal({component: DuplicateProductSetQuantityModal});
    const AvailabilityRecuperationUpdate = useModal({component: AvailabilityRecuperationUpdateModal});
    const PriceUpdate = useModal({component: PriceUpdateModal});
    const AvailabilityUpdate = useModal({component: AvailabilityUpdateModal});
    const ExtraUpdate = useModal({component: ExtraUpdateModal});
    const moveCategory = useModal({component: MoveCategoryModal});
    const addOption = useModal({component: AddOptionModal});
    const UpdateVatModal = useModal({component: UpdateProductsVATModal});
    const DuplicateConfirmationModal = useModal({component: DuplicateProductConfirmationModal});
    return {ExtraUpdate, PriceUpdate, moveCategory, addOption, UpdateVatModal, DuplicateConfirmationModal, AvailabilityUpdate, AvailabilityRecuperationUpdate, DuplicateSetQuantityModal}
  },
  computed: {
    ...mapGetters({
      selectedMenuItems: 'menu/selectedMenuItems',
      menuData: 'menu/menu',
      currentMerchant: 'currentMerchant',
    }),
    products(){
      return this.menuData.items.filter(item => this.selectedMenuItems.includes(item.id));
    },
    DisableSelectedProductBeAvailable(){
      return this.products.every(product => product.status === 'available');
    },
    DisableSelectedProductBeArchived(){
      return this.products.every(product => product.status === 'archived');
    },
    DisableSelectedProductHighlight(){
      return this.products.every(product => product.highlighted);
    },
  },
  methods: {
    setProductHighlighted() {
      if(this.DisableSelectedProductHighlight) return;

      this.$store.dispatch('menu/updateMenuItemBatchAction', {
        merchantId: this.currentMerchant.id,
        update: this.products.map(product => {
          return {
            id: product.id,
            highlighted: true,
          }
        })
      });
    },
    setProductUnhighlighted() {
      if(!this.DisableSelectedProductHighlight) return;

      this.$store.dispatch('menu/updateMenuItemBatchAction', {
        merchantId: this.currentMerchant.id,
        update: this.products.map(product => {
          return {
            id: product.id,
            highlighted: false,
          }
        })
      });
    },
    setAvailable() {
      if(this.DisableSelectedProductBeAvailable) return;

      this.$store.dispatch('menu/updateMenuItemBatchAction', {
        merchantId: this.currentMerchant.id,
        update: this.products.map(product => {
          return {
            id: product.id,
            status: 'available',
          }
        })
      });
    },
    duplicate(){
      this.DuplicateSetQuantityModal.open();
    },
    moveCategory(){
      this.moveCategory.open();
    },
    changeVat(){
      this.UpdateVatModal.open();
    },
    changeExtra(){
      this.ExtraUpdate.open();
    },
    changePrice(){
      this.PriceUpdate.open();
    },
    changeAvailability() {
      this.AvailabilityUpdate.patchOptions({attrs: {products: this.products}});
      this.AvailabilityUpdate.open();
    },
    changeAvailabilityRecuperation() {
      if(this.DisableSelectedProductBeArchived) return;
      this.AvailabilityRecuperationUpdate.patchOptions({attrs: {products: this.products}});
      this.AvailabilityRecuperationUpdate.open();
    },
    addOptions(){
      this.addOption.open();
    },
    resetSelectedMenuItems(){
      this.$store.commit('menu/SET_SELECTED_MENU_ITEMS', []);
      EventBus.emit('menu:category:update-category-check');
      this.menuData.categories.forEach((cat) => {
        EventBus.emit('menu:category-uncheck', cat);
      })
    }
  }
}
</script>
