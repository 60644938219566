<template>
  <tr>
    <td colspan="9" class="border-none">
      <div class="h-9 bg-gray-700 flex justify-between items-center text-white" :class="{'opacity-50':category.archived}">
        <div class="flex items-center flex-1">
          <div class="w-10 flex items-center justify-center mr-2">
            <input type="checkbox" v-if="isCheckboxDisplayable" v-model="checked" @change="categoryChecked" class="form-checkbox w-5 h-5 m-0">
          </div>
          <span class="font-bold text-sm">{{category.name}} {{category.archived ? '(Archivée)': ''}}</span>
        </div>
        <div class="bg-blue-600 mr-5 flex items-center gap-1 px-3 h-5 leading-none rounded-md text-sm" v-if="category.availability">
          <i class="material-icons text-sm">schedule</i>
          <span class="text-xs font-medium">{{$t('category_schedule.'+category.availability.type)}}</span>
        </div>
        <div class="flex items-center gap-2 pr-5">
          <button class="font-bold text-sm underline" @click="openCategoryUpdate">Modifier</button>
          •
          <button class="font-bold text-sm underline" @click="createProduct">Ajouter un produit</button>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import EventBus from "@/EventBus";
import {mapGetters} from "vuex";
import {useModal} from "vue-final-modal";
import MenuItemEditor from "@/views/Menu/MenuItemEditor.vue";
import MenuCategoryEditor from "@/views/Menu/MenuCategoryEditor.vue";
import {siteTour} from "@/services/TourService";

export default {
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  setup(){
    const productModal = useModal({component: MenuItemEditor});
    const categoryModal = useModal({component: MenuCategoryEditor});
    return {productModal, categoryModal}
  },
  data(){
    return {
      checked: false
    }
  },
  methods: {
    async createProduct(){
      await this.productModal.patchOptions({attrs: {category: this.category.id, item:null}});
      await this.productModal.open();
      siteTour('product_add');
    },
    async openCategoryUpdate(){
      await this.categoryModal.patchOptions({attrs: {categoryId: this.category.id}});
      await this.categoryModal.open();
    },
    categoryChecked(){
      if(this.checked){
        EventBus.emit('menu:category-checked', this.category);
      }
      else {
        EventBus.emit('menu:category-unchecked', this.category);
      }
    }
  },
  computed: {
    ...mapGetters({
      menuData: 'menu/menu',
      selectedMenuItems: 'menu/selectedMenuItems',
      archivedItemsVisibility: 'menu/archivedItemsVisibility'
    }),
    isCheckboxDisplayable(){
      return this.menuData.items.filter(i => this.category.itemIds.includes(i.uid)).filter(i => this.archivedItemsVisibility ? true : i.archived===false).length > 0;
    }
  },
  unmounted() {

  },
  mounted() {
    EventBus.on('menu:category-uncheck', (category) => {
      if(category.id === this.category.id){
        this.checked = false;
      }
    });
    EventBus.on('menu:category-check', (category) => {
      if(category.id === this.category.id){
        this.checked = true;
      }
    });
  }
}
</script>
