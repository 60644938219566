<template>
    <div class="aspect-[300/608] w-[300px] px-4 relative" >
        <div class="overflow-y-auto phone-scroll mt-3 rounded-t-xl pb-36 h-[95%] absolute w-[276px] left-[16px] bottom-0 top-0 right-0">
            <slot></slot>
        </div>
        <slot name="fixed"></slot>
        <div class="absolute w-full h-full bg-center bg-cover top-0 left-0 pointer-events-none" style="background-image: url('/images/iphone.png')"></div>
    </div>
</template>

<style>
.phone-scroll::-webkit-scrollbar-track
{
    border-radius: 3px;
    background-color: transparent;
}
.phone-scroll::-webkit-scrollbar
{
    width: 3px;
    height: 5px;
    background-color: transparent;
}
.phone-scroll::-webkit-scrollbar-thumb
{
    border-radius: 3px;
    background-color: rgba(0,0,0,.3);
}
</style>
