<template>

  <Popper ref="popper">
    <template v-slot:action>
      <div class="items-center hidden sm:flex relative">
        <div class="w-0 flex justify-start z-50"><i class="ml-2 material-icons text-gray-500">search</i></div>
        <input type="text" v-model="search"
               id="merchant-search"
               @keydown.down.prevent="changeIndex(1)"
               @keydown.up.prevent="changeIndex(-1)"
               @keydown.enter.prevent="openSelectedResult"
               class="form-input bg-gray-50 pl-10 h-9 w-[350px]" :placeholder="$t('cta.search_placeholder')">

        <div class="flex items-center justify-center text-gray-500 absolute right-2 opacity-80">
          <div class="bg-gray-200/70 px-2 text-xs leading-0 rounded-md">CTRL</div>
          <span class="text-sm">+</span>
          <div class="bg-gray-200/70 px-2 text-xs leading-0 rounded-md">K</div>
        </div>

      </div>
    </template>
    <div>
      <div class="w-[350px] rounded-md mt-1 bg-white border border-gray-300 z-100 shadow-2xl" v-if="results.length>0">
        <div class="border-b flex items-center justify-start last:border-0 w-full h-11 px-3" :class="{'bg-primary/5': i===resultIndex}" v-for="(result,i) in results">
          <i class="material-icons mr-3 text-xl text-primary">{{result.item.icon}}</i>
          <span class="font-medium">{{result.item.title}}</span>
        </div>
      </div>
    </div>
  </Popper>

</template>


<script>
import Popper from "@/components/Ui/Action/Popper.vue";
import Fuse from 'fuse.js';
import searchData from "@/search.json";
import { useMagicKeys } from '@vueuse/core'


import {ref, watch} from "vue";
export default {
  setup(){
    const popper = ref(null);
    return {popper}
  },
  components: {Popper},
  data(){
    return {
      search: '',
      resultIndex: 0
    }
  },
  computed: {
    results(){
      const fuseActions = new Fuse(searchData, {threshold: 0.3, includeScore: true, minMatchCharLength:0, distance: 35, keys: ['title', 'tags']});
      return fuseActions.search(this.search).sort((a,b)=>a.score-b.score).slice(0,10);
    },
  },
  methods: {
    changeIndex(value){
      if(this.resultIndex+value>=0 && this.resultIndex+value<this.results.length){
        this.resultIndex+=value;
      }
    },
    openSelectedResult(){
      if(this.results[this.resultIndex]){
        if(this.results[this.resultIndex].item.action.type === 'link'){
          this.$router.push(this.results[this.resultIndex].item.action.url);
        }
      }
      this.popper.close();
      this.search = '';
    }
  },
  watch: {
    search(search){
      if(search){
        this.popper.open();
      }
      else{
        this.popper.close();
      }
    }
  },
  mounted() {
    document.onkeydown = (e) => {
      if (e.key === "k" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault(); // present "Save Page" from getting triggered.
        document.getElementById('merchant-search').focus();
        this.popper.open();
      }
    };

  }
}
</script>
