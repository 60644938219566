import axios from "@/axios-instance";
import {
    attachModifierGroupBatch, createCategory, createItem,
    createModifierGroupBatch, duplicateModifierGroupBatch,
    deleteModifierBatch, deleteModifierGroupBatch, detachModifierGroup, duplicateItem,
    getMerchantMenu,
    moveProductCategories, moveTableCursor, updateCategoryBatch,
    updateItemBatch,
    updateModifierBatch,
    updateModifierGroupBatch, updateSorts
} from "@/services/MenuService";
import EventBus from "@/EventBus";

export default {
    namespaced: true,
    state: {
        menu: null,
        tableCursor: null,
        archivedItemsVisibility: false,
        selectedMenuItems: [],
        isMenuEditionLoading: false,
        isMenuCurrentlySync: false,
    },
    mutations: {
        SET_MENU_CURRENTLY_SYNC(state, isSync){
            state.isMenuCurrentlySync = isSync;
        },
        CURRENT_TABLE_CURSOR(state, cursor) {
            state.tableCursor = cursor;
        },
        SET_MENU_ITEM_HIGHLIGHT(state, {itemId, highlight}) {
            const index = state.menu.items.findIndex(i => i.id === itemId);
            state.menu.items[index].highlighted = highlight;
        },
        SET_MENU_EDITION_LOADING(state, isLoading){
            state.isMenuEditionLoading = isLoading;
        },
        SET_SELECTED_MENU_ITEMS(state, items){
            state.selectedMenuItems = items;
        },
        ADD_SELECTED_MENU_ITEM(state, item){
            state.selectedMenuItems.push(...item);
        },
        REMOVE_SELECTED_MENU_ITEM(state, item){
            state.selectedMenuItems=state.selectedMenuItems.filter(i => i !== item);
        },
        DELETE_ITEM_FROM_CATEGORY(state, {categoryId, itemId}){
            const category = state.menu.categories.find(c => c.id === categoryId);
            category.itemIds = category.itemIds.filter(i => i !== itemId);
        },
        UPDATE_ITEM_SORT(state, {itemId, sort}){
            state.menu.items.find(i => i.id === itemId).sort = sort;
        },
        UPDATE_CATEGORY_SORTS(state, {categoryId, sort, itemIds}){
            const cat = state.menu.categories.findIndex(i => i.id === categoryId);
            state.menu.categories[cat].itemIds = itemIds;
            state.menu.categories[cat].sort = sort;
        },
        SET_ARCHIVED_ITEM_VISIBILITY(state, visibility){
            state.archivedItemsVisibility = visibility;
        },
        SET_MENU(state, menu) {
            state.menu = menu;
        },
        UPDATE_MENU_ITEM(state, item) {
            const index = state.menu.items.findIndex(i => i.id === item.id);
            if(index===-1) return;
            state.menu.items[index] = item;
        },
        UPDATE_MENU_CATEGORY(state, item) {
            const index = state.menu.categories.findIndex(i => i.id === item.id);
            if(index===-1) return;
            state.menu.categories[index].name = item.name;
        },
        UPDATE_MENU_CATEGORY_FORCE(state, item) {
            const index = state.menu.categories.findIndex(i => i.id === item.id);
            if(index===-1) return;
            state.menu.categories[index] = item;
        },
        ADD_PRODUCT_IN_MENU_CATEGORY(state, {categoryId, itemId}) {
            const index = state.menu.categories.findIndex(i => i.id === categoryId);
            if(index===-1) return;
            state.menu.categories[index].itemIds.push(itemId);
        },
        UPDATE_MENU_CATEGORY_ITEMS(state, item) {
            const categoryIndex = state.menu.categories.findIndex(i => i.id === item.id)
            state.menu.categories[categoryIndex].itemIds = item.itemIds;
        },
        ADD_MENU_CATEGORY(state, {category, sort}){
            state.menu.categories.splice(sort, 0, category);
        },
        MOVE_CATEGORY_SORT(state, {categoryId, sort}){
            const category = state.menu.categories.find(i => i.id === categoryId);
            state.menu.categories.splice(state.menu.categories.indexOf(category), 1);
            state.menu.categories.splice(sort, 0, category);

            state.menu.categories
                .filter(c => c.archived===false)
                .forEach((category, index) => {
                    category.sort = index;
                });
        },
        MENU_CATEGORY_SET_ARCHIVED(state, {categoryId, archived}){
            const index = state.menu.categories.findIndex(e => e.id === categoryId)
            state.menu.categories[index].archived = archived;
        },
        PUSH_MENU_MODIFIER_GROUP(state, item) {
            state.menu.modifierGroups.push(item);
        },
        PUSH_MENU_ITEM(state, item) {
            state.menu.items.push(item);
        },
        DELETE_MENU_MODIFIER_GROUP(state, modifierGroupId) {
            state.menu.modifierGroups.splice(state.menu.modifierGroups.findIndex(i => i.id === modifierGroupId), 1);
        },
        UPDATE_MENU_MODIFIER(state, item) {
            const index = state.menu.modifiers.findIndex(i => i.id === item.id);
            if(index !== -1)
                state.menu.modifiers.splice(index, 1, item);
            else
                state.menu.modifiers.push(item);
        },
        UPDATE_MENU_MODIFIER_GROUP(state, item) {
            state.menu.modifierGroups.splice(state.menu.modifierGroups.findIndex(i => i.id === item.id), 1, item);
        },
        MODIFIER_GROUP_UPDATE_ITEMS(state, {modifierGroupId, uid}){
            const modifierGroupIndex = state.menu.modifierGroups.findIndex(i => i.id === modifierGroupId);
            if(!state.menu.modifierGroups[modifierGroupIndex].itemIds.includes(uid)){
                state.menu.modifierGroups[modifierGroupIndex].itemIds.push(uid);
            }
        },
        DELETE_MENU_MODIFIER(state, modifierId) {
            state.menu.modifiers.splice(state.menu.modifiers.findIndex(i => i.id === modifierId), 1);
        },
        UPDATE_ITEM_ARCHIVE_STATUS(state, {itemId, isArchived}) {
            state.menu.items = state.menu.items.map((item) => {
                if (item.id === itemId)
                    item.archived = isArchived;
                return item;
            });
        },
        MENU_MODIFIER_SET_PRODUCT_ID(state, {modifierId, productId}) {
            const index = state.menu.modifiers.findIndex(i => i.id === modifierId);
            if(index !== -1){
                state.menu.modifiers[index].parentProductId = productId;
            }
        }
    },
    getters: {
        menu: state => state.menu,
        archivedItemsVisibility: state => state.archivedItemsVisibility,
        selectedMenuItems: state => state.selectedMenuItems,
        isMenuEditionLoading: state => state.isMenuEditionLoading,
        isMenuCurrentlySync: state => state.isMenuCurrentlySync
    },
    actions: {
        async moveTableCursor({commit, state}, {key}){
            const cursor = moveTableCursor(key, state.tableCursor, state.menu, state.archivedItemsVisibility);
            commit('CURRENT_TABLE_CURSOR', cursor);
            return cursor;
        },
        setCurrentTableCursor({commit}, {cursor}){
            commit('CURRENT_TABLE_CURSOR', cursor);
        },

        async getMerchantMenuAction({ commit }, {merchantId}) {
            const menu = await getMerchantMenu(merchantId);
            commit('SET_MENU', menu);
        },

        async duplicateProduct({commit}, {merchantId, productId, quantity}){
            commit('SET_MENU_EDITION_LOADING', true);
            const result = await duplicateItem(merchantId, productId, quantity);
            result.items.forEach((product) => {
                commit('PUSH_MENU_ITEM', product);
            });
            result.categories.forEach((category) => {
                commit('UPDATE_MENU_CATEGORY_FORCE', category);
                EventBus.emit('menu:category:updated', category.id);
            });
            commit('SET_MENU_EDITION_LOADING', false);
        },

        async updateMenuSort({commit, state}, {merchantId, categories, items}){
            // @todo update menu sort locally
            items.forEach((item) => {
                commit('UPDATE_ITEM_SORT', {itemId: item.id, sort: item.sort})
            });
            categories.forEach((category) => {
                commit('UPDATE_CATEGORY_SORTS', {categoryId: category.id, sort: category.sort, itemIds: category.itemIds})
                EventBus.emit('menu:category:updated', category.id);
            });
            // @todo update menu sort on server
            await updateSorts(merchantId, items, categories);
        },


        async createMenuItemAction({commit, dispatch}, {merchantId, data}){
            commit('SET_MENU_EDITION_LOADING', true);
            const result = await createItem(merchantId, data);
            result.items.forEach((product) => {
                commit('PUSH_MENU_ITEM', product);
                EventBus.emit('menu:product:created', product.id);
            });
            result.categories.forEach((category) => {
                commit('UPDATE_MENU_CATEGORY_FORCE', category);
                dispatch('updateProductSortAfterCategoryUpdate', {categoryUid:category.uid});
            });
            commit('SET_MENU_EDITION_LOADING', false);
            commit('SET_MERCHANT_ONBOARDING_VALUE', {type:'menu', value: true}, {root: true});
        },


        async updateProductSortAfterCategoryUpdate({commit,state}, {categoryUid}){
            const category = state.menu.categories.find(c => c.uid === categoryUid);
            const items = state.menu.items.filter(p => category.itemIds.includes(p.uid) && p.archived===false);
            let index = 0;
            for(const itemUid of category.itemIds){
                const item = items.find(i => i.uid === itemUid);
                if(item){
                    commit('UPDATE_ITEM_SORT', {itemId: item.id, sort: index});
                    index++;
                }
            }
        },

        async updateMenuItemBatchAction({commit, dispatch, rootGetters, state}, {merchantId, update, categoryForce=false}) {
            commit('SET_MENU_EDITION_LOADING', true);
            const result = await updateItemBatch(merchantId, update);

            // if product change category
            for(const item of update){
                if(item.oldCategory){
                    console.log('> remove from old category');
                    commit('DELETE_ITEM_FROM_CATEGORY', {categoryId: item.oldCategory, itemId: item.uid});
                }
            }

            result.categories.forEach((category) => {
                if(categoryForce){
                    commit('UPDATE_MENU_CATEGORY_FORCE', category);
                }
                else{
                    commit('UPDATE_MENU_CATEGORY', category);
                }
            });

            result.items.forEach((product) => {
                const currentItem = state.menu.items.find(i => i.id === product.id);
                if(currentItem.unavailable !== product.unavailable){
                    commit('ADD_SIDEBAR_UNAVAILABLE_PRODUCT', product.unavailable ? 1 : -1, {root: true});
                    console.log( product.unavailable ? 1 : -1 );
                }
                commit('UPDATE_MENU_ITEM', product);
                EventBus.emit('menu:product:updated', product.id);
            });
            result.modifiers.forEach((modifier) => {
                commit('UPDATE_MENU_MODIFIER', modifier);
            });
            commit('SET_MENU_EDITION_LOADING', false);
            commit('SET_MERCHANT_ONBOARDING_VALUE', {type:'menu', value: state.menu.items && state.menu.items.some(i => i.archived===false)}, {root: true});
            result.categories.forEach((category) => {
                dispatch('updateProductSortAfterCategoryUpdate', {categoryUid:category.uid});
            });
        },

        async moveProductInCategoryAction({commit, rootGetters}, {merchantId, categoryId, productIds}) {
            commit('SET_MENU_EDITION_LOADING', true);
            const categories = await moveProductCategories(merchantId, categoryId, productIds);
            categories.forEach((category) => {
                commit('UPDATE_MENU_CATEGORY_FORCE', category);
                EventBus.emit('menu:category:updated', category.id);
            });
            commit('SET_MENU_EDITION_LOADING', false);
        },

        async updateMenuModifierBatchAction({commit, state}, {merchantId, modifierGroupId, update}) {
            commit('SET_MENU_EDITION_LOADING', true);
            const {modifiers, items} = await updateModifierBatch(merchantId, modifierGroupId, update);
            // Update items
            items.forEach((product) => {
                const currentItem = state.menu.items.find(i => i.id === product.id);
                if(currentItem.unavailable !== product.unavailable){
                    commit('ADD_SIDEBAR_UNAVAILABLE_PRODUCT', product.unavailable ? 1 : -1, {root: true});
                    console.log( product.unavailable ? 1 : -1 );
                }
                commit('UPDATE_MENU_ITEM', product);
                EventBus.emit('menu:product:updated', product.id);
            });
            // Update modifiers
            modifiers.forEach((modifier) => {
                commit('UPDATE_MENU_MODIFIER', modifier);
                commit('MODIFIER_GROUP_UPDATE_ITEMS', {modifierGroupId, uid: modifier.uid});
                EventBus.emit('menu:modifier:updated', modifier.id);
            });
            commit('SET_MENU_EDITION_LOADING', false);
        },

        async updateMenuModifierGroupBatchAction({commit, rootGetters}, {merchantId, update}) {
            commit('SET_MENU_EDITION_LOADING', true);
            const modifierGroups = await updateModifierGroupBatch(merchantId, update);
            modifierGroups.forEach((modifierGroup) => {
                commit('UPDATE_MENU_MODIFIER_GROUP', modifierGroup);
                EventBus.emit('menu:modifier-group:updated', modifierGroup.id);
            });
            commit('SET_MENU_EDITION_LOADING', false);
        },


        async createMenuModifierGroupAction({commit, rootGetters}, {merchantId, name, min, max, quantitySelector, sort}) {
            commit('SET_MENU_EDITION_LOADING', true);
            const modifierGroup = await createModifierGroupBatch(merchantId, name, min, max, quantitySelector, sort);
            commit('PUSH_MENU_MODIFIER_GROUP', modifierGroup);
            EventBus.emit('menu:modifier-group:updated', modifierGroup.id);
            commit('SET_MENU_EDITION_LOADING', false);
            return modifierGroup;
        },

        async deleteMenuModifierBatchAction({commit}, {merchantId, modifierIds}) {
            commit('SET_MENU_EDITION_LOADING', true);
            for(const modifierId of modifierIds){
                commit('DELETE_MENU_MODIFIER', modifierId);
                EventBus.emit('menu:modifier:updated', modifierId);
            }
            await deleteModifierBatch(merchantId, modifierIds);
            commit('SET_MENU_EDITION_LOADING', false);
        },

        async duplicateMenuModifierGroupBatchAction({commit}, {merchantId, modifierGroupIds}) {
            commit('SET_MENU_EDITION_LOADING', true);
            const {modifierGroups, modifiers} = await duplicateModifierGroupBatch(merchantId, modifierGroupIds);
            for(const modifierGroup of modifierGroups){
                commit('PUSH_MENU_MODIFIER_GROUP', modifierGroup);
                EventBus.emit('menu:modifier-group:updated', modifierGroup.id);
            }
            modifiers.forEach((modifier) => {
              commit('UPDATE_MENU_MODIFIER', modifier);
              EventBus.emit('menu:modifier:updated', modifier.id);
          });

            commit('SET_MENU_EDITION_LOADING', false);
        },

        async deleteMenuModifierGroupBatchAction({commit, state}, {merchantId, modifierGroupIds}) {
            commit('SET_MENU_EDITION_LOADING', true);
            for(const modifierGroupId of modifierGroupIds){
                const uid = state.menu.modifierGroups.find(i => i.id === modifierGroupId).uid;

                commit('DELETE_MENU_MODIFIER_GROUP', modifierGroupId);
                EventBus.emit('menu:modifier-group:updated', modifierGroupId);
                EventBus.emit('menu:modifier-group:deleted-uid', uid);
            }
            await deleteModifierGroupBatch(merchantId, modifierGroupIds);
            commit('SET_MENU_EDITION_LOADING', false);
        },

        async attachModifierGroupsBatchAction({commit}, {merchantId, modifierGroupIds, productIds, absolute=false}){
            commit('SET_MENU_EDITION_LOADING', true);
            const products = await attachModifierGroupBatch(merchantId, modifierGroupIds, productIds, absolute);
            products.forEach((product) => {
                commit('UPDATE_MENU_ITEM', product);
                EventBus.emit('menu:product:updated', product.id);
            });
            commit('SET_MENU_EDITION_LOADING', false);
        },
        async detachModifierGroupAction({commit}, {merchantId, modifierGroupId, productId}){
            commit('SET_MENU_EDITION_LOADING', true);
            const products = await detachModifierGroup(merchantId, modifierGroupId, productId);
            products.forEach((product) => {
                commit('UPDATE_MENU_ITEM', product);
                EventBus.emit('menu:product:updated', product.id);
            });
            commit('SET_MENU_EDITION_LOADING', false);
        },

        async updateMenuCategoryBatchAction({commit}, {merchantId, update}){
            commit('SET_MENU_EDITION_LOADING', true);
            update.forEach((u) => {
                commit('MOVE_CATEGORY_SORT', {categoryId: u.id, sort: u.sort})
            });
            const categories = await updateCategoryBatch(merchantId, update);
            categories.forEach((category) => {
                commit('UPDATE_MENU_CATEGORY_FORCE', category);
                EventBus.emit('menu:category:updated', category.id);
            });
            commit('SET_MENU_EDITION_LOADING', false);
        },


        async createCategoryAction({commit}, {merchantId, name, description, sort, availability, availabilityType, availabilityBetweenTwoDates, availabilityDateOnly}){
            commit('SET_MENU_EDITION_LOADING', true);
            const categories = await createCategory(merchantId, name, description, sort, availability, availabilityType, availabilityBetweenTwoDates, availabilityDateOnly);
            commit('ADD_MENU_CATEGORY', {category:categories[0], sort});
            commit('SET_MENU_EDITION_LOADING', false);
        },
        async setArchiveStatusCategoryAction({commit}, {merchantId, categoryId, archived}){
            commit('SET_MENU_EDITION_LOADING', true);
            await updateCategoryBatch(merchantId, [{
                id: categoryId,
                archived
            }]);
            commit('MENU_CATEGORY_SET_ARCHIVED', {categoryId, archived});
            commit('SET_MENU_EDITION_LOADING', false);
        }
    }
}
