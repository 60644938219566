import {getCurrentAccount} from "@/services/AccountService";

export default {
    namespaced: true,
    state: {
        account: null,
    },
    mutations: {
        SET_ACCOUNT(state, account) {
            state.account = account;
        }
    },
    getters: {
        account: state => state.account
    },
    actions: {
        async getAccount({ commit }) {
            const data = await getCurrentAccount();
            commit('SET_ACCOUNT', data);
            $crisp.push(["set", "user:email", [data.email]]);
            $crisp.push(["set", "user:nickname", [data.name]]);
            return data;
        },
    }
}
