import axios from "@/axios-instance";

export default {
    state: {
        isAuthed: false
    },
    mutations: {
        SET_AUTH_STATUS(state, status){
            state.isAuthed = status
        }
    },
    getters: {
        isAuthed: state => state.isAuthed
    },
    actions: {
        initAuth({commit}){
            commit('SET_AUTH_STATUS', !!localStorage.getItem('access_token'));
        },

        loginTokenAction({commit}, {token}){
            return new Promise(((resolve, reject) => {
                axios.post(import.meta.env.VITE_AUTH_URL+'/v1/oauth/token', {
                    grant_type: 'admin_token',
                    token,
                    client_type: 'delicity:merchant:panel'
                })
                    .then((response) => {
                        localStorage.setItem('access_token', response.data.access_token);
                        localStorage.setItem('refresh_token', response.data.refresh_token);
                        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        loginAction({commit}, {username, password}){
            return new Promise(((resolve, reject) => {
                axios.post(import.meta.env.VITE_AUTH_URL+'/v1/oauth/token', {
                    grant_type: 'password',
                    username,
                    password,
                    client_type: 'delicity:merchant:panel'
                })
                    .then((response) => {
                        localStorage.setItem('access_token', response.data.access_token);
                        localStorage.setItem('refresh_token', response.data.refresh_token);
                        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },

        refreshToken({commit, dispatch}, {refresh_token}){
            return new Promise(((resolve, reject) => {
                axios.post(import.meta.env.VITE_AUTH_URL+'/v1/oauth/token', {
                    grant_type: 'refresh_token',
                    refresh_token,
                    client_type: 'delicity:merchant:panel'
                })
                    .then((response) => {
                        localStorage.setItem('access_token', response.data.access_token);
                        localStorage.setItem('refresh_token', response.data.refresh_token);
                        axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
                        resolve(response.data);
                    })
                    .catch(async (error) => {
                        if(error.response && error.response.data && error.response.data.message === 'refresh_token_not_found'){
                            await dispatch('logoutAction').catch(e => console.log(e));
                            location.reload();
                        }
                        reject(error);
                    })
            }));
        },
        introspectAction(){
            return new Promise(((resolve, reject) => {
                axios.get(import.meta.env.VITE_AUTH_URL+'/v1/oauth/introspect')
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    })
            }));
        },
        logoutAction(){
            return new Promise(((resolve, reject) => {
                axios.post(import.meta.env.VITE_AUTH_URL+'/v1/oauth/logout', {client_type: 'delicity:merchant:panel'})
                    .then((response) => {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');
                        axios.defaults.headers.common.Authorization = null;
                        resolve(response.data);
                    })
                    .catch((error) => {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('refresh_token');
                        axios.defaults.headers.common.Authorization = null;
                        reject(error);
                    })
            }));
        },
    }
}
