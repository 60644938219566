import {DateTime} from "luxon";
import {formatDuration} from "@/services/FormatService";

export function formatTimestampToHuman(timestamp){
    return DateTime.fromSeconds(timestamp)
        .setLocale("fr")
        .toFormat("EEE d MMM 'à' HH:mm");
}

export function formatIsoToHuman(timestamp){
    return DateTime.fromISO(timestamp)
        .setLocale("fr")
        .toFormat("EEE d MMM 'à' HH:mm");
}

export function getHoursFromTimestamp(timestamp){
    return DateTime.fromSeconds(timestamp)
        .setLocale("fr")
        .toFormat("HH:mm");
}

export function getHoursFromIso(timestamp){
    return DateTime.fromISO(timestamp)
        .setLocale("fr")
        .toFormat("HH:mm");
}

export function getDateTimeFromISO(iso){
    return DateTime.fromISO(iso)
        .setLocale("fr")
}

export function getTotalOrderDurationWithSteps(steps){
    if(steps.some(s => s.status === 'delivered')){
        let start
        if(steps.some(s => s.status === 'preorder') && steps.some(s => s.status === 'preparation')){
            start = DateTime.fromISO(steps.find(s => s.status === 'preparation').time);
        }
        else{
            start = DateTime.fromISO(steps[0].time);
        }
        let end = DateTime.fromISO(steps[steps.length-1].time);
        let diff = end.diff(start, ['hours', 'minutes']);
        if(diff.hours > 0){
            return diff.hours + 'h' + Math.round(diff.minutes);
        }
        else{
            return Math.round(diff.minutes) + 'min';
        }
    }
    else{
        return null;
    }
}

export function getPreparationDurationWithAllSteps(steps){

    let firstPreparationStep = steps.find(s => s.status === 'preparation');
    if(!firstPreparationStep){
        firstPreparationStep = steps.find(s => s.status === 'taken');
    }
    if(!firstPreparationStep){
        return 'N/a'
    }

    let lastPreparationStep = steps.filter(s => s.status === 'preparation_done').sort((a, b) => b.time - a.time)[0];
    if(!lastPreparationStep){
        lastPreparationStep = steps.filter(s => s.status === "delivery_ongoing").sort((a, b) => b.time - a.time)[0];
    }
    if(!lastPreparationStep){
        return 'N/a'
    }

    const start = DateTime.fromISO(firstPreparationStep.time);
    const end = DateTime.fromISO(lastPreparationStep.time);
    return formatDuration(end.diff(start).toFormat('m'))
}
