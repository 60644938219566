<template>
  <div class="page-block h-full py-4 px-6 flex flex-col justify-end">
    <div class="flex flex-col min-h-[3rem]">
      <span class="font-bold leading-tight line-clamp-2">{{title}}</span>
      <span class="text-gray-500 mt-1 text-sm leading-tight" v-if="subtitle">{{subtitle}}</span>
    </div>

    <template v-if="!isLoading">
      <div class="flex-1 flex flex-col items-end justify-end mt-1">
        <span :class="[valueColor]" class="font-bold text-[2.2rem] xl:text-[2.5em]">{{value}}</span>
      </div>
      <div class="h-5 mt-3" v-if="showEvolution">
        <div class="flex justify-end" v-if="evolution!==null && !evolutionInverted">
          <div class="bg-[#70C32E]/30 text-green-700 font-bold px-3 text-sm rounded-full flex items-center h-5" v-if="evolution>0"><i class="material-icons text-sm mr-1">trending_up</i>{{evolution}}%</div>
          <div class="bg-red-600 text-white px-3 text-sm rounded-full flex items-center h-5" v-else-if="evolution<0"><i class="material-icons text-sm mr-1">trending_down</i>{{evolution}}%</div>
          <div class="bg-blue-100 text-black px-3 text-sm rounded-full flex items-center h-5" v-else-if="evolution===0"><i class="material-icons text-sm mr-1">trending_flat</i>{{evolution}}%</div>
        </div>
        <div class="flex justify-end" v-else-if="evolution!==null && evolutionInverted">
          <div class="bg-red-600 text-white  px-3 text-sm rounded-full flex items-center h-5" v-if="evolution>0"><i class="material-icons text-sm mr-1">trending_up</i>{{evolution}}%</div>
          <div class="bg-[#70C32E]/30 text-green-700 font-bold px-3 text-sm rounded-full flex items-center h-5" v-else-if="evolution<0"><i class="material-icons text-sm mr-1">trending_down</i>{{evolution}}%</div>
          <div class="bg-blue-100 text-black px-3 text-sm rounded-full flex items-center h-5" v-else-if="evolution===0"><i class="material-icons text-sm mr-1">trending_flat</i>{{evolution}}%</div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="flex-1 flex flex-col items-end justify-end mt-4">
        <div class="h-12 w-36 animate-pulse bg-slate-200/80 rounded-md"></div>
      </div>
      <div class="mt-3 flex justify-end">
        <div class="h-5 w-14 animate-pulse bg-slate-200/80 rounded-md"></div>
      </div>
    </template>


  </div>
</template>

<script>
export default {
  props: {
    isLoading:{
      default: false,
      type: Boolean
    },
    value: {
      required: false
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    evolution: {
      type: Number,
      required: false,
      default: null
    },
    evolutionInverted: {
      type: Boolean,
      default: false
    },
    showEvolution: {
      type: Boolean,
      default: false
    },
    valueColor: {
      type: String,
      required: false,
      default: 'text-primary'
    }
  }
}
</script>
