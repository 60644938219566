<template>
  <div ref="popperLocation" class="action-popper" @click="toggleOpen">
    <slot name="action"></slot>
  </div>
  <Teleport to="body">

    <transition
        enter-active-class="transition duration-75 ease-out"
        enter-from-class="transform scale-95 -translate-y-5 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 -translate-y-5 opacity-0">
      <div class="fixed z-[999999]"  v-show="isOpen" ref="popperContent" :style="{'left': location.left+'px', 'top': location.top+'px'}">
        <slot></slot>
      </div>
    </transition>

  </Teleport>
</template>

<script>
import {ref} from "vue";
import {onClickOutside} from "@vueuse/core";

export default {
  setup(){
    const popperLocation = ref()
    const popperContent = ref()
    return {popperLocation, popperContent}
  },
  data(){
    return{
      location: {left:0, top:0},
      popperWidth: 0,
      isOpen: false
    }
  },
  methods: {
    sync(){
      if(!this.isOpen){
        return;
      }
      this.location = this.getOffset(this.popperLocation);
      const elementHeight =this.popperLocation.offsetHeight;
      const offsetWidth =this.popperLocation.offsetWidth

      this.popperWidth = this.popperContent.offsetWidth;
      this.location.left = this.location.left - (this.popperWidth/2) + (offsetWidth/2);
      this.location.top = this.location.top + elementHeight;
    },
    open(){
      this.isOpen = true;
      setTimeout(() => {
        this.sync();
      }, 20);
    },
    close(){
      this.isOpen = false;
    },
    toggleOpen(){
      this.isOpen = !this.isOpen;
      if(this.isOpen){
        setTimeout(() => {
          this.sync();
        }, 20);
      }
    },
    getOffset(el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
      };
    }
  },
  mounted() {
    this.sync();
    addEventListener("resize", () => {
      this.sync();
    });
    onClickOutside(this.popperContent, (e) => {
      if(this.isOpen){
        setTimeout(() => {
          if(this.isOpen){
            this.toggleOpen();
          }
        }, 50);
      }
    });
  }
}
</script>
