import axios from "@/axios-instance";

export async function getCurrentAccount(merchantId, callback){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/integrations/hubrise/get_auth_url?callback=${callback}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function validateAuth(merchantId, code){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/integrations/hubrise/validation`, {code})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function destroyAuth(merchantId, id){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/integrations/hubrise/destroy`, {id})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function updateSettings(merchantId, data){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/integrations/hubrise/settings`, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
