<template>
  <div class="pl-2 pr-2 h-9 bg-[#E8F1E1] text-green-900 flex items-center w-[fit-content] gap-2 rounded-full cursor-pointer" v-if="confirmed" @click.prevent="openPreorderConfirm">
    <i class="material-icons text-xl">check_circle</i>
    <span class="font-semibold">Confirmée</span>
    <div v-if="ringAt" class="text-xs bg-gray-800 gap-1 flex items-center text-white px-2 h-6 rounded-full font-bold">
      <i class="material-icons text-xs">notifications</i>
      {{ getDateTimeFromISO(ringAt).toFormat('HH:mm') }}
    </div>
  </div>

  <div class="pr-1 pl-3 w-[10.5rem] py-1 h-9 border-2 border-orange-300 flex items-center justify-between rounded-full" v-else>
    <span class="font-semibold text-sm mr-2 text-gray-900">Confirmer ?</span>
    <div class="flex items-center">
      <i class="material-icons text-[#FF0000] cursor-pointer" @click.prevent="openCancelOrder">cancel</i>
      <i class="material-icons text-[#70C32E] cursor-pointer" @click.prevent="openPreorderConfirm">check_circle</i>
    </div>
  </div>
</template>

<script>
import {getDateTimeFromISO, getHoursFromTimestamp} from "@/services/TimeService";
import {useModal} from "vue-final-modal";
import PreorderConfirm from "@/components/Order/Modal/PreorderConfirm.vue";
import CancelOrder from "@/components/Order/Modal/CancelOrder.vue";
import {DateTime} from "luxon";
export default {
  setup(){
    const preorderConfirm = useModal({component: PreorderConfirm});
    const cancelOrder = useModal({component: CancelOrder});
    return {getHoursFromTimestamp, preorderConfirm, cancelOrder}
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    confirmed: {
      type: Boolean,
      default: false
    },
    ringAt: {
      default: null
    }
  },
  methods: {
    getDateTimeFromISO,
    openPreorderConfirm(){
      this.preorderConfirm.patchOptions({attrs: {
          orderId: this.order.id,
          orderRingAt: DateTime.fromISO(this.order.preorderRingAt),
          preorderFor: DateTime.fromISO(this.order.preorderFor),
          preorderConfirmed: !!this.order.preorderConfirmedAt
      }});
      this.preorderConfirm.open();
    },
    openCancelOrder(){
      this.cancelOrder.patchOptions({attrs: {orderId: this.order.id}});
      this.cancelOrder.open();
    }
  }
}
</script>
