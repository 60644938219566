<template>
        <div class="mt-8 bg-white gap-4 rounded-xl">
          <div class="py-4 px-6 flex items-center w-full">
            <button class="h-10 w-10 bg-gray-100 flex items-center justify-center rounded-xl" @click="showIntegrationHistory = !showIntegrationHistory">
              <i class="material-icons">{{ !showIntegrationHistory ? 'expand_more' : 'expand_less' }}</i>
            </button>
            <div class="flex-col flex ml-6 flex-1">
              <span class="text-xl font-bold">Historique de syncronisation du menu ({{data.length}})</span>
            </div>
            <button class="btn bg-primary text-white" @click="syncMenu" v-if="hubrise.isMenuMaster" :disabled="isSynchronisationLoading">Lancer une syncronisation</button>
          </div>
          <div class="w-full border-t" v-if="showIntegrationHistory">
            <table class="page-table bg-white">
              <tr>
                <th>#</th>
                <th>Sens</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
              <tr v-for="d in data">
                <td><small>{{d.id}}</small></td>
                <td>
                  <span class="capitalize" v-if="d.type === 'from_aggregator'">{{d.aggregator_type}} -> Delicity</span>
                  <span class="capitalize" v-if="d.type === 'from_platform'">Delicity -> {{d.aggregator_type}}</span>
                </td>
                <td>
                  <span v-if="d.status === 'success'">OK</span>
                  <span v-else>Erreur ({{d.status}})</span>
                </td>
                <td>
                  <span>{{formatDate(d.created_at, 'dd/MM/yyyy HH:mm')}} <small class="ml-2">({{timeAgo(d.created_at)}})</small></span>
                </td>
              </tr>
            </table>
          </div>
        </div>


</template>

<script>
import { useTimeAgo } from '@vueuse/core'
import {getIntegrationHistory, synchronizeMenu} from "@/services/Integration/IntegrationService";
import {mapGetters} from "vuex";
import {formatDate} from "../../services/FormatService";
import {DateTime} from "luxon";

export default {
  data(){
    return {
      showIntegrationHistory: false,
      data: null,
      isSynchronisationLoading: false,
      interval: null
    }
  },
  setup(){
    return {
      useTimeAgo,
      formatDate
    }
  },
  methods: {
    timeAgo(date){
      return useTimeAgo(DateTime.fromISO(date).toJSDate(), ).value
    },
    async load(){
      this.data = await getIntegrationHistory(this.currentMerchant.id);
    },
    async syncMenu(){
      this.isSynchronisationLoading = true;
      await synchronizeMenu(this.currentMerchant.id).catch(e => console.error(e));
      this.isSynchronisationLoading = false;
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
    hubrise(){
      if(!this.currentMerchant.integrations.some(e => e.type === 'hubrise')) return false;
      return this.currentMerchant.integrations.find(e => e.type === 'hubrise');
    }
  },
  mounted() {
    this.load();
    this.interval = setInterval(() => {
      this.load();
    }, 10000)
  },
  beforeUnmount() {
    clearInterval(this.interval);
  }
}
</script>
