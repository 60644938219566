<template>
  <VueFinalModal
      class="flex justify-end items-center"
      contentTransition="vfm-slide-right"
      :modal-id="closeId ?? modalId"
      :content-class="['!h-[100dvh] h-[100vh] w-full xl:w-[calc(100vw-250px)] 2xl:w-[1285px]', backgroundColor]">
    <slot :close="close"></slot>
  </VueFinalModal>
</template>

<script setup lang="ts">
import {useVfm, VueFinalModal} from 'vue-final-modal'
import {ref} from "vue";
const vfm = useVfm()


const modalId = ref('modal-'+Math.random().toString(36).substring(7))
function close(id=null) {
  vfm.close(id ?? modalId.value);
}


defineProps({
  closeId: {
    type: String,
    default: null
  },
  backgroundColor: {
    type: String,
    default: 'bg-white',
  }
})
</script>
