<template>
  <div class="pl-3 pr-3 h-9 bg-[#70C32E]/20 flex items-center gap-2 rounded-full w-[7.5rem]" v-if="status === 'validated'">
    <i class="material-icons text-[#70C32E] text-xl">check_circle</i>
    <span class="font-semibold text-[#427A16]">Validée</span>
  </div>
  <div class="pl-3 pr-3 h-9 bg-[#FF3D3D]/20 text-green-900 flex items-center w-[7.5rem] gap-2 rounded-full" v-else-if="status === 'declined'">
    <i class="material-icons text-xl text-[#D92F2F]">cancel</i>
    <span class="font-semibold text-[#7A1616]">Refusée</span>
  </div>
  <div class="pl-3 pr-3 h-9 bg-[#FF3D3D]/20 text-red-900 flex items-center w-[7.5rem] gap-2 rounded-full" v-else-if="status === 'canceled'">
    <i class="material-icons text-xl text-[#D92F2F]">cancel</i>
    <span class="font-semibold text-[#7A1616]">Annulée</span>
  </div>
  <div class="pl-3 pr-3 h-9 bg-[#ccc]/20 text-red-900 flex items-center w-[7.5rem] gap-2 rounded-full" v-else-if="status === 'done'">
    <i class="material-icons text-xl text-[#888]">assignment_turned_in</i>
    <span class="font-semibold text-[#888]">Finalisée</span>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: 'canceled'
    }
  }
}
</script>
