import {getCurrentAccount} from "@/services/AccountService";
import {
    archiveBookingZone, createBooking,
    createBookingZone,
    declineBooking,
    getBookingList, getBookingSettings, getBookingShifts,
    getBookingToConfirm, getBookingZone, updateBooking, updateBookingSettings, updateBookingZone,
    validateBooking
} from "@/services/BookingService";
import {errorToast, successToast} from "@/services/SystemService";
import i18n from "@/i18n";
import {getMerchantDocumentUploadUrl} from "@/services/MerchantService";
import ScheduleManagement from "@delicity/backend-schedule-management";

export default {
    namespaced: true,
    state: {
        bookings: null,
        bookingToConfirm: [],
        bookingSettings: null,
        bookingZones: null,
        bookingCurrentMerchantId: null,
        bookingShifts: null // Delicity SheduleManagement Object
    },
    mutations: {
        SET_BOOKING_SHIFTS(state, shifts) {
            state.bookingShifts = shifts;
        },
        SET_BOOKING_ZONES(state, zones) {
            state.bookingZones = zones;
        },
        ADD_BOOKING_ZONE(state, zone){
            state.bookingZones.push(zone);
        },
        UPDATE_BOOKING_ZONES(state, zone) {
            if(state.bookingZones){
                const zoneIndex = state.bookingZones.findIndex(z => z.id === zone.id);
                if(zone.default){
                    state.bookingZones.forEach(z => {
                        if(z.id !== zone.id){
                            z.default = false;
                        }
                    });
                }
                if(zoneIndex !== -1){
                    state.bookingZones.splice(zoneIndex, 1, zone);
                }
            }
        },
        DELETE_BOOKING_ZONES(state, id) {
            if(state.bookingZones){
                const zoneIndex = state.bookingZones.findIndex(z => z.id === id);
                if(zoneIndex !== -1){
                    state.bookingZones.splice(zoneIndex, 1);
                }
            }
        },
        SET_BOOKING_SETTINGS(state, settings) {
            state.bookingSettings = settings;
        },
        SET_BOOKINGS(state, bookings) {
            state.bookings = bookings;
        },
        ADD_BOOKING(state, booking) {
            if(state.bookings){
                state.bookings.items.push(booking);
            }
            if(state.bookingToConfirm && booking.status === 'taken'){
                state.bookingToConfirm.push(booking);
            }
        },
        EDIT_BOOKING(state, booking) {
            if(state.bookings && state.bookings.items.length){
                const index = state.bookings.items.findIndex(b => b.id === booking.id);
                if(index !== -1){
                    state.bookings.items.splice(index, 1, booking);
                }
            }
            if(state.bookingToConfirm.length>0){
                const bookingToConfirmIndex = state.bookingToConfirm.findIndex(b => b.id === booking.id);
                if(bookingToConfirmIndex !== -1){
                    state.bookingToConfirm.splice(bookingToConfirmIndex, 1, booking);
                }
            }
        },
        SET_BOOKING_TO_CONFIRM(state, bookings) {
            state.bookingToConfirm = bookings;
        },
        UPDATE_BOOKING_STATUS(state, {bookingId, status}) {
            if(state.bookings && state.bookings.items.length){
                const bookingIndex = state.bookings.items.findIndex(booking => booking.id === bookingId);
                if(bookingIndex !== -1){
                    state.bookings.items[bookingIndex].status = status;
                }
            }
            if(state.bookingToConfirm.length>0){
                const bookingToConfirmIndex = state.bookingToConfirm.findIndex(b => b.id === bookingId);
                if(bookingToConfirmIndex !== -1){
                    if(status !== 'taken'){
                        state.bookingToConfirm.splice(bookingToConfirmIndex, 1);
                    }
                }
            }
        },
        SET_BOOKING_INTERNAL_COMMENT(state, {bookingId, comment}){
            if(state.bookings && state.bookings.items.length){
                const bookingIndex = state.bookings.items.findIndex(booking => booking.id === bookingId);
                if(bookingIndex !== -1){
                    state.bookings.items[bookingIndex].intern_message = comment;
                }
            }
            if(state.bookingToConfirm.length>0){
                const bookingToConfirmIndex = state.bookingToConfirm.findIndex(b => b.id === bookingId);
                if(bookingToConfirmIndex !== -1){
                    state.bookingToConfirm[bookingToConfirmIndex].intern_message = comment;
                }
            }
        },
        SET_BOOKING_CURRENT_MERCHANT_ID(state, merchantId) {
            state.bookingCurrentMerchantId = merchantId;
        }
    },
    getters: {
        bookings: state => state.bookings,
        bookingShifts: state => state.bookingShifts,
        bookingToConfirm: state => state.bookingToConfirm,
        bookingSettings: state => state.bookingSettings,
        bookingZones: state => state.bookingZones,
        bookingCurrentMerchantId: state => state.bookingCurrentMerchantId
    },
    actions: {
        async getBookingShift({commit}, {merchantId}){
            const bookingShifts = await getBookingShifts(merchantId);
            commit('SET_BOOKING_SHIFTS', new ScheduleManagement(bookingShifts.slots, bookingShifts.closes, bookingShifts.options));
        },
        async getToConfirmBookings({commit}, {merchantId}) {
            const bookings = await getBookingToConfirm(merchantId);
            commit('SET_BOOKING_TO_CONFIRM', bookings);
        },
        async getBookingList({commit}, {merchantId, page, limit=10}) {
            commit('SET_BOOKING_CURRENT_MERCHANT_ID', merchantId);
            const bookings = await getBookingList(merchantId, page, limit);
            commit('SET_BOOKINGS', bookings);
        },
        async create({commit}, {merchantId, booking}){
            const newBooking = await createBooking(merchantId, booking);
            console.log(newBooking);
            commit('ADD_BOOKING', newBooking);
        },
        async update({commit}, {merchantId, booking}){
            const editBooking = await updateBooking(merchantId, booking.id, booking);
            commit('EDIT_BOOKING', editBooking);
        },
        async validate({commit}, {merchantId, bookingId}){
            commit('UPDATE_BOOKING_STATUS', {bookingId, status: 'validated'});
            await validateBooking(merchantId, bookingId);
        },
        async cancel({commit}, {merchantId, bookingId, reason, message}){
            commit('UPDATE_BOOKING_STATUS', {bookingId, status: 'declined'});
            await declineBooking(merchantId, bookingId, reason, message);
        },
        async getSettings({commit}, {merchantId}){
            const settings = await getBookingSettings(merchantId);
            commit('SET_BOOKING_SETTINGS', settings);
        },
        async updateSettings({commit}, {merchantId, settings}){
            await updateBookingSettings(merchantId, settings);
            commit('SET_BOOKING_SETTINGS', settings);
        },
        async getBookingZones({commit}, {merchantId}){
            const zones = await getBookingZone(merchantId);
            commit('SET_BOOKING_ZONES', zones);
        },
        async createBookingZone({commit, state}, {merchantId, zone}){
            const newZone = await createBookingZone(merchantId, zone);
            commit('ADD_BOOKING_ZONE', newZone);
        },
        async updateBookingZone({commit, state}, {merchantId, zone}){
            commit('UPDATE_BOOKING_ZONES', zone);
            await updateBookingZone(merchantId, zone);
        },
        async archiveBookingZone({commit, state, dispatch}, {merchantId, zoneId}){
            commit('DELETE_BOOKING_ZONES', zoneId);
            await archiveBookingZone(merchantId, zoneId)
                .then(() => {
                    successToast(i18n.global.t('messages.booking_zone_archived'))
                })
                .catch((e) => {
                    if(e.response && e.response.data.error){
                        if(i18n.global.te('errors.'+e.response.data.error))
                            errorToast(i18n.global.t('errors.'+e.response.data.error));
                        else
                            errorToast('Impossible d\'archiver la zone de réservation');
                    }
                    // Reload zones if error
                    dispatch('getBookingZones', {merchantId});
                })
        },
        async enableBooking({commit, state}, {merchantId}){
            const settings = state.bookingSettings;
            settings.bookingEnabled = true;
            await updateBookingSettings(merchantId, settings);
            commit('SET_BOOKING_SETTINGS', settings);
        }
    }
}
