<template>
  <table class="w-full mt-2">
    <tr>
      <td class="border">
        <label class="px-4 py-2 flex items-center cursor-pointer hover:bg-gray-50">
          <input type="checkbox" class="form-checkbox mr-2" v-model="selected" value="spicy_1">
          <span class="flex-1 leading-tight">{{ $t('product_meta.spicy_1') }}</span>
        </label>
      </td>
      <td class="border">
        <label class="px-4 py-2 flex items-center cursor-pointer hover:bg-gray-50" >
          <input type="checkbox" class="form-checkbox mr-2" v-model="selected" value="spicy_2">
          <span class="flex-1 leading-tight">{{ $t('product_meta.spicy_2') }}</span>
        </label>
      </td>
      <td class="border">
        <label class="px-4 py-2 flex items-center cursor-pointer hover:bg-gray-50" >
          <input type="checkbox" class="form-checkbox mr-2" v-model="selected" value="home_made">
          <span class="flex-1 leading-tight">{{ $t('product_meta.home_made') }}</span>
        </label>
      </td>
    </tr>
    <tr>
      <td class="border">
        <label class="px-4 py-2 flex items-center cursor-pointer hover:bg-gray-50">
          <input type="checkbox" class="form-checkbox mr-2" v-model="selected" value="kasher">
          <span class="flex-1 leading-tight">{{ $t('product_meta.kasher') }}</span>
        </label>
      </td>
      <td class="border">
        <label class="px-4 py-2 flex items-center cursor-pointer hover:bg-gray-50">
          <input type="checkbox" class="form-checkbox mr-2" v-model="selected" value="hallal">
          <span class="flex-1 leading-tight">{{ $t('product_meta.hallal') }}</span>
        </label>
      </td>
      <td class="border">
        <label class="px-4 py-2 flex items-center cursor-pointer hover:bg-gray-50">
          <input type="checkbox" class="form-checkbox mr-2" v-model="selected" value="gluten_free">
          <span class="flex-1 leading-tight">{{ $t('product_meta.gluten_free') }}</span>
        </label>
      </td>
    </tr>
    <tr>
      <td class="border">
        <label class="px-4 py-2 flex items-center cursor-pointer hover:bg-gray-50">
          <input type="checkbox" class="form-checkbox mr-2" v-model="selected" value="vegetarian">
          <span class="flex-1 leading-tight">{{ $t('product_meta.vegetarian') }}</span>
        </label>
      </td>
      <td class="border">
        <label class="px-4 py-2 flex items-center cursor-pointer hover:bg-gray-50">
          <input type="checkbox" class="form-checkbox mr-2" v-model="selected" value="vegan">
          <span class="flex-1 leading-tight">{{ $t('product_meta.vegan') }}</span>
        </label>
      </td>
      <td class="border">
        <label class="px-4 py-2 flex items-center cursor-pointer hover:bg-gray-50">
          <input type="checkbox" class="form-checkbox mr-2" v-model="selected" value="highlight">
          <span class="flex-1 leading-tight">{{ $t('product_meta.highlight') }}</span>
        </label>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      selected: []
    }
  },
  watch: {
    modelValue(val){
      this.selected = val;
    },
    selected(val, old){
      if(val.filter(e => e.startsWith('spicy')).length > 1){
        this.selected = val.filter(e => e !== old.find(o => o.startsWith('spicy')));
      }
      this.$emit('update:modelValue', this.selected);
    }
  },
  mounted() {
    this.selected = this.modelValue;
  }
}
</script>
