import axios from "@/axios-instance";

export function getClientFidelityStats(merchantId, userId){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/clients/${userId}/fidelity_stats`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function getClientList(merchantId, page, limit, search, sortField, sortOrder){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/clients?page=${page}&limit=${limit}&search=${search}&sortField=${sortField}&sortOrder=${sortOrder}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}


export function exportClientList(merchantId){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/clients/export_clients`,{responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const filename = `liste-client.xls`;
                link.setAttribute('download', `${filename}`);
                document.body.appendChild(link);
                link.click();
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    }));
}







export function getClient(merchantId, userId){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/clients/${userId}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
