<template>
  <div class="w-full sm:min-h-[100vh] py-11 sm:py-20 flex flex-col items-center justify-center gradient-auth">
    <img src="/images/logosquare.png" class="h-[150px] mb-5">

    <div class="max-w-[400px] w-full p-10 bg-white rounded-md z-50 relative">
      <slot></slot>
    </div>
  </div>
</template>

<style>
.gradient-auth {
  background: linear-gradient(rgb(248, 245, 241) 0%, rgb(255, 255, 255) 100%);
}
</style>
