<template>
    <page-block :title="title">
        <div class="h-[200px] md:h-[250px] md:pb-6 md:pl-6">
            <canvas :id="chartId"></canvas>
        </div>
    </page-block>
</template>



<script>
import {Chart, registerables} from "chart.js";
Chart.register(...registerables);

export default {
  setup(){
    const chartId = Math.random().toString(36).substring(7);
    return {chartId}
  },
  props: {
      title: {
          type: String,
          required: false
      },
      labels: {
          type: Array,
          required: true
      },
      values: {
          type: Array,
          required: true
      }
  },
  mounted() {
    const ctx = document.getElementById(this.chartId).getContext('2d');

      const data = {
          labels: this.labels,
          datasets: [
              {
                label: '%',
                borderWidth:0,
                weight:2,
                cutout: '75%',
                data: this.values,
                backgroundColor: ['#199CD9', '#404040'],
              }
          ]
      };

    new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            align: 'right',
          },
          title: {
            display: false
          }
        }
      },
    });

  }
}
</script>
