<template>
  <div class="py-4 px-5 flex items-center w-full bg-primary text-white" v-if="isMenuCurrentlySync">
    <Loader class="h-5 mr-5"/>
    <span class="font-medium">Votre menu est en train d'être importé depuis votre intégration, cette opération peut prendre quelques dizaines de secondes.</span>
  </div>
</template>
<script>
import Loader from "@/components/Ui/Elements/Loader.vue";
import {mapGetters} from "vuex";
export default {
  components: {Loader},
  data(){
    return {
      interval: null,
      expireAt: null,
      show: false
    }
  },
  methods: {
    check(){
      console.log('checking...');
      if(new Date()/1000 > this.expireAt){
        this.$store.commit('menu/SET_MENU_CURRENTLY_SYNC', false);
        clearInterval(this.interval);
        this.$store.dispatch('menu/getMerchantMenuAction', {merchantId: this.currentMerchant.id})
      }
    }
  },
  computed: {
    ...mapGetters({
      isMenuCurrentlySync: 'menu/isMenuCurrentlySync',
      currentMerchant: 'currentMerchant'
    })
  },
  mounted() {
    this.expireAt = localStorage.getItem('menu:aggregator:sync');
    if(new Date()/1000 < this.expireAt && !this.interval){
      this.$store.commit('menu/SET_MENU_CURRENTLY_SYNC', true);
      this.interval = setInterval(this.check, 1000);
    }
  },
  beforeUnmount() {
    clearInterval(this.interval);
  }
}
</script>
