<template>
  <div v-if="pagination">
    <paginate
        v-if="!isLoading && pagination.totalPages>1"
        :pageCount="pagination.totalPages"
        :clickHandler="changePage"
        :prevText="'Précedent'"
        :nextText="'Suivant'"
        v-model="pagination.currentPage"
        :containerClass="'pagination-container'">
    </paginate>
    <loader v-if="isLoading"/>
  </div>
</template>

<style>
.pagination-container {
  @apply flex justify-start items-center gap-1;
}
.pagination-container .page-link {
  @apply flex justify-center items-center h-10 px-4 rounded-lg bg-gray-200 cursor-pointer hover:bg-gray-300;
}
.pagination-container .disabled .page-link {
  @apply opacity-20;
}
.pagination-container .active .page-link {
  @apply bg-primary text-white hover:bg-primary hover:text-white font-bold;
}
</style>

<script>
import Paginate from "vuejs-paginate-next";
export default {
  components: {Paginate},
  props: {
    noScroll: {
      default: false,
      type: Boolean
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    }
  },
  methods: {
    changePage(e){
      this.$emit('changePage', e);
      if(!this.noScroll){
        document.getElementById('layout').scrollTo(0, 0);
      }
    }
  },

}
</script>
