import { createApp } from 'vue'
import { createVfm } from 'vue-final-modal'
import App from '@/App.vue'
import router from '@/router/index.js'
import stores from '@/stores/index.js'
import i18n from '@/i18n.js'
import MerchantLayout from "@/components/Layouts/MerchantLayout.vue";
import Loader from "@/components/Ui/Elements/Loader.vue";
import Pagination from "@/components/Ui/Elements/Pagination.vue";
import PageBlock from "@/components/Ui/Elements/PageBlock.vue";
import '@/assets/tailwind.css'
import 'animate.css';
import 'vue-final-modal/style.css'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueTailwindDatepicker from 'vue-tailwind-datepicker'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import * as Sentry from "@sentry/vue";
import 'intro.js/minified/introjs.min.css'
import '@/assets/introjsCustom.css'

import "@/tracker.js"

const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://c870af73880379aec1d09142a1d85572@o4505862518145024.ingest.sentry.io/4505884811460608",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router)
    .use(stores)
    .use(autoAnimatePlugin)
    .use(Toast, {timeout:5000})
    .use(createVfm())
    .use(i18n)
    .use(VueTailwindDatepicker)
    .component('merchant-layout', MerchantLayout)
    .component('pagination', Pagination)
    .component('page-block', PageBlock)
    .component('loader', Loader)
    .mount('#app')
