<template>
  <page-block :title="$t('order.order_title')" no-title-padding>
    <template v-slot:title-right>
      <button class="btn bg-gray-200 text-gray-800 flex items-center gap-2 px-3 hover:ring-0" @click="openReceipt">
        <i class="material-icons text-xl">receipt_long</i>
        ticket
      </button>
    </template>
    <template v-slot:no-padding>

      <div class="flex flex-col px-6 pb-4 text-gray-500 border-b text-sm">
        <span>{{ $t('order.order_validated_at', {date: formatIsoToHuman(order.takenAt)}) }}</span>
        <span>{{ $t('order.in_'+order.type) }} · {{ order.preorder ? $t('order.preorder', {date: formatIsoToHuman(order.preorder.preorderFor)}) : $t('order.asap') }}</span>
        <b v-if="order.onsitePayment">Commande à régler sur place</b>
      </div>

      <div class="px-6 py-4 flex flex-col gap-2 border-b last:border-0">
        <div class="grid grid-cols-[30px,auto] text-sm" v-for="item in order.items">
          <span class="font-semibold">{{item.quantity}}x</span>
          <div class="flex flex-col gap-[3px]">
            <div class="flex justify-between items-center">
              <span class="font-semibold">{{item.name}}</span>
              <span class="font-semibold text-xs">{{formatPrice(item.unitPrice)}}</span>
            </div>
            <div class="flex justify-between" v-for="m in item.modifiers" :key="m.id">
              <small class="text-xs text-gray-600">{{m.name}}</small>
              <span class="text-xs text-gray-400" v-if="m.price">+{{formatPrice(m.price)}}</span>
            </div>
          </div>
        </div>
      </div>

      <!--
      <button class="flex items-center justify-center w-full h-12 text-primary font-bold text-sm">{{ $t('cta.see_details') }}</button>
-->
    </template>
  </page-block>
</template>

<script>
import {formatIsoToHuman} from "../../../services/TimeService";
import {formatPrice} from "../../../services/FormatService";

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatPrice, formatIsoToHuman,
    openReceipt(){
      window.open('https://admin.delicity.co/receipt/'+this.order.token+'/'+this.order.id + '?size=6', "receipt", "popup=yes,width=400,height=700");
    }
  }
}
</script>
