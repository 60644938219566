<template>
  <Modal size="xs" v-slot="{close}" close-id="duplicate-product" title="Dupliquer les produits">
    <div class="pt-2 pb-3">
      <div class="pt-2 pb-3 grid grid-cols-[auto,150px] gap-3">
      <div class="flex items-center">
        <span class="">Nombre de copies à créer</span>
      </div>
      <div>
        <select v-model="quantity" class="form-select" :class="{'border-red-600 ring ring-red-500/50': errors.some(e => e.field === 'quantity')}">
          <option :value="i" v-for="i in 10">{{i}}</option>
        </select>
      </div>
    </div>

    <div v-if="errors.some(e => e.field === 'quantity')" class="flex justify-end items-center text-sm text-red-600">
      {{errors.find(e => e.field === 'quantity').message}}
    </div>
      <div class="flex justify-end mt-5">
        <button class="btn bg-primary text-white rounded disabled:opacity-50" @click="duplicate">Dupliquer</button>
      </div>
    </div>
  </Modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";
import {useModal} from "vue-final-modal";
import DuplicateProductConfirmationModal from "@/components/Menu/Modals/DuplicateProductConfirmationModal.vue";


export default {
  components: {Modal},
  setup(){
    const DuplicateConfirmationModal = useModal({component: DuplicateProductConfirmationModal});
    return {DuplicateConfirmationModal}
  },
  computed: {
    ...mapGetters({
      menu: 'menu/menu',
      currentMerchant: 'currentMerchant',
      selectedMenuItems: 'menu/selectedMenuItems',
    })
  },
  data(){
    return {
      quantity: null,
      errors: []
    }
  },
  methods: {
    validate() {
      this.errors = []

      if(this.quantity < 1) this.errors.push({field: 'quantity', message: 'La quantité doit être de 1 au minimum'})
      else if(this.quantity > 20) this.errors.push({field: 'quantity', message: 'La quantité ne peut pas être supérieure à 20'})

      return this.errors.length === 0;
    },
    async duplicate(){
      if(!this.validate()) return;
      await useVfm().close('duplicate-product')
      this.DuplicateConfirmationModal.patchOptions({attrs: {quantity: this.quantity}})
      this.DuplicateConfirmationModal.open({quantity: this.quantity});
    },
  }
}
</script>
