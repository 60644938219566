<template>
  <Modal size="small" v-slot="{close}" title="Déplacer dans une catégorie">
      <div class="pt-2 pb-3">
          <span class="form-label">Déplacer les {{selectedMenuItems.length}} éléments sélectionnés dans la catégorie:</span>
          <select class="form-input mt-2" v-model="categoryId">
              <option :value="category.id" v-for="category in menu.categories" :key="category.id">{{category.name}}</option>
          </select>
          <div class="flex justify-end mt-5">
              <button class="btn bg-primary text-white rounded disabled:opacity-50" :disabled="!categoryId" @click="save">Déplacer</button>
          </div>
      </div>
  </Modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";

export default {
    components: {Modal},
    setup(){
        const vfm = useVfm();
        return {vfm}
    },
    computed: {
        ...mapGetters({
            menu: 'menu/menu',
            currentMerchant: 'currentMerchant',
            selectedMenuItems: 'menu/selectedMenuItems',
        })
    },
    data(){
        return {
            categoryId: null
        }
    },
    methods: {
        save(){
            this.$store.dispatch('menu/moveProductInCategoryAction', {
                merchantId: this.currentMerchant.id,
                categoryId: this.categoryId,
                productIds: this.selectedMenuItems
            })
                .then(() => {
                    this.vfm.closeAll();
                })
                .catch(() => {

                })
        }
    }
}
</script>
