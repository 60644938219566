<template>
  <div class="flex flex-col gap-3 md:mt-16 sm:mt-4">

    <div class="w-full">
      <button class="bg-white border rounded-md h-10 px-4 flex items-center"  @click="checkAllToggle">
        <input type="checkbox" :checked="isAllProductChecked" class="form-checkbox pointer-events-none w-5 h-5 mr-3">
        <span class="text-gray-800 font-medium">Tout sélectionner</span>
      </button>
    </div>

    <div class="page-block overflow-x-auto md:overflow-x-clip" v-for="cat in groups" :key="cat.uid">

      <div class="h-12 flex items-center justify-between gap-4 px-4" :class="{'opacity-60 bg-gray-50':cat.archived}">
        <div class="flex items-center justify-center h-10 cursor-pointer" v-if="currentMerchant.features.menuEditionEnabled" @click="checkCategory(cat)">
          <input type="checkbox" class="form-checkbox pointer-events-none w-5 h-5" :checked="categories.some(i => i === cat.id)">
        </div>
        <strong class="text-lg flex-1 flex items-center leading-none">{{cat.name}}<span v-if="cat.archived" class="ml-3 text-xs rounded-md font-medium bg-gray-200 px-2 py-[3px]">Archivée</span></strong>

        <div class="text-blue-800 bg-blue-100 flex items-center gap-1 px-3 py-[2px] rounded-md text-sm" v-if="cat.availability">
          <i class="material-icons text-sm">schedule</i>
          <span class="text-xs">{{$t('category_schedule.'+cat.availability.type)}}</span>
        </div>

        <div class="flex gap-1">
          <button @click="openCategoryUpdate(cat)" class="text-primary underline font-bold text-sm">{{ $t('cta.edit') }}</button>·<button @click="createItem(cat)" class="text-primary underline font-bold text-sm">{{ $t('cta.add_product') }}</button>
        </div>
      </div>

      <div class="px-6 py-2 border-t flex items-center gap-2" v-if="cat.description">
        <i class="material-icons text-lg text-gray-400">info</i>
        <span class="text-sm text-gray-700 truncate">{{cat.description}}</span>
      </div>


      <div v-if="cat.items.length===0" class="h-24 border-t w-full flex items-center justify-center gap-1">
        <span class="text-gray-500 text-sm">{{ $t('menu.no_product_in_category') }}</span>·<button class="text-primary underline font-bold text-sm" @click="createItem(cat)">{{ $t('cta.add_product') }}</button>
      </div>
      <div class="border-t flex px-3 gap-3 items-center py-4 sm:py-0" v-else :class="{'bg-[#F4F4F4]': false, 'bg-gray-50':cat.archived||item.archived}" v-for="item in cat.items" :key="item.uid">
        <div class="px-1 flex items-center justify-center h-14 cursor-pointer" @click="checkProduct(item)" v-if="currentMerchant.features.menuEditionEnabled" >
          <input type="checkbox" class="form-checkbox pointer-events-none w-5 h-5" :checked="selectedMenuItems.some(i => i === item.id)">
        </div>
        <div class="flex items-center h-14 w-10" @click="openItem(item)">
          <img :src="item.image.small ?? item.image.base" v-if="item.image" class="w-10 rounded-md h-10" :class="item.image" alt="">
          <div class="w-10 h-10 bg-gray-100 rounded-md flex justify-center items-center" v-else><i class="material-icons text-gray-400">add_a_photo</i></div>
        </div>
        <div class="flex flex-col justify-center gap-[2px] flex-1"  @click="openItem(item)">
          <span class="text-sm sm:text-base font-bold text-gray-800 leading-tight">{{item.name}}</span>
          <small class="text-xs text-gray-500">{{getMenuPriceLine(item)}}</small>
        </div>


        <div class="h-8 px-3 hidden sm:flex items-center text-white bg-yellow-500 hover:bg-yellow-500/80 rounded-full cursor-pointer" @click="setHighlighted(item)" v-if="item.highlighted">
          <i class="material-icons md:mr-1 text-lg">star</i>
          <span class="font-medium text-sm hidden md:block">Produit star</span>
        </div>
        <div class="h-8 px-3 hidden sm:flex items-center text-gray-400 bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200/75" @click="setHighlighted(item)" v-else>
          <i class="material-icons md:mr-1 text-lg">star</i>
          <span class="font-medium text-sm hidden md:block">Produit star</span>
        </div>

        <select class="h-8 w-[6.5rem] md:w-30 px-3 pr-0 font-medium bg-gray-100 text-sm rounded-full outline-none" @change="updateProductStatus($event, item, cat)">
          <option value="available" :selected="item.status === 'available'">✅ Dispo</option>
          <option value="unavailable" :selected="item.status === 'unavailable'">❌ Indispo</option>
          <option value="archived" :selected="item.status === 'archived'">Archivé</option>
        </select>

        <div class="bg-gray-100 h-8 w-28 justify-center  gap-2 items-center rounded-full hidden md:flex"  v-if="item.children.length">
          <span class="font-medium text-sm">{{ $t('menu.option_count', {count: item.children.length}) }}</span>
          <i class="material-icons text-sm text-primary cursor-pointer" :id="`tippy-${item.id}`" @mouseover="showTooltip(item)">pending</i>
        </div>
        <div class="bg-gray-100 h-9 px-4  gap-2 items-center rounded-full hidden md:flex"  v-else>
          <span class="text-sm text-gray-400">{{ $t('menu.no_option') }}</span>
        </div>

        <button v-if="currentMerchant.features.menuEditionEnabled"  class="btn-sm bg-primary text-white h-9 px-4 rounded-md text-sm font-bold" @click="openItem(item)">{{$t('cta.details')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {formatPrice} from "@/services/FormatService";
import {getMenuPriceLine, getCategoryItems} from "@/services/MenuService";
import {mapGetters} from "vuex";
import EventBus from "@/EventBus";
import {useModal} from "vue-final-modal";
import MenuItemEditor from "@/views/Menu/MenuItemEditor.vue";
import MenuCategoryEditor from "@/views/Menu/MenuCategoryEditor.vue";
import Fuse from "fuse.js";
import {siteTour} from "@/services/TourService";
import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/translucent.css'


export default {
  props: {
    search: {
      type: String,
      default: ''
    }
  },
  setup(){
    const productModal = useModal({component: MenuItemEditor});
    const categoryModal = useModal({component: MenuCategoryEditor});
    return {formatPrice,getMenuPriceLine, productModal, categoryModal}
  },
  computed: {
    ...mapGetters({
      selectedMenuItems: 'menu/selectedMenuItems',
      archivedItemsVisibility: 'menu/archivedItemsVisibility',
      menu: 'menu/menu',
      currentMerchant: 'currentMerchant'
    }),
    groups(){
      const cat = JSON.parse(JSON.stringify(this.menu.categories));
      return cat.filter(c => this.archivedItemsVisibility ? true : c.archived===false).map((c) => {
        const items = this.menu.items.filter(i => c.itemIds.indexOf(i.uid) !== -1 )
          .filter(i => this.archivedItemsVisibility ? true : i.archived===false)
          .map((i,index) => {
            if(i.archived)
              i.status = 'archived';
            else if(i.unavailable)
              i.status = 'unavailable';
            else
              i.status = 'available';
            //i.sort = index;
            return i;
          })
          .sort((a,b) => a.sort - b.sort)
        c.items = c.itemIds.map((uid) => items.find(i => i.uid === uid)).filter(e => e !== undefined);
        if(this.search){
          const fuseActions = new Fuse(c.items, {threshold: 0.3, includeScore: true, minMatchCharLength:0, distance: 35, keys: ['name']});
          c.items = fuseActions.search(this.search).sort((a,b)=>a.score-b.score).map(r => r.item)
        }
        return c;
      })
        .sort((a,b) => a.sort - b.sort)
        .filter((e) => {
          if(this.search) return e.items.length>0;
          else return true;
        })
    },
    isAllProductChecked(){
      return this.selectedMenuItems.length === this.menu.items.filter(i => this.archivedItemsVisibility ? true : i.archived===false).length;
    }
  },
  data(){
    return {
      categories: [],
      tippyInstances: [],
    }
  },
  methods: {
    checkAllToggle(){
      if(this.isAllProductChecked){
        this.$store.commit('menu/SET_SELECTED_MENU_ITEMS', []);
        this.menu.categories.forEach((cat) => {
          this.categories = this.categories.filter(c => c !== cat.id);
        })
      }
      else{
        this.menu.categories.forEach((cat) => {
          this.categories.push(cat.id);
        })
        this.$store.commit('menu/SET_SELECTED_MENU_ITEMS', this.menu.items.filter(i => this.archivedItemsVisibility ? true : i.archived===false).map(i => i.id));
      }
    },

    async openCategoryUpdate(category){
      await this.categoryModal.patchOptions({attrs: {categoryId: category.id}});
      await this.categoryModal.open();
    },
    async openItem(item){
      if(!this.currentMerchant.features.menuEditionEnabled) return;
      const category = this.menu.categories.find(c => c.itemIds.includes(item.uid));
      await this.productModal.patchOptions({attrs: {category: category.id, item}});
      await this.productModal.open();
      siteTour('product_details');
    },
    async createItem(category){
      await this.productModal.patchOptions({attrs: {category: category.id, item:null}});
      await this.productModal.open();
      siteTour('product_add');
    },
    checkCategory(category){
      const categoryItems = this.menu.items.filter(i => this.archivedItemsVisibility ? true : i.archived===false).filter(i => category.itemIds.includes(i.uid));
      const selectedInCategory = categoryItems.filter(i => this.selectedMenuItems.some(s => s === i.id));
      const notSelectedInCategory = categoryItems.filter(i => !this.selectedMenuItems.some(s => s === i.id));
      if(selectedInCategory.length === categoryItems.length){
        selectedInCategory.forEach(i => this.$store.commit('menu/REMOVE_SELECTED_MENU_ITEM', i.id));
        this.categories = this.categories.filter(c => c !== category.id);
      }
      else{
        this.$store.commit('menu/ADD_SELECTED_MENU_ITEM', notSelectedInCategory.map(i => i.id));
        this.categories.push(category.id)
      }
    },
    checkProduct(item){
      if(this.selectedMenuItems.some(i => i === item.id)){
        this.$store.commit('menu/REMOVE_SELECTED_MENU_ITEM', item.id);
      }
      else{
        this.$store.commit('menu/ADD_SELECTED_MENU_ITEM', [item.id]);
      }
      // Check if all item in category is selected ?
      const category = this.menu.categories.find(c => c.itemIds.includes(item.uid));
      this.updateCategoryCheck(category);
    },
    updateCategoryCheck(category){
      const categoryItems = this.menu.items.filter(i => this.archivedItemsVisibility ? true : i.archived===false).filter(i => category.itemIds.includes(i.uid));
      const selectedInCategory = categoryItems.filter(i => this.selectedMenuItems.some(s => s === i.id));
      if(categoryItems.length === selectedInCategory.length){
        this.categories.push(category.id)
      }
      else{
        this.categories = this.categories.filter(c => c !== category.id);
      }
    },
    async setHighlighted(item){
      const isHighlighted = !item.highlighted;
      this.$store.commit('menu/SET_MENU_ITEM_HIGHLIGHT', {itemId:item.id, highlight:isHighlighted});

      await this.$store.dispatch('menu/updateMenuItemBatchAction', {merchantId: this.currentMerchant.id, update: [{
          id: item.id,
          highlighted: isHighlighted,
        }]});
    },
    async updateProductStatus(event, item, category){
      if(category.archived && (event.target.value === 'available' || event.target.value === 'unavailable')){

        return;
      }
      await this.$store.dispatch('menu/updateMenuItemBatchAction', {merchantId: this.currentMerchant.id, update: [{
          id: item.id,
          status: event.target.value,
        }]});
    },
    showTooltip(value){
      const options = value.children.map(uid => this.menu.modifierGroups.find(m => m.uid===uid));
      const optionMandatory = options.filter(o => o.min>0).map(opt => opt.name);
      const optionOptional = options.filter(o => o.min===0).map(opt => opt.name);

      const content = `
        <div class="w-[200px]">
          <div class="flex flex-col items-start text-start gap-[.2em] px-0 py-[.7em]">
            ${optionMandatory.map(item => `<div class="font-bold color-[#333333] px-[1.3em] py-0 overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px] leading-6">${item}*</div>`).join('')}
            ${optionMandatory.length && optionOptional.length ? `<div class="w-full my-[6px] h-[1px] bg-[#d1d5db]"></div>` : ''}
            ${optionOptional.map(item => `<div class="color-[#333333] px-[1.3em] py-0 overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px] leading-6">${item}</div>`).join('')}
          </div>
        </div>`

      let idTippy = `#tippy-${value.id}`

      if(!this.tippyInstances.map(instance => '#'+instance[0].reference.id).includes(idTippy))

        this.tippyInstances.push(
          tippy(idTippy, {
            content: content,
            allowHTML: true,
            placement: 'top',
            theme:'options',
            arrow: true,
          })
        )

    },
  },
  mounted() {
    EventBus.on('menu:category:update-category-check', () => {
      this.menu.categories.forEach(c => this.updateCategoryCheck(c));
    });
  }
}
</script>

<style>
.tippy-content {
  padding: 0;
}
.tippy-box {
  padding: 0;
  background-color: white;
  color: black;
  box-shadow: 0px 6px 15px -4px rgba(0,0,0,0.34);
  border-radius: 16px;
}
.tippy-arrow {
  color:white;
}
</style>

