<template>
    <div class="flex h-11 pr-7 pl-2 hover:bg-blue-300/10" :class="{'opacity-50 pointer-event-none':isLoading, 'opacity-50 cursor-not-allowed hover:bg-gray-300 bg-gray-300':disabled}" >
        <div class="w-8 flex items-center justify-center mr-2 text-primary" v-if="icon">
            <i class="material-icons text-2xl" v-if="!isLoading" :class="[textClass,iconClass]">{{icon}}</i>
            <loader v-else class="h-4 w-4 dark:text-white text-gray-800"/>
        </div>
        <div class="flex items-center font-medium flex-1 leading-tight dark:text-white whitespace-nowrap" :class="[textClass, !icon?'pl-2':'']">{{text}}</div>
    </div>
</template>

<script>
export default {
  props: {
	  icon: {
		  default: null
	  },
	  disabled: {
		  default: false,
		  type: Boolean
	  },
	  isLoading: {
		  default: false,
		  type: Boolean
	  },
	  textClass:{
		  default: null
	  },
	  iconClass:{
		  default: null
	  },
	  text: {
		  type: String
	  }
  }
}
</script>
