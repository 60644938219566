<template>
  <modal title="Détails réservation" size="medium">

    <div class="border rounded-xl">
      <div class="px-6 py-5">
        <div class="flex items-center">
          <img :src="currentBooking.user.avatar" class="h-14 w-14 rounded-full">
          <div class="flex flex-col ml-4 flex-1">
            <span class="font-bold text-lg leading-tight">{{currentBooking.user.name}}</span>
            <div class="flex items-center gap-2 mt-1">

              <span class="text-gray-600 text-sm">Depuis votre site</span>

              <button class="underline font-medium text-primary text-sm" v-if="currentBooking.user.id">Voir la fiche client</button>
            </div>
          </div>

        </div>

        <div class="grid grid-cols-3 gap-2 mt-4" >
          <a :href="'tel:'+currentBooking.user.phone" class="flex text-center items-center justify-center h-10 btn bg-primary text-sm text-white">{{currentBooking.user.phone_formatted}}</a>

          <template v-if="currentBooking.user.id">
            <div class="flex-1 h-10 bg-gray-200 rounded-md flex items-center justify-center" v-if="!fidelity">
              <loader class="h-4 w-4"/>
            </div>
            <div class="flex-1 h-10 bg-gray-200 rounded-md flex items-center justify-center" v-if="!fidelity">
              <loader class="h-4 w-4"/>
            </div>
            <div class="flex-1 h-10 bg-gray-200 rounded-md flex items-center justify-center" v-if="fidelity">
              <span>{{ $t('client.fidelity.orders', {count: fidelity.orders}) }}</span>
            </div>
            <div class="flex-1 h-10 bg-gray-200 rounded-md flex items-center justify-center" v-if="fidelity">
              <span>{{ $t('client.fidelity.bookings', {count: fidelity.bookings}) }}</span>
            </div>
          </template>
          <template v-else>
            <div class="flex-1 h-10 bg-gray-200 rounded-md flex items-center justify-center">
              <span>{{ $t('client.fidelity.orders', {count: 0}) }}</span>
            </div>
            <div class="flex-1 h-10 bg-gray-200 rounded-md flex items-center justify-center">
              <span>{{ $t('client.fidelity.bookings', {count: 1}) }}</span>
            </div>
          </template>


        </div>

      </div>
      <hr>
      <div class="px-6 py-5 grid grid-cols-2">
        <div class="flex flex-col">
          <span class="uppercase text-gray-400 font-bold text-sm">Status</span>
          <span class="font-bold text-xl text-gray-800" v-if="currentBooking.status === 'taken'">En Attente</span>
          <span class="font-bold text-xl text-gray-800" v-if="currentBooking.status === 'validated'">Validée</span>
          <span class="font-bold text-xl text-red-600" v-if="currentBooking.status === 'declined'">Refusée</span>
          <span class="font-bold text-xl text-gray-800" v-if="currentBooking.status === 'done'">Terminée</span>
          <span class="font-bold text-xl text-red-600" v-if="currentBooking.status === 'canceled'">Annulée</span>
        </div>
        <div class="flex flex-col">
          <span class="uppercase text-gray-400 font-bold text-sm">REF</span>
          <span class="font-bold text-xl text-gray-800">{{currentBooking.ref}}</span>
        </div>
      </div>
      <hr>
      <div class="px-6 py-5  grid grid-cols-[50%,20%,30%]">
        <div class="flex flex-col">
          <span class="uppercase text-gray-400 font-bold text-sm">CRENEAU</span>
          <span class="font-bold text-xl text-gray-800 capitalize">{{formatDate(booking.booking_for, 'ccc DD HH:mm')}}</span>
        </div>
        <div class="flex flex-col">
          <span class="uppercase text-gray-400 font-bold text-sm">PAX</span>
          <span class="font-bold text-xl text-gray-800 flex items-center"><i class="material-icons mr-1 text-xl">group</i>{{booking.persons}}</span>
        </div>
        <div class="flex flex-col">
          <span class="uppercase text-gray-400 font-bold text-sm">SALLE</span>
          <span class="font-bold text-xl text-gray-800">{{booking.zone.name}}</span>
        </div>
        <div class="flex flex-col col-span-3 mt-5">
          <span class="uppercase text-gray-400 font-bold text-sm">COMMENTAIRE CLIENT</span>
          <div class="bg-gray-200 px-4 py-2 rounded-lg mt-1">
            <span class="text-gray-600" v-if="booking.client_message">{{booking.client_message}}</span>
            <span v-else class="text-sm text-gray-500">Pas de commentaire client...</span>
          </div>
        </div>
        <div class="flex flex-col col-span-3 mt-5" v-if="booking.intern_message">
          <span class="uppercase text-gray-400 font-bold text-sm">COMMENTAIRE INTERNE</span>
          <div class="bg-yellow-200/60 px-4 py-2 rounded-lg mt-1">
            <span class="text-sm text-gray-600" >{{booking.intern_message}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 flex justify-between" v-if="booking.status !== 'canceled' && booking.status !== 'declined'">
      <button class="font-medium underline text-primary" @click="openAddInternalComment">Ajouter un commentaire interne</button>
      <div class="flex items-center gap-3">
        <button v-if="booking.status !== 'taken'" class="btn border border-red-600 text-red-600 hover:ring-red-500/20" @click="cancel">Annuler</button>
        <button class="btn bg-primary text-white" @click="openUpdateBooking">Modifier</button>
      </div>
    </div>

    <div class="mt-8 border-t -mx-6 px-6 pt-4" v-if="booking.status === 'taken'">
      <div class="flex justify-between">
        <div class="border-l-4 flex items-center justify-start pl-4 border-orange-400">
          <span class="text-xl font-medium">Valider cette réservation ?</span>
        </div>
        <div class="flex items-center gap-1">
            <button class="text-[#FF0000] hover:opacity-80"><i class="material-icons text-[3rem]">cancel</i></button>
            <button class="text-[#70C32E] hover:opacity-80"><i class="material-icons text-[3rem]">check_circle</i></button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {useModal} from "vue-final-modal";
import BookingEditor from "@/components/Booking/Modal/BookingEditor.vue";
import {mapGetters} from "vuex";
import {formatDate} from "../../../services/FormatService";
import BookingInternalComment from "@/components/Booking/Modal/BookingInternalComment.vue";
import BookingCancellation from "@/components/Booking/Modal/BookingCancellation.vue";
import {getClientFidelityStats} from "@/services/ClientService";
export default {
  components: {Modal},
  setup(){
    const cancelModal = useModal({component: BookingCancellation})
    const updateBooking = useModal({component: BookingEditor});
    const addInternalComment = useModal({component: BookingInternalComment});
    return {updateBooking, addInternalComment, cancelModal}
  },
  data(){
    return {
      fidelity: null
    }
  },
  methods: {
    formatDate,
    cancel(){
      this.cancelModal.patchOptions({attrs: {bookingId: this.booking.id}});
      this.cancelModal.open();
    },
    openUpdateBooking(){
      this.updateBooking.patchOptions({attrs: {bookingData:this.currentBooking}});
      this.updateBooking.open();
    },
    openAddInternalComment(){
      this.addInternalComment.patchOptions({attrs: {booking:this.currentBooking}});
      this.addInternalComment.open();
    }
  },
  props: {
    booking: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant',
      bookings: 'booking/bookings',
      bookingToConfirm: 'booking/bookingToConfirm'
    }),
    currentBooking(){
      if(!this.bookings || this.bookings.length===0) return this.booking;
      if(this.bookings.items.some(e => e.id === this.booking.id)){
        return this.bookings.items.find(e => e.id === this.booking.id)
      }
      if(this.bookingToConfirm.some(e => e.id === this.booking.id)) {
        return this.bookingToConfirm.find(e => e.id === this.booking.id)
      }
    }
  },
  async mounted() {
    if(this.currentBooking.user.id){
      this.fidelity = await getClientFidelityStats(this.currentMerchant.id, this.currentBooking.user.id)
    }
  }
}
</script>
