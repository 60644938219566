<template>
    <div>
        <input type="text" :disabled="error" :id="inputId" class="form-input" placeholder="Entrez une adresse...">
        <small v-if="error" class="text-red-600">{{error}}</small>
    </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

export default {
    props: {
        modelValue: {
            type: String,
            default: null
        },
        fieldValue: {
            type: String,
            default: null
        }
    },
    data(){
        return {
            google: null,
            error: null,
            inputId: null
        }
    },
    methods: {
        loadAutocomplete(){
            const input = document.getElementById(this.inputId);
            const options = {
                componentRestrictions: { country: "fr" },
                fields: ["place_id"],
                strictBounds: false,
                types: ["geocode"],
            };
            const autocomplete = new this.google.maps.places.Autocomplete(input, options);

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                this.$emit('update:modelValue', place.place_id);
            });
        }
    },
    mounted() {
        this.inputId = (Math.random() + 1).toString(36).substring(7);
        const loader = new Loader({
            apiKey: import.meta.env.VITE_GOOGLE_API,
            version: "weekly",
            libraries: ["places"]
        });

        this.$nextTick(() => {
            if(this.fieldValue){
                const input = document.getElementById(this.inputId);
                input.value = this.fieldValue;
            }
        })

        loader
            .load()
            .then((google) => {
                this.google = google;
                this.loadAutocomplete();
            })
            .catch(e => {
                console.log(e);
                this.error = this.$t('errors.try_later_or_reload')
            });
    }
}
</script>
