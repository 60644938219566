<template>
  <Modal size="xs" v-slot="{close}" title="Fixer les prix">

    <div>
      <div class="mb-4">
        <div class="flex items-center mb-4">
          <label class="form-label mb-0">Types de produits</label>
          <div v-if="errors.some(e => e.field === 'type')" class="flex pt-[2px] justify-end items-center text-sm text-red-600 ml-4">
            {{errors.find(e => e.field === 'type').message}}
          </div>
        </div>
        <div class="border rounded-md flex" :class="{'border-red-600 ring ring-red-500/50': errors.some(e => e.field === 'type')}">
          <label for="onsite" class="h-10 border-r flex-1 flex justify-center items-center gap-2 cursor-pointer" :class="{'bg-gray-100':currentMerchant.features.menuMinorEditionOnly}">
            <input type="checkbox" class="form-checkbox" id="onsite" value="on_site_counter" name="type" :disabled="currentMerchant.features.menuMinorEditionOnly" v-model="orderType.onsite">
            <span>Sur place</span>
          </label>
          <label for="pickup" class="h-10 border-r flex-1 flex justify-center items-center gap-2 cursor-pointer">
            <input type="checkbox" class="form-checkbox" id="pickup" value="pickup" name="type" v-model="orderType.pickup">
            <span>Click&Collect</span>
          </label>
          <label for="delivery" class="h-10 flex-1 flex justify-center items-center gap-2 cursor-pointer">
            <input type="checkbox" class="form-checkbox" id="delivery" value="delivery" name="type" v-model="orderType.delivery">
            <span>En livraison</span>
          </label>
        </div>
      </div>
    </div>


    <div class="pt-2 pb-3 grid grid-cols-[auto,150px] gap-3">
      <div class="flex items-center">
          <span class="">Nouveaux prix des produits</span>
      </div>
      <div>
          <input type="number" v-model="price" class="form-input" min="0.01" step="0.01" :class="{'border-red-600 ring ring-red-500/50': errors.some(e => e.field === 'price')}">
      </div>
    </div>

    <div v-if="errors.some(e => e.field === 'price')" class="flex justify-end items-center text-sm text-red-600">
      {{errors.find(e => e.field === 'price').message}}
    </div>

    <div class="flex justify-end mt-3">
        <button class="btn bg-primary text-white rounded disabled:opacity-50" @click="save">{{$t('cta.edit')}}</button>
    </div>
  </Modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";

export default {
    components: {Modal},
    setup(){
        const vfm = useVfm();
        return {vfm}
    },
    computed: {
        ...mapGetters({
            menu: 'menu/menu',
            currentMerchant: 'currentMerchant',
            selectedMenuItems: 'menu/selectedMenuItems',
        })
    },

    data(){
        return {
            orderType: {
              delivery: false,
              pickup: false,
              onsite: false,
            },
            price: null,
            errors: []
        }
    },
    methods: {
      validate() {
        this.errors = []

        if(!this.price) this.errors.push({field: 'price', message: 'Vous devez renseigner un prix'})
        else if(this.price < 0.01) this.errors.push({field: 'price', message: 'Le prix ne peut pas être inférieur à 0,01€'})
        if(!this.orderType.delivery && !this.orderType.pickup && !this.orderType.onsite) this.errors.push({field: 'type', message: 'Vous devez choisir au moins un type de produit'})

        return this.errors.length === 0;
      },
      async save(){
        if(!this.validate()) return;

        const update = this.menu.items
          .filter(i => this.selectedMenuItems.includes(i.id))
          .map((item) => {
            const data = {id: item.id};
            if(this.orderType.delivery) data['price_delivery'] = this.price*100;
            if(this.orderType.pickup) data['price_pickup'] = this.price*100;
            if(this.orderType.onsite) data['price_onsite'] = this.price*100;
            return data;
          });

        await this.$store.dispatch('menu/updateMenuItemBatchAction', {merchantId: this.currentMerchant.id, update});
        this.vfm.closeAll();
      }
    }
}
</script>
