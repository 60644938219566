import axios from "@/axios-instance";
import {uploadMediaToS3} from "@/services/MediaService";
import heic2any from "heic2any";

export async function getMerchantSchedule(merchantId){
    return new Promise(((resolve, reject) => {
        axios.get('/v1/merchants/'+merchantId+'/schedule')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}


export async function getMerchantSettings(merchantId){
    return new Promise(((resolve, reject) => {
        axios.get('/v1/merchants/'+merchantId+'/settings')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function updateMerchantSettings(merchantId, data){
    return new Promise(((resolve, reject) => {
        axios.post('/v1/merchants/'+merchantId+'/settings', data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}


export async function updateMerchantSchedule(merchantId, schedules){
    return new Promise(((resolve, reject) => {
        axios.post('/v1/merchants/'+merchantId+'/schedule', {schedules})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}


export async function createMerchantClosure(merchantId, start, end, type){
    return new Promise(((resolve, reject) => {
        axios.post('/v1/merchants/'+merchantId+'/schedule/close', {start, end, type})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function updateMerchantClosure(merchantId, closureId, start, end, type){
    return new Promise(((resolve, reject) => {
        axios.post('/v1/merchants/'+merchantId+'/schedule/close/update', {closureId, start, end, type})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function deleteMerchantClosure(merchantId, closureId){
    return new Promise(((resolve, reject) => {
        axios.post('/v1/merchants/'+merchantId+'/schedule/close/delete', {closureId})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}


export async function getMerchantSidebarAlerts(merchantId){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/sidebar_alerts`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function getMerchantCompanyInfos(merchantId){
    return new Promise(((resolve, reject) => {
        axios.get('/v1/merchants/'+merchantId+'/company/company_infos')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function getMerchantCompanyDocuments(merchantId){
    return new Promise(((resolve, reject) => {
        axios.get('/v1/merchants/'+merchantId+'/company/documents')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function updateMerchantCompanyInfos(merchantId, {companyName,companyCapital,companyRCS,companyRegistrationNumber,companyType,companyVatNumber,hasVatEnabled,companyNewAddressID}){
    return new Promise(((resolve, reject) => {
        axios.post('/v1/merchants/'+merchantId+'/company/company_infos', {companyName,companyCapital,companyRCS,companyRegistrationNumber,companyType,companyVatNumber,hasVatEnabled,companyNewAddressID})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function getMerchants(){
    return new Promise(((resolve, reject) => {
        axios.get('/v1/merchants/current_merchants')
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}


export async function getMerchantDocumentUploadUrl(merchantId, type, fileType, replaceId){
    return new Promise(((resolve, reject) => {
        axios.post('/v1/merchants/'+merchantId+'/company/documents/upload_url', {type,fileType,replaceId})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
export async function ConfirmMerchantDocumentUpload(merchantId, type, documentId){
    return new Promise(((resolve, reject) => {
        axios.post('/v1/merchants/'+merchantId+'/company/documents/upload_confirm', {type, documentId})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function removeMerchantDocument(merchantId, id){
    return new Promise(((resolve, reject) => {
        axios.post('/v1/merchants/'+merchantId+'/company/documents/delete', {id})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function getOnboardingLink(merchantId, type){
    return new Promise(((resolve, reject) => {
        axios.post('/v1/merchants/'+merchantId+'/onboarding/link', {type})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}



export async function uploadMerchantDocument(event, merchantId, uploadType, replaceId=null){
    let file = event.target.files[0];

    // Convert Iphone files to jpeg if needed
    if (event.target.files[0].type === 'image/heif' || event.target.files[0].type === 'image/heic') {
        await heic2any({
            blob: event.target.files[0],
            toType: "image/jpeg",
            quality: 0.9
        })
            .then((conversionResult) => {
                file = conversionResult;
            });
    }

    const fileType = file.type;
    const baseUpload = await getMerchantDocumentUploadUrl(merchantId, uploadType, fileType, replaceId)
    await uploadMediaToS3(baseUpload.url, file)
    await ConfirmMerchantDocumentUpload(merchantId, uploadType, baseUpload.documentId);
    return true;
}

export async function searchMerchantOnGoogle(search){
    return new Promise(((resolve, reject) => {
        axios.post('/v1/merchants/google_search', {search})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function setMerchantPlaceId(merchantId, place_id){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/onboarding/set_google_place_id`, {place_id})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function getMerchantPdfMenu(merchantId){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/pdf_menu`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}


export async function updateMerchantGallery(merchantId, imageData, galleryId){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/gallery/set`, {imageData, galleryId})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function removeMerchantGallery(merchantId, galleryId){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/gallery/delete`, {galleryId})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
export async function removeBanner(merchantId){
  console.log(merchantId)
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/banner/delete`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
export async function removeLogo(merchantId){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/logo/delete`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
export async function removeProductImage(merchantId, productId){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/product_image/delete`, {productId})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}


export async function getMerchantTrackingQrCodeLink(merchantId){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/qr_code/main`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
