<template>
  <Modal size="xs" v-slot="{close}" title="Modifier la majoration de prix">
      <div class="pt-2 pb-3 grid grid-cols-[auto,150px] gap-3">

          <div class="flex items-center">
              <span class="">Modifier les prix</span>
          </div>
          <div>
              <select class="form-input" v-model="from">
                  <option value="delivery">En livraison</option>
                  <option value="pickup">Click&Collect</option>
                  <option value="onsite" :disabled="currentMerchant.features.menuMinorEditionOnly">Sur place</option>
              </select>
          </div>

          <div class="flex items-center">
              <span class="">en ajoutant un supplément de</span>
          </div>
          <div>
              <input type="number" v-model="diff" class="form-input">
          </div>

          <div class="flex items-center">
              <span class="">par rapport aux prix</span>
          </div>
          <div>
              <select class="form-input" v-model="to">
                  <option value="delivery">En livraison</option>
                  <option value="pickup">Click&Collect</option>
                  <option value="onsite">Sur place</option>
              </select>
          </div>

      </div>

      <div class="flex justify-end mt-3">
          <button class="btn bg-primary text-white rounded disabled:opacity-50" @click="save">{{$t('cta.edit')}}</button>
      </div>
  </Modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";

export default {
    components: {Modal},
    setup(){
        const vfm = useVfm();
        return {vfm}
    },
    computed: {
        ...mapGetters({
            menu: 'menu/menu',
            currentMerchant: 'currentMerchant',
            selectedMenuItems: 'menu/selectedMenuItems',
        })
    },

    data(){
        return {
            from: 'delivery',
            diff: 0,
            to: 'onsite'
        }
    },
    methods: {
      async save(){
        const update = this.menu.items
            .filter(i => this.selectedMenuItems.includes(i.id))
            .map((item) => {
              const data = {id: item.id};
              data[`price_${this.from}`] = item.price[this.to] + (this.diff*100);
              return data;
            });
        await this.$store.dispatch('menu/updateMenuItemBatchAction', {merchantId: this.currentMerchant.id, update});
        this.vfm.closeAll();
      }
    }
}
</script>
