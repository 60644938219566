<template>
  <vue-final-modal
      name="ai-menu"
      modal-id="ai-menu"
      content-transition="vfm-slide-down"
      hide-overlay
      class="flex justify-start items-end sm:pl-10 lg:pl-[260px]"
      :content-class="[
          'bg-white transition-all w-full sm:w-[calc(100vw-5rem)] md:w-[600px] lg:w-[600px] flex flex-col rounded-t-xl border-2 border-b-0 border-primary shadow-2xl',
          isFocus&&conversationContent.length>0? 'h-[80vh]' : '',
          !isFocus&&conversationContent.length>0? 'h-[60vh]' : '',
          conversationContent.length===0? 'h-[35vh]' : ''
          ]">

      <div class="h-14 border-b border-gray-300 pl-4 flex items-center justify-between">
          <i class="material-icons text-primary text-2xl">auto_awesome</i>
          <span class="text-xl font-bold flex-1 ml-3">IA de Delicity</span>
          <div class="flex items-center">
              <button class="btn btn-sm bg-gray-300 text-gray-800 h-7" @click="createNewConversation">Nouvelle conversation</button>
              <button class="h-14 w-14 flex items-center justify-center" @click="close"><i class="material-icons">close</i></button>
          </div>
      </div>

      <div class="flex-1 w-full bg-gray-200 overflow-y-auto" id="chatcontent">

          <template v-for="message in conversationContent">
              <div class="w-full flex p-4 border-t border-gray-300 first:border-0"  v-if="message.role !== 'system' && message.role !== 'function' && !message.function_call">

                  <div class="h-12 w-12 bg-center bg-cover rounded-full shadow"
                       v-if="message.role==='user'"
                       :style="'background-image: url('+currentAccount.avatar_small+')'"></div>

                  <div class="h-12 w-12 bg-center bg-cover rounded-full shadow"
                       v-if="message.role==='assistant'"
                       :style="'background-image: url(/images/bag_avatar.svg)'"></div>

                  <div class="pl-4 flex-1">
                      <div class="whitespace-pre-wrap leading-7 mt-3 font-medium" >
                          {{message.content}}
                      </div>
                  </div>
              </div>
              <div v-if="message.function_call" class="w-full flex p-4 border-t border-gray-300 first:border-0">
                  <div class="h-12 w-12 bg-center bg-cover rounded-full shadow"
                       v-if="message.role==='assistant'"
                       :style="'background-image: url(/images/bag_avatar.svg)'"></div>

                  <div class="pl-4 flex-1">
                      <div class="whitespace-pre-wrap leading-7 mt-3 font-medium" v-if="message.function_call.name === 'create_menu'">✅ Menu créer</div>
                      <div class="whitespace-pre-wrap leading-7 mt-3 font-medium" v-if="message.function_call.name === 'get_products'">⚙️ Récupération de la liste de tous vos produits...</div>
                      <div class="whitespace-pre-wrap leading-7 mt-3 font-medium" v-if="message.function_call.name === 'get_products_details'">⚙️ Récupération des détails de certains produits...</div>
                      <div class="whitespace-pre-wrap leading-7 mt-3 font-medium" v-if="message.function_call.name === 'update_products'">⚙️ Modification...</div>
                  </div>
              </div>
          </template>

          <div class="h-20 w-full flex items-center justify-center" v-if="waitingResponse">
              <loader/>
          </div>

      </div>


      <div class="h-0 w-full flex items-start justify-end relative z-50">
          <button class="bg-primary btn text-white my-7 px-5 mx-5" @click="send">Envoyer</button>
      </div>
      <textarea class="w-full transition-all border-t outline-none pl-6 font-medium text-lg border-t border-gray-300 pr-[130px]"
                :class="{
                      'h-full pt-6': isFocus&&conversationContent.length===0,
                      'h-72 pt-6': isFocus&&conversationContent.length>0,
                      'h-24 pt-4': !isFocus
                }"
                placeholder="Écrivez le menu que vous voulez créer ou modifier comme si vous parliez à un humain..."
                @blur="!message&&conversationContent.length>0?isFocus=false:''"
                @focus="isFocus=true"
                @keydown.ctrl.enter="send"
                v-model="message"
      ></textarea>




  </vue-final-modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {useVfm, VueFinalModal} from "vue-final-modal";
import {
    conversationSendMessage,
    createNewConversation,
    getConversation,
    getCurrentConversations
} from "@/services/AiService";
import Loader from "@/components/Ui/Elements/Loader.vue";

export default {
    components: {Loader, Modal, VueFinalModal},
    setup(){
        const vfm = useVfm();
        return {vfm}
    },
    computed: {
        ...mapGetters({
            menu: 'menu/menu',
            currentMerchant: 'currentMerchant',
            currentAccount: 'account/account'
        })
    },
    data(){
        return {
            interval: null,
			isFocus: false,
            conversationContent: [],
            message: '',
            currentConversation: null,
            waitingResponse: false
        }
    },
    methods: {
        close(){
			useVfm().closeAll();
        },
        createNewConversation(){
            this.conversationContent = [];
            this.currentConversation = null;
            this.isFocus = true;
        },
        async send(){
            if(!this.currentConversation){
                await createNewConversation(this.currentMerchant.id, this.message)
                    .then((data) => {
                        this.currentConversation = data.conversationId;
                        this.waitingResponse = true;
                        this.$nextTick(() => {
                            document.getElementById('chatcontent').scrollTop = document.getElementById('chatcontent').scrollHeight;
                        })
                    });
            }
            else{
                await conversationSendMessage(this.currentMerchant.id, this.currentConversation, this.message)
                    .then((data) => {
                        this.waitingResponse = true;
                        this.$nextTick(() => {
                            document.getElementById('chatcontent').scrollTop = document.getElementById('chatcontent').scrollHeight;
                        })
                    });
            }
            this.conversationContent.push({
                role: 'user',
                content: this.message,
                temp: true
            });
            this.$nextTick(() => {
                document.getElementById('chatcontent').scrollTop = document.getElementById('chatcontent').scrollHeight;
            })
            this.message = '';
            this.isFocus = false;
        },
        loadMessage(){
            getConversation(this.currentMerchant.id, this.currentConversation)
                .then(async (data) => {
                    if(data.messages.length !== this.conversationContent.filter(e => !e.temp).length){
                        this.conversationContent = data.messages;
                        this.waitingResponse = false;

                        // @todo scroll to the bottom
                        this.$nextTick(() => {
                            document.getElementById('chatcontent').scrollTop = document.getElementById('chatcontent').scrollHeight;
                        })

                        await this.$store.dispatch('menu/getMerchantMenuAction', {merchantId: this.currentMerchant.id})
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
        },
        getCurrentConversations(){
            getCurrentConversations(this.currentMerchant.id)
                .then((data) => {
                    if(data.some((c) => c.messages.length>0)){
                        this.currentConversation = data.find((conversation) => conversation.messages.length>0)._id;
                        this.loadMessage();
                    }
                })
        }
    },
    unmounted() {
        clearInterval(this.interval);
    },
    mounted(){
        this.getCurrentConversations();

        this.interval = setInterval(() => {
            if(this.currentConversation){
                this.loadMessage();
            }
        }, 5000)
    }
}
</script>
