<template>
  <div class="w-full h-[100vh] flex justify-center items-center bg-gray-200 flex-col">
    <loader class="h-10 w-10"/>
    <span class="font-bold mt-10">HubRise > Delicity</span>
    <span>Finalisation de l'intégration...</span>
  </div>
</template>

<script>
import {validateAuth} from "@/services/Integration/HubriseService";
import {errorToast} from "@/services/SystemService";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    })
  },
  async mounted() {
    if(!this.currentMerchant) await this.$store.dispatch('getMerchantsAction');

    if(this.$route.query.code){
      validateAuth(this.currentMerchant.id, this.$route.query.code)
          .then(async () => {
            await this.$store.dispatch('getMerchantsAction');
            this.$router.push({name: 'integrations'})
          })
          .catch(() => {
            errorToast('Une erreur est survenue lors de la validation de l\'authentification...');
            this.$router.push({name: 'integrations'})
          })
    }
  }
}
</script>
