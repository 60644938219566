<template>
  <modal-confirm :button-confirm-text="Dupliquer"
                 @confirm="confirm"
                 @cancel="close"
                 :description="$t('menu.bulk_duplication', {count:selectedMenuItems.length, quantity})">
  </modal-confirm>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";
import ModalConfirm from "@/components/Ui/Modals/ModalConfirm.vue";

export default {
  components: {ModalConfirm, Modal},
  setup(){
    const vfm = useVfm();
    return {vfm}
  },
  props: {
    quantity: {
      type: Number,
      required: true
    }
  },
  data(){
    return {
      isLoading: true,
      percentage: 0,
    }
  },
  computed: {
    ...mapGetters({
      menu: 'menu/menu',
      currentMerchant: 'currentMerchant',
      selectedMenuItems: 'menu/selectedMenuItems',
    })
  },
  methods: {
    async confirm(){
      const selected = this.menu.items.filter(i => this.selectedMenuItems.includes(i.id));
      for(let item of selected){
        await this.$store.dispatch('menu/duplicateProduct', {merchantId: this.currentMerchant.id, productId: item.id, quantity: this.quantity});
      }
      await useVfm().closeAll();
    },
    async close(){
      await useVfm().closeAll();
    }
  }
}
</script>
