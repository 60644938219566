<template>
  <modal size="xs" close-id="validate-preorder">
    <div class="px-2 py-3">
      <div class="mb-4 flex flex-col gap-1" v-if="!preorderConfirmed">
        <span class="text-2xl font-bold">Confirmer la précommande</span>
        <span>Indiquez à quelle heure souhaitez-vous avoir un rappel et passer la commande en préparation</span>
      </div>
      <div class="mb-4 flex flex-col gap-1" v-else>
        <span class="text-2xl font-bold">Modifier la précommande</span>
        <span>Indiquez à quelle heure souhaitez-vous avoir un rappel et passer la commande en préparation</span>
      </div>

      <div class="flex justify-center mt-8 mb-1">
        <small class="text-base mb-1 font-medium">Heure de rappel</small>
      </div>
      <div class="flex items-center gap-4 justify-center ">
        <button class="h-11 w-11 bg-primary rounded-full text-white flex items-center justify-center" @click="removeTime"><i class="material-icons">remove</i></button>
        <span class="font-bold text-3xl" v-if="ringAt">{{ringAt.toFormat('HH:mm')}}</span>
        <button class="h-11 w-11 bg-primary rounded-full text-white flex items-center justify-center" @click="addTime"><i class="material-icons">add</i></button>
      </div>
      <div class="flex justify-center mt-3 mb-14">
        <small class="text-sm text-gray-600 mb-1">(Précommande pour {{preorderFor.toFormat('HH:mm')}})</small>
      </div>

      <div class="flex justify-between gap-4">
        <button class="btn bg-primary text-white flex-2 rounded-md h-10" :disabled="isButtonLoading" @click="save">
          <span v-if="!isButtonLoading">Confirmer précommande</span>
          <loader class="h-4 w-4" v-else/>
        </button>

        <button class="btn bg-gray-200  rounded-md ring-gray-100 h-10" @click="closeModal">Fermer</button>
      </div>
    </div>
  </modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {useVfm} from "vue-final-modal";
import {DateTime} from "luxon";
import {mapGetters} from "vuex";

export default {
  components: {Modal},
  props: {
    orderId: {
      type: Number,
      required: true
    },
    orderRingAt: {
      type: DateTime,
      required: true
    },
    preorderFor: {
      type: DateTime,
      required: true
    },
    preorderConfirmed: {
      type: Boolean,
      required: false
    }
  },
  data(){
    return {
      ringAt: null,
      isButtonLoading: false
    }
  },
  methods: {
    closeModal(){
      useVfm().close('validate-preorder');
    },
    addTime(){
      this.ringAt = this.ringAt.plus({minutes: 5});
    },
    removeTime(){
      this.ringAt = this.ringAt.minus({minutes: 5});
    },
    async save(){
      this.isButtonLoading = true;
      await this.$store.dispatch('order/confirmPreorder', {merchantId: this.currentMerchant.id, orderId: this.orderId, datetime: this.ringAt.toISO()})
      this.isButtonLoading = false;
      this.closeModal();
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    })
  },
  mounted() {
    this.ringAt = this.orderRingAt;
  }
}
</script>
