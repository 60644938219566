import {formatPrice} from "@/services/FormatService";
import axios from "@/axios-instance";

export function getMenuPriceLine(item){
    if(item.price.delivery === item.price.onsite){
        return formatPrice(item.price.onsite)
    }
    else{
        return formatPrice(item.price.onsite) + ' ('+formatPrice(item.price.delivery)+' en livraison)'
    }
}

export function getCategoryItems(items, category, archivedItemsVisibility){
    return items.filter(i => category.itemIds.indexOf(i.uid) !== -1 )
        .filter(i => archivedItemsVisibility ? true : i.archived===false)
        .map(i => {
            if(i.archived)
                i.status = 'archived';
            else if(i.unavailable)
                i.status = 'unavailable';
            else
                i.status = 'available';
            return i;
        })
}



export function updateCategoryBatch(merchantId, update){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/category/update_batch`, {update})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
export function createCategory(merchantId, name, description, sort, availability, availabilityType, availabilityBetweenTwoDates, availabilityDateOnly){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/category/create`, {name, description, sort, availability, availabilityType, availabilityBetweenTwoDates, availabilityDateOnly})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function updateSorts(merchantId, items, categories){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/sorts`, {items, categories})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function updateItemBatch(merchantId, update){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/item/update_batch`, {update})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function duplicateItem(merchantId, productId, quantity){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/item/duplicate`, {productId, quantity})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function createItem(merchantId, data){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/item/create`, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function moveProductCategories(merchantId, categoryId, productIds){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/category/update_product`, {categoryId, productIds})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function getMerchantMenu(merchantId){
    return new Promise((resolve, reject) => {
        axios.get(`/v1/merchants/${merchantId}/menu`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export function createModifierGroupBatch(merchantId, name, min, max, quantitySelector, sort){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/modifier_group/create`, {name, min, max, quantitySelector, sort})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function updateModifierGroupBatch(merchantId, update){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/modifier_group/update_batch`, {update})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
export function attachModifierGroupBatch(merchantId, modifierGroupIds, productIds, absolute){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/modifier_group/attach_product_batch`, {modifierGroupIds, productIds, absolute})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
export function detachModifierGroup(merchantId, modifierGroupId, productId){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/modifier_group/detach_product`, {modifierGroupId, productId})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function updateModifierBatch(merchantId, modifierGroupId, update){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/modifier/update_batch`, {modifierGroupId, update})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function deleteModifierBatch(merchantId, modifiers){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/modifier/delete_batch`, {ids:modifiers})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function deleteModifierGroupBatch(merchantId, modifierGroups){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/modifier_group/delete_batch`, {ids:modifierGroups})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function duplicateModifierGroupBatch(merchantId, modifierGroups){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/${merchantId}/menu/modifier_group/duplicate_batch`, {ids:modifierGroups})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}


export function moveTableCursor(key, current, menu, archivedVisible){
    const cursorMap = menu.categories.sort((a,b) => a.sort - b.sort)
        .filter(c => archivedVisible || !c.archived)
        .map(c => c.itemIds.map(uid => {
            const product = menu.items.filter(c => archivedVisible || !c.archived).find(i=>i.uid===uid);
            return product ? product.id : null;
        }).filter(c => c))
        .flat();

    const currentProductId = parseInt(current.split('_')[0]);

    let currentColumn = parseInt(current.split('_')[1]);
    let currentRowIndex = cursorMap.indexOf(currentProductId);

    let nextProductId = currentProductId;
    let nextColumn = currentColumn;

    if(key === 'ArrowUp' && currentRowIndex > 0){
        nextProductId = cursorMap[currentRowIndex - 1];
    }
    else if(key === 'ArrowDown'){
        nextProductId = cursorMap[currentRowIndex + 1];
    }
    else if(key === 'ArrowRight' && currentColumn < 6){
        nextColumn = currentColumn + 1;
    }
    else if(key === 'ArrowLeft' && currentColumn > 0){
        nextColumn = currentColumn - 1;
    }

    return nextProductId + '_' + nextColumn;
}
