import axios from "@/axios-instance";

export async function getCurrentAccount(){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/account`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function checkEmailAvailability(){
    return new Promise(((resolve, reject) => {
        axios.get(`/v1/merchants/account/check_email_availability`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function updateCurrentAccount(data){
    return new Promise(((resolve, reject) => {
        axios.post(`/v1/merchants/account`, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function updatePassword(password){
    return new Promise(((resolve, reject) => {
        axios.post(`${import.meta.env.VITE_AUTH_URL}/v1/oauth/update_password`, {password})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export function validateEmail(email){
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}
