<template>
  <div class="flex items-center justify-start border bg-white rounded-md overflow-hidden h-[31.5px]" id="tour_searchInput">
    <div class="h-10 w-11 flex items-center justify-center select-none" @click="showSearchBar=!showSearchBar">
      <i class="material-icons text-2xl">search</i>
    </div>
    <input type="text" class="h-10 outline-none w-[110px]" @change="updateSearch" v-model="search" :placeholder="placeholder">
  </div>
</template>


<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Recherche'
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      showSearchBar: false,
      search: ''
    }
  },
  methods: {
    updateSearch(){
      this.$emit('update:modelValue', this.search)
    }
  },
  watch: {
    modelValue(){
      this.search = this.modelValue;
      if(this.modelValue){
        this.showSearchBar = true;
      }
    }
  },
  mounted() {
    this.search = this.modelValue;
    if(this.modelValue){
      this.showSearchBar = true;
    }
  }
}
</script>
