import axiosInstance from "@/axios-instance";

export async function getIntegrationHistory(merchantId){
    return new Promise(((resolve, reject) => {
        axiosInstance.get('/v1/merchants/'+merchantId+'/integrations/menu_sync_history', {})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}

export async function synchronizeMenu(merchantId){
    localStorage.setItem('menu:aggregator:sync', (new Date()/1000)+30);
    return new Promise(((resolve, reject) => {
        axiosInstance.post('/v1/merchants/'+merchantId+'/integrations/menu_start_sync', {})
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    }));
}
