<template>
  <Modal size="small" v-slot="{close}" close-id="assign-option" title="Utiliser une option existante">
    <div class="pt-2 pb-3">
      <label class="form-label">Choisir une option</label>
      <select class="form-select" v-model="modifierGroupId">
        <option :value="null">-- Choisir l'option --</option>
        <option :value="modifierGroup.id" v-for="modifierGroup in options" :key="modifierGroup.uid">{{modifierGroup.name}} ({{modifierGroup.min === 0 ? 'Option facultative' : 'Option obligatoire'}})</option>
      </select>
      <div class="flex justify-end mt-5">
        <button class="btn bg-primary text-white rounded disabled:opacity-50" :disabled="!modifierGroupId" @click="save">Ajouter</button>
      </div>
    </div>
  </Modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import {useVfm} from "vue-final-modal";
import EventBus from "@/EventBus";

export default {
  components: {Modal},
  setup(){
    const vfm = useVfm();
    return {vfm}
  },
  props: {
    ignoreOptions: {
      required: true,
      type: Array,
      default: () => []
    },
    type: {
      required: true,
      type: String
    },
    productId: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      menu: 'menu/menu',
      currentMerchant: 'currentMerchant'
    }),
    options(){
      return this.menu.modifierGroups.filter(g => !this.ignoreOptions.includes(g.id));
    }
  },
  data(){
    return {
      modifierGroupId: null
    }
  },
  methods: {
    save(){
      if(this.productId){
        this.$store.dispatch('menu/attachModifierGroupsBatchAction', {
          merchantId: this.currentMerchant.id,
          modifierGroupIds: [this.modifierGroupId],
          productIds: [this.productId]
        });
      }
      else{
        const currentOption = this.menu.modifierGroups.find(f => f.id === this.modifierGroupId)
        EventBus.emit('menu:modifier-group:pending-attach', currentOption.uid);
      }
      useVfm().close('assign-option');
    }
  },
  mounted() {
    console.log();
  }
}
</script>
