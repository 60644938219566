import axios from 'axios'
import store from '@/stores/index.js'

const instance = axios.create({
  baseURL: import.meta.env.VITE_GATEWAY_URL,
  timeout: 20000
});


instance.interceptors.response.use(async (response) => {
        return response;
    },
    async(error) => {
        if(error.response.status === 401 && (
            error.response.data.error === 'ERR_JWT_EXPIRED'
            || error.response.data.error === 'ERR_JWS_INVALID'
            || error.response.data.error === 'ERR_JWS_SIGNATURE_VERIFICATION_FAILED'
            || error.response.data.error === 'auth_token_need_refresh'
        )) {
            await store.dispatch('refreshToken', {refresh_token: localStorage.getItem('refresh_token')});
            error.config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
            return axios.request(error.config);
        }
        return Promise.reject(error);
    });

if (localStorage.getItem('access_token') !== null) {
    instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
}
export default instance
