<template>
  <modal size="2xs" close-id="delete-closure" :no-scroll="true">
    <div class="flex flex-col py-2 px-2">
      <span class="text-xl font-bold mb-3">Confirmation</span>
      <span>Êtes-vous sûr de vouloir supprimer la fermeture du <span class="capitalize">{{ formatDate(closure.punctualStart, 'ccc dd LLLL HH:mm') }}</span> au <span class="capitalize">{{ formatDate(closure.punctualEnd, 'ccc dd LLLL HH:mm') }}</span> ?</span>

      <div class="flex items-center  mt-7 gap-2">
        <button class="btn bg-red-600 hover:ring-red-300/50 text-white" :disabled="isLoading" @click="deleteClosure">
          <span v-if="!isLoading">Supprimer</span>
          <loader class="h-4 w-4" v-else/>
        </button>
        <button class="btn bg-gray-100 text-gray-800 hover:ring-0">{{$t('cta.cancel')}}</button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Ui/Modals/Modal.vue'
import {deleteMerchantClosure} from "@/services/MerchantService";
import {useVfm} from "vue-final-modal";
import {formatDate} from "../../../services/FormatService";
import {mapGetters} from "vuex";
import Loader from "@/components/Ui/Elements/Loader.vue";

export default {
  components: {Loader, Modal},
  props: {
    closure: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    })
  },
  methods: {
    formatDate,
    async deleteClosure(){
      this.isLoading = true;
      const data = await deleteMerchantClosure(this.currentMerchant.id, this.closure.id);
      this.$store.commit('SET_MERCHANT_SCHEDULE', data);
      await useVfm().close('delete-closure');
      this.isLoading = false;
    }
  }
}
</script>
