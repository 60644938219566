<template>
  <modal close-id="menu-modifier-editor-modal" :title="modalTitle" :no-padding="true">

    <div class="px-8 py-4">

      <div class="grid md:grid-cols-2 gap-4 mt-3">
        <div>
          <label class="font-bold">Nom de l'option</label>
          <input type="text" class="form-input mt-2" :class="{'form-input-error':errors.some(e => e.error === 'name')&&!modifierGroup.name}" v-model="modifierGroup.name" placeholder="ex: Sauce au choix, supplément au choix...">
          <small v-if="errors.some(e => e.error === 'name')&&!modifierGroup.name" class="text-red-600">{{errors.find(e=>e.error==='name').message}}</small>
        </div>
        <div class="grid md:grid-cols-2 gap-4">
          <div>
            <label class="font-bold">Ordre</label>
            <select class="form-select mt-2" v-model="modifierGroup.sort">
              <option :value="0">En premier</option>
            </select>
          </div>
          <div>
            <label class="font-bold">Obligatoire</label>
            <div class="flex items-center mt-2">
              <!-- TODO: enlever le disabled et ajouter l'interaction au clic : @click="isOptionMandatory = 'mandatory'" -->
              <div
                class="flex items-center p-2 w-full border-[1px] border-r-[transparent] border-gray-300 rounded-l-lg cursor-not-allowed"
                :class="{'bg-primary text-white font-[500]' : isOptionMandatory === 'mandatory'}"
              >
                <input id="mandatoryOption" value="mandatory" v-model="isOptionMandatory" disabled type="radio" class="h-full cursor-not-allowed w-[50%] accent-white">
                <label class="cursor-not-allowed ml-1" for="mandatoryOption">Oui</label>
              </div>
              <!-- TODO: enlever le disabled et ajouter l'interaction au clic : @click="isOptionMandatory = 'optional'" -->
              <div
                class="flex items-center p-2 w-full border-[1px] border-gray-300 rounded-r-lg cursor-not-allowed"
                :class="{'bg-primary text-white font-[500]' : isOptionMandatory === 'optional'}"
              >
                <input id="notMandatoryOption" value="optional" v-model="isOptionMandatory" disabled type="radio" class="h-full cursor-not-allowed w-[50%] accent-white">
                <label class="cursor-not-allowed ml-1" for="notMandatoryOption">Non</label>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div class="grid md:grid-cols-2 gap-4 mt-5">

          <div>
            <label class="font-bold">Type d'option</label>
            <select class="form-select mt-2" v-model="optionTypeSelected">
              <option v-for="optionType in optionsTypes" :value="optionType.value">{{ optionType.name }}</option>
            </select>
          </div>

          <div>
            <label class="font-bold">Nombre de choix</label>
            <div class="flex items-center mt-2" :class="{'opacity-[40%]' : optionTypeSelected === 0}">
              <div class="border-[1px] border-gray-300 text-sm rounded-l-lg px-3 py-2 h-10 whitespace-nowrap">Choix min</div>
              <select :disabled="optionTypeSelected === 0" class="border-y-[1px] border-gray-300 bg-transparent pl-2 font-bold outline-none h-10" @change="evaluateMaxValue" v-model="modifierGroup.min">
                <option v-if="isOptionMandatory === 'optional'" :value="index" v-for="(i, index) in 31">{{index}}</option>
                <option v-else :value="i" v-for="i in 30">{{i}}</option>
              </select>
              <div class="border-y-[1px] border-l-[1px] border-gray-300 text-sm px-3 py-2 h-10 whitespace-nowrap">Choix max</div>
              <select :disabled="optionTypeSelected === 0" class="border-[1px] border-gray-300 rounded-r-lg bg-transparent pl-2 font-bold outline-none h-10" v-model="modifierGroup.max">
                <option :value="i" v-for="i in 30" :disabled="i < modifierGroup.min">{{i}}</option>
              </select>
            </div>
            <small v-if="errors.some(e => e.error === 'quantity')" class="text-red-600">{{errors.find(e=>e.error==='quantity').message}}</small>
          </div>

        </div>

    </div>

    <div class="bg-[#333333] text-white py-2 px-8 mt-5">
      <strong>Choix possibles</strong>
    </div>

    <div class="px-3 md:px-8 pb-4 pt-2 mt-2 min-h-[220px] flex-col flex">

      <div class="w-full flex pl-3 md:pr-2 gap-2 border border-transparent" v-if="modifiers.length>0">
        <div class="w-[5%]"></div>
        <div class="w-[55%]">
          <span class="text-sm text-gray-500">Nom du choix</span>
        </div>
        <div class="w-[20%] text-center md:text-left">
          <span class="text-sm text-gray-500 hidden md:block">Supplément</span>
          <span class="text-sm text-gray-500 md:hidden">Supp.</span>
        </div>
        <div class="w-[15%] text-center md:text-left">
          <span class="text-sm text-gray-500 hidden md:block">Disponibilité</span>
          <span class="text-sm text-gray-500 md:hidden">Dispo?</span>
        </div>
        <div class="w-[5%]"></div>
      </div>

      <draggable v-model="modifiers" tag="ul" item-key="uid">
        <template #item="{ element: v }">
          <div class="bg-gray-200 border pl-3 md:pr-2 py-2 rounded-md flex gap-2 border-gray-300 mb-1 relative"  :class="{'border-r-primary':v.productId}">
            <div class="w-[5%] flex items-center justify-center cursor-grab">
              <i class="material-icons text-gray-500">drag_indicator</i>
            </div>
            <div class="w-[55%]">
              <input type="text" class="form-input"  :id="'field:'+v.uid" v-on:keydown.ctrl.enter="addModifier" v-on:keydown.ctrl.delete="removeModifier(v)" v-model="v.name" :class="{'form-input-error bg-white':errors.some(e => e.error === 'modifier_name:'+i)&& !v.name}">
            </div>
            <div class="w-[20%]">
              <input type="number" placeholder="0,00€"  v-on:keydown.ctrl.enter="addModifier" v-on:keydown.ctrl.delete="removeModifier(v)" class="form-input" v-model="v.price">
            </div>
            <div class="w-[15%]">
              <div @click="v.available = !v.available" class="cursor-pointer border border-gray-300 bg-white h-10 rounded-md flex items-center justify-center">
                <input type="checkbox" :checked="v.available" class="form-checkbox md:mr-2 text-green-500">
                <span class="text-sm hidden md:block">Dispo</span>
              </div>
            </div>
            <div class="w-[5%] flex items-center justify-end">
              <menu-button no-button no-border icon="more_vert" icon-class="text-black">
                <template v-slot:menu>
                  <menu-button-item text="Lié a un produit" @click="modifierLinkToProduct(v)" description="Permet de lier la disponibilité de l'option à celle d'un produit"/>
                  <menu-button-item text="Dupliquer le choix" @click="duplicateModifierChoice(v)"/>
                  <menu-button-item text="Supprimer ce choix" @click="removeModifier(v)"/>
                </template>
              </menu-button>
            </div>

            <svg width="20" v-if="v.productId" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-primary h-4 -right-6 top-5 absolute">
              <path d="M8.58995 11.4101C8.99995 11.8001 8.99995 12.4401 8.58995 12.8301C8.19995 13.2201 7.55995 13.2201 7.16995 12.8301C5.21995 10.8801 5.21995 7.71007 7.16995 5.76007L10.7099 2.22007C12.6599 0.270068 15.8299 0.270068 17.7799 2.22007C19.7299 4.17007 19.7299 7.34007 17.7799 9.29007L16.2899 10.7801C16.2999 9.96007 16.1699 9.14007 15.8899 8.36007L16.3599 7.88007C17.5399 6.71007 17.5399 4.81007 16.3599 3.64007C15.1899 2.46007 13.2899 2.46007 12.1199 3.64007L8.58995 7.17007C7.40995 8.34007 7.40995 10.2401 8.58995 11.4101ZM11.4099 7.17007C11.7999 6.78007 12.4399 6.78007 12.8299 7.17007C14.7799 9.12007 14.7799 12.2901 12.8299 14.2401L9.28995 17.7801C7.33995 19.7301 4.16995 19.7301 2.21995 17.7801C0.269946 15.8301 0.269946 12.6601 2.21995 10.7101L3.70995 9.22007C3.69995 10.0401 3.82995 10.8601 4.10995 11.6501L3.63995 12.1201C2.45995 13.2901 2.45995 15.1901 3.63995 16.3601C4.80995 17.5401 6.70995 17.5401 7.87995 16.3601L11.4099 12.8301C12.5899 11.6601 12.5899 9.76007 11.4099 8.59007C10.9999 8.20007 10.9999 7.56007 11.4099 7.17007Z" fill="currentColor"/>
            </svg>
          </div>
        </template>
      </draggable>



      <div class="mt-2 flex items-center gap-4" :class="{'mt-0': modifiers.length===0}">
        <button class="btn-sm rounded-md bg-gray-200 ring-gray-200/30" @click="addModifier(null)">+ Ajouter un choix</button>
        <small class="text-red-500" v-if="errors.some(e => e.error === 'modifiers')">{{errors.find(e => e.error === 'modifiers').message}}</small>
      </div>
      <div class="mt-12 flex justify-end flex-1 items-end">
        <button class="btn rounded-md bg-primary text-white" @click="save">Enregistrer</button>
      </div>
    </div>

  </modal>
</template>

<script>
import MenuButton from "@/components/Ui/Elements/MenuButton.vue";
import MenuButtonItem from "@/components/Ui/Elements/MenuButtonItem.vue";
import Modal from "@/components/Ui/Modals/Modal.vue";
import {mapGetters} from "vuex";
import draggable from 'vuedraggable';
import EventBus from "@/EventBus";
import {useModal, useVfm} from "vue-final-modal";
import linkModifierToItem from "@/components/Menu/Modals/LinkModifierToItem.vue";
import duplicateModifierChoice from "@/components/Menu/Modals/DuplicateModifierChoice.vue";
export default {
  components: {Modal, MenuButton, MenuButtonItem, draggable},
  setup(){
    const linkModifierToItemModal = useModal({component: linkModifierToItem});
    const duplicateModifierChoiceModal = useModal({component: duplicateModifierChoice});
    return {linkModifierToItemModal, duplicateModifierChoiceModal}
  },
  props: {
    type: {
      type: String,
      required: true
    },
    productId: {
      type: Number,
      required: true
    },
    optionUid: {
      type: String,
      default: null
    }
  },
  data(){
    return{
      customMinAndMax: false,
      modifierGroup: {
        id: null,
        name: null,
        quantitySelector: false,
        min: 1,
        max: 1,
        sort: 0
      },
      modifierToDelete: [],
      modifiers: [],
      errors: [],
      isLoading: false,
      isOptionMandatory: "mandatory",
      optionsTypes: [
        { value: 0, name: 'Choisir 1 option dans la liste'},
        { value: 1, name: 'Choisir plusieurs options distinctes'},
        { value: 2, name: 'Choisir plusieurs options - Choix identiques possibles'},
      ],
      optionTypeSelected: 0,
    }
  },
  methods: {
    validate(){
      this.errors = [];

      if(!this.modifierGroup.name) this.errors.push({error: 'name', message: this.$t('errors.missing_option_name')})
      if(this.modifierGroup.max === 0) this.errors.push({error: 'quantity', message: this.$t('errors.option_max_must_be_positive')})
      if(this.modifierGroup.min > this.modifierGroup.max) this.errors.push({error: 'quantity', message: this.$t('errors.option_min_cant_be_higher_than_max')})
      if(this.modifiers.length === 0) this.errors.push({error: 'modifiers', message: this.$t('errors.modifier_group_must_have_a_modifier')})

      this.modifiers.forEach((modifier, index) => {
        if(!modifier.name) this.errors.push({error: 'modifier_name:'+index})
      });

      return this.errors.length === 0;
    },
    async save(){
      if(!this.validate()) return;
      this.isLoading = true;

      if(this.optionUid){
        const promises = [];
        promises.push(this.$store.dispatch('menu/updateMenuModifierGroupBatchAction', {merchantId: this.currentMerchant.id, update: [{
            id: this.modifierGroup.id,
            sort: this.modifierGroup.sort,
            name: this.modifierGroup.name,
            min: this.modifierGroup.min,
            max: this.modifierGroup.max,
            quantitySelector: this.modifierGroup.quantitySelector
          }]}));
        promises.push(this.$store.dispatch('menu/updateMenuModifierBatchAction', {merchantId: this.currentMerchant.id, modifierGroupId: this.modifierGroup.id, update:
              this.modifiers.map((m, i) => {
                return {
                  id: m.id,
                  name: m.name,
                  price: Math.round(m.price*100),
                  description: m.description,
                  available: m.available,
                  productId: m.productId,
                  sort: i,
                }
              })}));

        if(this.modifierToDelete.length>0){
          promises.push(this.$store.dispatch('menu/deleteMenuModifierBatchAction', {merchantId: this.currentMerchant.id, modifierIds: this.modifierToDelete}));
        }

        await Promise.all(promises);
        this.isLoading = false;
      }
      else{
        const modifierGroup = await this.$store.dispatch('menu/createMenuModifierGroupAction', {
          merchantId: this.currentMerchant.id,
          name: this.modifierGroup.name,
          min: this.modifierGroup.min,
          max: this.modifierGroup.max,
          quantitySelector: this.modifierGroup.quantitySelector,
          sort: this.modifierGroup.sort
        });

        await this.$store.dispatch('menu/updateMenuModifierBatchAction', {merchantId: this.currentMerchant.id, modifierGroupId: modifierGroup.id, update:
              this.modifiers.map((m, i) => {
                return {
                  id: m.id,
                  name: m.name,
                  price: Math.round(m.price*100),
                  description: m.description,
                  available: m.available,
                  productId: m.productId,
                  sort: i
                }
              })
        });

        if(this.productId){
          await this.$store.dispatch('menu/attachModifierGroupsBatchAction', {
            merchantId: this.currentMerchant.id,
            modifierGroupIds:[modifierGroup.id],
            productIds:[this.productId],
            absolute: false
          })
        }
        else{
          EventBus.emit('menu:modifier-group:pending-attach', modifierGroup.uid);
        }
      }
      this.isLoading = false;
      useVfm().close('menu-modifier-editor-modal');
    },
    addModifier(name = null, price = null, available = true){
      this.errors = this.errors.filter(e => e.error !== 'modifiers');
      const uid = Math.random().toString(36).substr(2, 9);
      this.modifiers.push({
        uid: uid,
        id: null,
        name: name,
        price: price,
        description: null,
        productId: null,
        available: available
      });
      this.$nextTick(() => {
        document.getElementById('field:'+uid).focus()
      })
    },
    modifierLinkToProduct(modifier){
      this.linkModifierToItemModal.patchOptions({attrs: {modifierUid: modifier.uid, actualLink: modifier.productId}});
      this.linkModifierToItemModal.open();
    },
    duplicateModifierChoice(modifier){
      this.duplicateModifierChoiceModal.patchOptions({attrs: {modifier: modifier}});
      this.duplicateModifierChoiceModal.open();
    },
    removeModifier(modifier){
      if(modifier.id) this.modifierToDelete.push(modifier.id);
      this.modifiers = this.modifiers.filter(m => m.uid !== modifier.uid).sort((a,b) => a.sort - b.sort);

      this.$nextTick(() => {
        const lastModifier = this.modifiers[this.modifiers.length-1];
        if(lastModifier){
          document.getElementById('field:'+lastModifier.uid).focus()
        }
      })
    },
    evaluateMaxValue() {
      if(this.modifierGroup.min > this.modifierGroup.max) this.modifierGroup.max = this.modifierGroup.min;
    }
  },
  created(){
    if(this.optionUid){
      const option = this.menu.modifierGroups.find(o => o.uid === this.optionUid);

      this.modifierGroup.id = option.id;
      this.modifierGroup.name = option.name;
      this.modifierGroup.min = option.min;
      this.modifierGroup.max = option.max;
      this.modifierGroup.quantitySelector = option.quantitySelector;
      this.modifierGroup.sort = 0;
      const menuModifiers = this.menu.modifiers.filter(m => option.itemIds.includes(m.uid)).sort((a,b) => a.sort - b.sort);
      this.modifiers = menuModifiers.map(m => {
        return {
          uid: Math.random().toString(36).substr(2, 9),
          id: m.id,
          name: m.name,
          description: m.description,
          price: m.price/100,
          available: !m.unavailable,
          productId: m.parentProductId
        }
      });

      if(this.modifierGroup.max === 1) this.optionTypeSelected = 0
      else if(this.modifierGroup.quantitySelector) this.optionTypeSelected = 2
      else this.optionTypeSelected = 1
    }
    else{
      this.addModifier()
      if(this.type === 'mandatory'){
        this.modifierGroup.min = 1;
        this.modifierGroup.max = 1;
      }
      else {
        this.modifierGroup.min = 0;
        this.modifierGroup.max = 1;
      }
    }
  },
  mounted() {
    this.isOptionMandatory = this.type;

    EventBus.on('modifier-group:link-modifier-to-product', (data) => {
      const index = this.modifiers.findIndex(m => m.uid === data.modifierUid)
      if(index !== -1){
        this.modifiers[index].productId = data.productId;
      }
      else{
        console.error(`> Error updating modifier list UI, modifier uid ${data.modifierUid} not found...`, this.modifiers)
      }
    });
    EventBus.on('modifierChoiceDuplication', ({quantity, modifier}) => {
      for(let i = 0; i < quantity; i++){
        this.addModifier(modifier.name, modifier.price, modifier.available);
      }
    });
  },
  computed: {
    ...mapGetters({
      menu: 'menu/menu',
      currentMerchant: 'currentMerchant'
    }),
    modalTitle(){
      if(!this.optionUid) {
        return this.type==="mandatory" ? "Nouvelle option obligatoire" : "Nouvelle option facultative";
      }
      else {
        return this.type==="mandatory" ? "Modification option obligatoire" : "Modification option facultative";
      }
    },
    isSeveralQuantityAllowed() {
      return this.optionTypeSelected === 2;
    }
  },
  watch: {
    optionTypeSelected(newType, oldType){
      this.modifierGroup.quantitySelector = (newType === 2)
      if(newType === 0) {
        this.modifierGroup.max = 1;
        if(this.isOptionMandatory === 'mandatory') this.modifierGroup.min = 1;
        else this.modifierGroup.min = 0;
      }
    }
  }
}
</script>
