<template>
  <Modal size="medium" v-slot="{ close }" title="Gérer la disponibilité produits">
    <div>
      <div class="mb-4">

        <label for="available" class="flex items-center cursor-pointer p-3 border-t-[1px] border-l-[1px] border-r-[1px] rounded-tl-xl rounded-tr-xl">
          <input type="radio" v-model="availability" name="availability" id="available" value="available" class="form-radio mr-3">
          <div>
            <div class="font-bold">Produits disponibles</div>
            <div class="text-gray-600">Le(s) produit(s) sont disponibles à la vente</div>
          </div>
          <i class="material-icons text-green-600 justify-self-end ml-auto">check_circle</i>
        </label>

        <div class="p-3 border-[1px]">
          <label for="unavailable" class="flex items-center cursor-pointer">
            <input type="radio" v-model="availability" name="availability" id="unavailable" value="unavailable" class="form-radio mr-3">
            <div>
              <div class="font-bold">Produits indisponibles</div>
              <div class="text-gray-600">Indéfiniment ou jusqu'à une certaine date</div>
            </div>
            <i class="material-icons text-red-500 justify-self-end ml-auto">cancel</i>
          </label>

          <div v-if="availability === 'unavailable'" class="flex gap-4 mt-2 pl-6 pr-10">
            <select class="form-select" v-model="timeUnavailable">
              <option v-for="(time, i) in timeUnavailableOptions" :key="i" :value="time.value">{{ time.name }}</option>
            </select>
            <input class="form-input" :class="{ 'invisible': timeUnavailable !== 'date' }" type="date" :min="minDate" v-model="unavailabilityDate">
          </div>
        </div>


        <label for="archive" class="flex items-center cursor-pointer p-3 border-b-[1px] border-l-[1px] border-r-[1px] rounded-bl-xl rounded-br-xl">
          <input type="radio" v-model="availability" name="availability" id="archive" value="archive" class="form-radio mr-3">
          <div>
            <div class="font-bold">Produits archivés</div>
            <div class="text-gray-600">Masqués indéfiniment sur la page de vente</div>
          </div>
          <div class="justify-self-end ml-auto bg-gray-400 flex items-center justify-center rounded-[9999px] w-[22px] h-[22px] p-[1px]">
            <svg width="11" height="11" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 1C0 0.447716 0.447715 0 1 0H9C9.55229 0 10 0.447715 10 1V1.22222C10 1.77451 9.55229 2.22222 9 2.22222H1C0.447716 2.22222 0 1.77451 0 1.22222V1ZM0.555556 3.77778C0.555556 3.22549 1.00327 2.77778 1.55556 2.77778H8.44444C8.99673 2.77778 9.44444 3.22549 9.44444 3.77778V9C9.44444 9.55229 8.99673 10 8.44444 10H1.55556C1.00327 10 0.555556 9.55229 0.555556 9V3.77778ZM3.61111 4.44444C3.53744 4.44444 3.46679 4.47371 3.41469 4.5258C3.3626 4.5779 3.33333 4.64855 3.33333 4.72222C3.33333 5.18246 3.70643 5.55556 4.16667 5.55556H5.83333C6.29357 5.55556 6.66667 5.18246 6.66667 4.72222C6.66667 4.64855 6.6374 4.5779 6.58531 4.5258C6.53321 4.47371 6.46256 4.44444 6.38889 4.44444H3.61111Z"
                fill="white" />
            </svg>
          </div>
        </label>

      </div>
    </div>

    <div class="flex justify-end mt-3">
      <button class="btn bg-primary text-white rounded disabled:opacity-50" @click="save">{{ $t('cta.save') }}</button>
    </div>
  </Modal>
</template>


<script>
import Modal from "@/components/Ui/Modals/Modal.vue";
import { mapGetters } from "vuex";
import { useVfm } from "vue-final-modal";
import { DateTime } from "luxon";
import ScheduleManagement from "@delicity/backend-schedule-management";

export default {
  components: { Modal },
  setup() {
    const vfm = useVfm();
    return { vfm }
  },
  computed: {
    ...mapGetters({
      menu: 'menu/menu',
      currentMerchant: 'currentMerchant',
      selectedMenuItems: 'menu/selectedMenuItems',
    }),
    minDate() {
      return DateTime.now().toFormat('yyyy-MM-dd');
    },
  },
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      availability: null,
      timeUnavailable: 'indefinite',
      timeUnavailableOptions:
        [
          {
            name: "Jusqu'à nouvel ordre",
            value: 'indefinite'
          },
          {
            name: "Jusqu'à demain",
            value: 'tomorrow'
          },
          {
            name: "Jusqu'au prochain créneau",
            value: 'nextslot'
          },
          {
            name: "Choisir la date",
            value: 'date'
          }
        ],
      unavailabilityDate: null,
    }
  },
  methods: {

    async save() {

      if (!this.availability) return;

      let status;

      switch (this.availability) {
        case 'available':
          status = 'available';
          break;
        case 'unavailable':
          status = 'unavailable';
          break;
        case 'archive':
          status = 'archived';
          break;
        default:
          break;
      }

      let dateToSend;
      switch(this.timeUnavailable) {
        case 'indefinite':
          dateToSend = DateTime.now().plus({years: 1000}).toISO();
          break;
        case 'tomorrow':
          dateToSend = DateTime.now().endOf('day').toISO();
          break;
        case 'nextslot':
          const schedule = new ScheduleManagement(this.currentMerchant.schedule.slots, this.currentMerchant.schedule.closes, this.currentMerchant.schedule.options);
          dateToSend = schedule.getNextOpen(DateTime.local());
          if(dateToSend) dateToSend = dateToSend.toISO();
          break;
        case 'date':
          dateToSend = DateTime.fromFormat(this.unavailabilityDate, 'yyyy-MM-dd').toISO()
          break;
      }

      if (status) {
        this.$store.dispatch('menu/updateMenuItemBatchAction', {
          merchantId: this.currentMerchant.id,
          update: this.products.map(product => {
            return {
              id: product.id,
              status: status,
              unavailableUntil: dateToSend
            }
          }),
        });
      }

      this.vfm.closeAll();
    },
  },
  mounted() {
    this.unavailabilityDate = this.minDate;
  }
}
</script>
