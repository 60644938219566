<template>
  <merchant-layout>
    <div class="w-full flex flex-col items-center justify-center">



      <div class="px-4 text-center max-w-[700px] mx-auto mb-6 w-full">
        <div style="position: relative; padding-bottom: 62.5%; height: 0;" class="border border-primary rounded-xl overflow-hidden border-2">
          <iframe src="https://www.loom.com/embed/8dd29d61e54d4920ae2ed1b588ee5deb?sid=59be025a-e3bd-4118-ad9a-bb246cbc93d2" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
        </div>
      </div>

      <div class="text-center max-w-[500px] px-5">
        <h1 class="text-2xl font-bold">Votre boutique est en construction 🚧</h1>
        <span class="text-gray-500 text-lg mt-2 block">Complétez les étapes ci-dessous pour lancer votre activité sur Delicity au plus vite</span>
      </div>

      <div class="w-full max-w-[700px] mt-6 mb-20">
        <page-block no-title-padding>
          <template v-slot:no-padding>
            <div class="px-6 py-4">
              <span class="font-bold text-xl">Les étapes de votre lancement</span>
            </div>
            <div class="h-1 bg-gray-200 w-full">
              <div class="h-1 bg-primary rounded-r-full" :style="'width: '+onboardingPercentage+'%;'"></div>
            </div>

            <div class="flex items-center justify-between px-6 py-4">
              <div class="flex items-center justify-center">
                <div class="h-12 w-12 bg-gray-300 rounded-md flex items-center justify-center text-white" :class="{'bg-green-500':currentMerchant.onboarding.googleId}">
                  <i class="material-icons">location_pin</i>
                </div>
                <div class="flex flex-col pl-4">
                  <b>Votre établissement sur Google</b>
                  <span class="text-sm text-gray-600" v-if="!currentMerchant.onboarding.googleId">Récupérez les infos basiques de Google Maps</span>
                  <span class="text-sm text-green-600" v-else>Étape terminée</span>
                </div>
              </div>
              <div class="flex items-center">
                <button class="btn bg-primary text-white" @click="openGoogleMerchantSearch" v-if="!currentMerchant.onboarding.googleId">{{ $t('cta.complete') }}</button>
                <i class="material-icons text-green-500 text-[2.5rem]" v-else>check_circle</i>
              </div>
            </div>


            <div class="flex items-center justify-between px-6 py-4">
              <div class="flex items-center justify-center">
                <div class="h-12 w-12 bg-gray-300 rounded-md flex items-center justify-center text-white" :class="{'bg-green-500':currentMerchant.onboarding.schedules}">
                  <i class="material-icons">schedule</i>
                </div>
                <div class="flex flex-col pl-4">
                  <b>Horaires de votre établissement</b>
                  <span class="text-sm text-gray-600" v-if="!currentMerchant.onboarding.schedules">Indiquez quand vous êtes ouvert</span>
                  <span class="text-sm text-green-600" v-else>Étape terminée</span>
                </div>
              </div>
              <div class="flex items-center">
                <button class="btn bg-primary text-white" @click="$router.push({name: 'hours'})" v-if="!currentMerchant.onboarding.schedules">{{ $t('cta.complete') }}</button>
                <i class="material-icons text-green-500 text-[2.5rem]" v-else>check_circle</i>
              </div>
            </div>


            <div class="flex items-center justify-between px-6 py-4">
              <div class="flex items-center justify-center">
                <div class="h-12 w-12 bg-gray-300 rounded-md flex items-center justify-center text-white" :class="{'bg-green-500':currentMerchant.onboarding.merchantSettings}">
                  <i class="material-icons">store</i>
                </div>
                <div class="flex flex-col pl-4">
                  <b>Informations sur votre établissement</b>
                  <span class="text-sm text-gray-600" v-if="!currentMerchant.onboarding.merchantSettings">Présentez votre établissement à vos clients</span>
                  <span class="text-sm text-green-600" v-else>Étape terminée</span>
                </div>
              </div>
              <div class="flex items-center">
                <button class="btn bg-primary text-white" @click="$router.push({name: 'merchant'})" v-if="!currentMerchant.onboarding.merchantSettings">{{ $t('cta.complete') }}</button>
                <i class="material-icons text-green-500 text-[2.5rem]" v-else>check_circle</i>
              </div>
            </div>


            <div class="flex items-center justify-between px-6 py-4">
              <div class="flex items-center justify-center">
                <div class="h-12 w-12 bg-gray-300 rounded-md flex items-center justify-center text-white" :class="{'bg-green-500':currentMerchant.onboarding.orderSettings}">
                  <i class="material-icons">shopping_bag</i>
                </div>
                <div class="flex flex-col pl-4">
                  <b>Configuration des commandes</b>
                  <span class="text-sm text-gray-600" v-if="!currentMerchant.onboarding.orderSettings">Choisissez vos façons de vendre et définissez vos conditions</span>
                  <span class="text-sm text-green-600" v-else>Étape terminée</span>
                </div>
              </div>
              <div class="flex items-center">
                <button class="btn bg-primary text-white" @click="$router.push({name: 'orders_settings'})" v-if="!currentMerchant.onboarding.orderSettings">{{ $t('cta.complete') }}</button>
                <i class="material-icons text-green-500 text-[2.5rem]" v-else>check_circle</i>
              </div>
            </div>



            <div class="flex items-center justify-between px-6 py-4">
              <div class="flex items-center justify-center">
                <div class="h-12 w-12 bg-gray-300 rounded-md flex items-center justify-center text-white" :class="{'bg-green-500':currentMerchant.onboarding.documents&&currentMerchant.onboarding.company}">
                  <i class="material-icons">business</i>
                </div>
                <div class="flex flex-col pl-4">
                  <b>Information sur votre entreprise</b>
                  <span class="text-sm text-gray-600" v-if="!currentMerchant.onboarding.documents||!currentMerchant.onboarding.company">Nous avons besoin d'informations administratives sur votre entreprise</span>
                  <span class="text-sm text-green-600" v-else>Étape terminée</span>
                </div>
              </div>
              <div class="flex items-center">
                <button class="btn bg-primary text-white" @click="$router.push({name: 'settings_admin'})" v-if="!currentMerchant.onboarding.documents||!currentMerchant.onboarding.company">{{ $t('cta.complete') }}</button>
                <i class="material-icons text-green-500 text-[2.5rem]" v-else>check_circle</i>
              </div>
            </div>


            <div class="flex items-center justify-between px-6 py-4">
              <div class="flex items-center justify-center">
                <div class="h-12 w-12 bg-gray-300 rounded-md flex items-center justify-center text-white" :class="{'bg-green-500':currentMerchant.onboarding.menu}">
                  <i class="material-icons">list</i>
                </div>
                <div class="flex flex-col pl-4">
                  <b>Menu et produits en vente</b>
                  <span class="text-sm text-gray-600" v-if="!currentMerchant.onboarding.menu">Indiquez votre carte complète de produits en vente</span>
                  <span class="text-sm text-green-600" v-else>Étape terminée</span>
                </div>
              </div>
              <div class="flex items-center">
                <button class="btn bg-primary text-white" @click="$router.push({name: 'menu'})"  v-if="!currentMerchant.onboarding.menu">{{ $t('cta.complete') }}</button>
                <i class="material-icons text-green-500 text-[2.5rem]" v-else>check_circle</i>
              </div>
            </div>


            <div class="flex items-center justify-between px-6 py-4">
              <div class="flex items-center justify-center">
                <div class="h-12 w-12 bg-gray-300 rounded-md flex items-center justify-center text-white" :class="{'bg-green-500':currentMerchant.onboarding.hasOpenStartAdvice}">
                  <i class="material-icons">rocket_launch</i>
                </div>
                <div class="flex flex-col pl-4">
                  <b>Conseils pour votre lancement</b>
                  <span class="text-sm text-gray-600" v-if="!currentMerchant.onboarding.hasOpenStartAdvice">Lancez votre boutique dans les meileures conditions</span>
                  <span class="text-sm text-green-600" v-else>Étape terminée</span>
                </div>
              </div>
              <div class="flex items-center">
                <button class="btn bg-primary text-white" @click="getOnboardingLink('starting_advise_link')" v-if="!currentMerchant.onboarding.hasOpenStartAdvice">{{ $t('cta.constult') }}</button>
                <button class="btn bg-primary text-white" @click="getOnboardingLink('starting_advise_link')" v-else>{{ $t('cta.see_again') }}</button>
              </div>
            </div>


            <div class="flex items-center justify-between px-6 py-4">
              <div class="flex items-center justify-center">
                <div class="h-12 w-12 bg-gray-300 rounded-md flex items-center justify-center text-white" :class="{'bg-green-500':currentMerchant.onboarding.hasOpenGoodPractices}">
                  <i class="material-icons">live_help</i>
                </div>
                <div class="flex flex-col pl-4">
                  <b>Bonnes pratiques</b>
                  <span class="text-sm text-gray-600" v-if="!currentMerchant.onboarding.hasOpenGoodPractices">Prenez les bonnes habitudes pour votre activité</span>
                  <span class="text-sm text-green-600" v-else>Étape terminée</span>
                </div>
              </div>
              <div class="flex items-center">
                <button class="btn bg-primary text-white" @click="getOnboardingLink('good_practice_link')" v-if="!currentMerchant.onboarding.hasOpenGoodPractices">{{ $t('cta.constult') }}</button>
                <button class="btn bg-primary text-white" @click="getOnboardingLink('good_practice_link')" v-else>{{ $t('cta.see_again') }}</button>
              </div>
            </div>

          </template>
        </page-block>
      </div>
    </div>
  </merchant-layout>
</template>

<script>
import {mapGetters} from "vuex";
import {useModal} from "vue-final-modal";
import SearchGoogleModal from "@/components/Onboarding/SearchGoogleModal.vue";
import {successToast} from "@/services/SystemService";

export default {
  setup(){
    const googleSearchModal = useModal({component: SearchGoogleModal})
    return {googleSearchModal}
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
    onboardingPercentage(){
      let percentage = 0;
      if(this.currentMerchant.onboarding.googleId) percentage += 10;
      if(this.currentMerchant.onboarding.schedules) percentage += 10;
      if(this.currentMerchant.onboarding.merchantSettings) percentage += 15;
      if(this.currentMerchant.onboarding.orderSettings) percentage += 10;
      if(this.currentMerchant.onboarding.documents) percentage += 20;
      if(this.currentMerchant.onboarding.menu) percentage += 20;
      return percentage;
    }
  },
  methods: {
    getOnboardingLink(type){
      this.$store.dispatch('getOnboardingLink', {merchantId: this.currentMerchant.id, type: type});
    },
    openGoogleMerchantSearch(){
      this.googleSearchModal.open();
    }
  },
  async mounted() {
    await this.$store.dispatch('getMerchantsAction');
    if(this.currentMerchant.status === 'activated'){
      successToast(this.$t('messages.onboarding_success'));
      this.$router.push({name: 'analytics'});
    }
  }
}
</script>
