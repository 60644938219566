import { createStore } from 'vuex'

import auth from "@/stores/auth";
import merchant from "@/stores/merchant";
import order from "@/stores/order";
import menu from "@/stores/menu";
import accounting from "@/stores/accounting";
import account from "@/stores/account";
import system from "@/stores/system";
import booking from "@/stores/booking";

export default createStore({
    modules: {auth, booking, merchant, order, menu, accounting, system, account}
})
