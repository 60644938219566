<template>
  <div class="page-block overflow-hidden mb-5" :class="{'border-primary':isImportant}">
    <div class="h-14 w-full flex items-center justify-between px-6" :class="{'bg-primary':isImportant}">
      <div class="flex-1 flex items-center" :class="[isImportant ? 'text-white' : '']">
        <strong class="uppercase text-xl font-bold">{{title}}</strong>
        <div class="w-14 h-1 rounded-xl ml-5" :class="[isImportant ? 'bg-white' : 'bg-primary']"></div>
      </div>
    </div>
    <div>
      <table class="w-full" :class="{'border-t': !isImportant}">
        <tr v-for="booking in bookings" class="border-t" :key="booking.id">
          <td class="pl-6 py-2 w-[9rem]">
            <span>{{ booking.ref }}</span>
          </td>
          <td class="px-3 py-2 lg:w-[18%] capitalize">
            <span>{{formatDate(booking.booking_for, 'ccc DD HH:mm')}}</span>
          </td>
          <td class="px-3 py-2 lg:w-[18%] text-center">
            <span>{{booking.user.name}}</span>
          </td>
          <td class="px-3 py-2">
            <span class="flex items-center"><i class="material-icons text-2xl mr-2">group</i>{{ $t('booking.person_count', {persons: booking.persons}) }}</span>
          </td>
          <td class="px-3 py-2">
            <span>{{booking.zone.name}}</span>
          </td>
          <td class="px-3 py-2 text-right w-[12rem]">
            <BookingValidateButton :bookingId="booking.id" v-if="booking.status==='taken'"></BookingValidateButton>
            <BookingStatus :status="booking.status" v-else></BookingStatus>
          </td>
          <td class="px-3 py-2 text-right w-[8rem]">
            <button class="btn  bg-primary text-white" @click="openBookingDetails(booking)">Détails</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import BookingStatus from "@/components/Booking/BookingStatus.vue";
import BookingValidateButton from "@/components/Booking/BookingValidateButton.vue";
import BookingDetails from "@/components/Booking/Modal/BookingDetails.vue";
import {useModal} from "vue-final-modal";
import {formatDate} from "../../services/FormatService";

export default {
  components: {BookingValidateButton, BookingStatus},
  setup(){
    const bookingDetailModal = useModal({component: BookingDetails});
    return {
      bookingDetailModal
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isImportant: {
      type: Boolean,
      default: false
    },
    bookings: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    formatDate,
    openBookingDetails(booking){
      this.bookingDetailModal.patchOptions({attrs: {booking}});
      this.bookingDetailModal.open();
    }
  }
}
</script>
