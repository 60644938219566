<template>
  <page-block title="Horaires">
    <div class="bg-primary/5 px-5 py-4 rounded-md mb-5">
      <span class="text-gray-700">La livraison avec des livreurs indépendants est possible uniquement de 09h00 à 23h00.</span>
    </div>
    <div class="border mb-5 rounded-md">
      <div v-for="day in daySlots" :key="day.day" class="flex flex-col items-start px-6 border-b last:border-0">
        <label class="cursor-pointer h-14 flex items-center" :for="'day'+day.day">
          <input @change="updateDayStatus(day)" :id="'day'+day.day" type="checkbox" v-model="day.enabled" class="form-checkbox mr-4">
          <span class="font-medium">{{$t('days.'+day.day)}}</span>
        </label>
        <div class="flex flex-wrap items-center xs:items-start gap-4 pb-3" v-if="day.slots.length>0">

          <div class="flex h-10 w-[15rem] border rounded-md overflow-hidden" :class="{'border-red-600 ring ring-red-500/50':slot.hasError}" v-for="(slot,si) in day.slots">

            <select class="form-select border-0 text-sm rounded-none flex-1 focus:ring-0" v-model="slot.start" @change="updateDaySlot(day, slot)">
              <option :value="null">Choisir</option>
              <template v-for="hours in getDayHours(day, slot, 'start')">
                  <option :value="hours">
                    {{hours.value}}
                  </option>
              </template>
            </select>


            <div class="h-full border-r"></div>

            <select class="form-select border-0 text-sm rounded-none flex-1 focus:ring-0"  v-model="slot.end" @change="updateDaySlot(day, slot)">
              <option :value="null">Choisir</option>
              <template v-for="hours in getDayHours(day, slot, 'end')">
                <option :value="hours"
                        v-if="!slot.start || slot.start && slot.start.seconds < hours.seconds">
                  {{hours.value}}
                </option>
              </template>
            </select>

            <div class="h-10 w-10 border-l flex justify-center items-center cursor-pointer" @click="removeSlot(day, slot)">
              <i class="material-icons text-xl">close</i>
            </div>
          </div>

          <button class="btn bg-gray-200 text-sm px-4 hover:ring-0 font-bold hidden xs:block" v-if="(day.slots.length===1 && day.slots[0].end && day.slots[0].end.weekDay === day.day)" @click="addSlot(day)">Ajouter créneau</button>
          <button class="btn bg-gray-200 text-sm px-3 hover:ring-0 xs:hidden" v-if="(day.slots.length===1 && day.slots[0].end && day.slots[0].end.weekDay === day.day)" @click="addSlot(day)"><i class="material-icons text-xl">add</i></button>
        </div>
      </div>
    </div>

    <button class="btn bg-primary text-white" @click="save" :disabled="isLoading">{{$t('cta.save')}}</button>
  </page-block>
</template>

<script>
import {DateTime, Interval} from "luxon";
import {mapGetters} from "vuex";
import ScheduleManagement from "@delicity/backend-schedule-management";
import {isSlotListValid, isSlotValid} from "@delicity/backend-schedule-management/services/DateService";
import {errorToast, successToast} from "@/services/SystemService";
import {updateMerchantSchedule} from "@/services/MerchantService";


export default {
  data(){
    return {
      isLoading: false,
      daySlots: []
    }
  },
  methods:{
    async save(){
      if(this.daySlots.some(d => d.slots.some(s => s.hasError))){
        const day = this.daySlots.find(d => d.slots.some(s => s.hasError));
        const errorSlot = day.slots.find(s => s.hasError);
        if(errorSlot.error === 'overlaping'){
          errorToast(this.$t('errors.invalidSlotOverlaping', {day: this.$t('days.'+day.day), start: errorSlot.start.value, end: errorSlot.end.value}));
        }
        else{
          errorToast(this.$t('errors.invalidSlot', {day: this.$t('days.'+day.day), start: errorSlot.start.value, end: errorSlot.end.value}));
        }
        return;
      }

      this.isLoading = true;
      if(!isSlotListValid(this.flatSlots)){
        const errorSlot = this.flatSlots.find(s => !isSlotValid(s));
        errorToast(this.$t('errors.invalidSlot', {day: this.$t('days.'+errorSlot.day), start: errorSlot.start, end: errorSlot.end}));
      }
      const data = await updateMerchantSchedule(this.currentMerchant.id, this.flatSlots);
      this.loadTimetable(data);
      this.$store.commit('SET_MERCHANT_SCHEDULE', data);
      successToast(this.$t('messages.update_schedule_success'));
      this.isLoading = false;
    },
    updateDaySlot(day, s){
      let lastEnd = 0;
      day.slots.forEach((slot) => {
        if(slot.start && slot.end && slot.start.seconds >= slot.end.seconds){
          slot.hasError = slot.start.seconds >= slot.end.seconds;
          slot.error = 'wrong_order';
        }
        else if(slot.start.seconds < lastEnd){
          slot.hasError = slot.start.seconds < lastEnd;
          slot.error = 'overlaping';
        }
        else{
          slot.hasError = false;
          slot.error = '';
        }
        lastEnd = slot.end.seconds
      })
    },
    updateDayStatus(day){
      if(!day.enabled){
        day.slots = []
      }
      else if(day.enabled && day.slots.length === 0){
        this.addSlot(day)
      }
    },
    removeSlot(day, slot){
      day.slots = day.slots.filter(s => s !== slot)
      if(day.slots.length===0) day.enabled = false
    },
    addSlot(day){
      day.enabled = true

      // Copy Yesterday value
      if(day.day>1 && day.slots.length===0 && this.daySlots.some(d => d.day === day.day-1 && d.slots.length>0)){
        const yesterday = this.daySlots.find(d => d.day === day.day-1 && d.slots.length>0);
        day.slots = yesterday.slots.map(s => {
          return {
            identifier: Math.random().toString(36).substring(7),
            id: null,
            start: {value: s.start.value, weekDay: s.start.weekDay+1, seconds: DateTime.fromSeconds(s.start.seconds).plus({day:1}).toSeconds()},
            end: {value: s.end.value, weekDay: s.end.weekDay+1, seconds: DateTime.fromSeconds(s.end.seconds).plus({day:1}).toSeconds()},
            hasError: s.hasError
          }
        });
        return;
      }

      // New empty slot
      day.slots.push({
        identifier: Math.random().toString(36).substring(7),
        id: null,
        start: null,
        end: null,
        hasError: false,
        error: ''
      })
    },
    getDayHours(day, slot, type){
      let intervalStart = DateTime.now().set({weekDay: day.day}).startOf('day').set({hour: 7});
      let intervalEnd = DateTime.now().set({weekDay: day.day}).endOf('day');
      if(type === 'end'){
        intervalEnd = intervalEnd.plus({hours: 6})
      }
      //if(type === 'start'){
      //  intervalStart = DateTime.now().set({weekDay: day.day}).startOf('day').set({hour: 7})
     // }
      const interval = Interval.fromDateTimes(intervalStart, intervalEnd);
      const split = interval.splitBy({minutes: 15});

      return split.map((d) => {
        if(type === 'start'){
          return {
            seconds: d.start.toSeconds(),
            value: d.start.toFormat('HH:mm'),
            weekDay: d.start.weekday
          }
        }
        else{
          return {
            seconds: d.end.toSeconds(),
            value: d.end.toFormat('HH:mm'),
            weekDay: d.end.weekday
          }
        }
      });
    },
    loadTimetable(currentSchedule){
      this.daySlots = [];
      for (let i = 1; i < 8; i++) {
        let slots = [];
        if(currentSchedule.slots.some(s => s.day === i)){
          for(const slot of currentSchedule.slots.filter(s => s.day === i)){
            const start = DateTime.now().set({weekDay: i, hour: slot.start.split(':')[0], minutes: slot.start.split(':')[1]}).startOf('minute');
            let end = DateTime.now().set({weekDay: i, hour: slot.end.split(':')[0], minutes: slot.end.split(':')[1]}).startOf('minute');
            let hasError = false;
            if(start.toSeconds() > end.toSeconds()){
              if(end.hour < 6){
                end = end.plus({days: 1})
              }
              else{
                hasError = true;
              }
            }
            slots.push({
              identifier: slot.id ?? Math.random().toString(36).substring(7),
              id: slot.id,
              start: {seconds: start.toSeconds(), value: start.toFormat('HH:mm'), weekDay: start.weekday},
              end: {seconds: end.toSeconds(), value: end.toFormat('HH:mm'), weekDay: end.weekday},
              hasError
            })
          }
        }
        this.daySlots.push({
          day: i,
          enabled: slots.length>0,
          slots
        })
        this.updateDaySlot(this.daySlots[this.daySlots.length-1])
      }
    }
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant'
    }),
    flatSlots(){
      return this.daySlots.filter(d => d.slots.length>0).flatMap((d) => {
        return d.slots.filter(s => s.start && s.end).map((s) => {
          return {
            id: s.id,
            day: s.start.weekDay,
            start: s.start.value,
            end: s.end.value
          }
        })
      })
    }
  },
  async mounted() {
    if(!this.currentMerchant) await this.$store.dispatch('getMerchantsAction');
    this.loadTimetable(this.currentMerchant.schedule);
  }
}
</script>
